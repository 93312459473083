import { Modal, Button, Icon } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface AccademicOffersModalProps {
  show: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
}

const AcademicOfferDeleteModal = ({
  show,
  onCloseModal,
  onConfirm,
}: AccademicOffersModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseModal();
      }}
      size="md"
      unmountOnClose
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" color="secondary" />
        <div className="mt-4 fw-600 text-black fs-22 text-center">
          {t('academicOffers.list.deleteModalTitle')}
        </div>
      </div>

      <Row className="pt-5">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <Button
            type="button"
            text={t(`common.actions.cancel`)}
            outlined
            onClick={() => onCloseModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="button"
              color="danger"
              text={t('academicOffers.list.confirmDelete')}
              fullwidth
              onClick={onConfirm}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default AcademicOfferDeleteModal;
