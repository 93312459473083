import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import GoBackButton from '../../components/buttons/GoBackButton';
import { PathsLayouts } from '../../config/routes';
import PackagesEnrollStudents from '../../packages/parts/PackagesEnrollStudents';
import { PackagesPendingStudentsLoader } from '../../packages/parts/PackagesPendingStudentsLoader';
import { PackagesPendingStudentsOfferLoader } from '../../packages/parts/PackagesPendingStudentsOfferLoader';
import PackagesViewBox from '../../packages/parts/PackagesViewBox';
import PackagesViewPendingStudents from '../../packages/parts/PackagesViewPendingStudents';
import GeneratingFileModal from '../Reports/parts/GeneratingFileModal';

function PackagesPendingStudents(): JSX.Element {
  const { t } = useTranslation();
  const prefix = 'packages.pending-students';
  const [loading, setLoading] = useState<boolean>(false);

  const { id: academicOfferStudyPlanVersionOfferId } =
    useParams<{ id?: string }>();

  let history = useHistory();
  function back() {
    history.push(PathsLayouts.packages);
  }

  if (
    !academicOfferStudyPlanVersionOfferId ||
    isNaN(parseInt(academicOfferStudyPlanVersionOfferId))
  ) {
    return <Redirect to={PathsLayouts.packages} />;
  }

  const hint = t(`${prefix}.hint`);

  return (
    <Card className="p-4 mx-3">
      <div className="mb-4">
        <GoBackButton onClick={back} />
      </div>
      <PackagesPendingStudentsOfferLoader
        academicOfferStudyPlanVersionOfferId={
          academicOfferStudyPlanVersionOfferId
        }
      >
        {(academicStudyPlanVersionOffer) => (
          <>
            <Row className="mb-5">
              <Col lg={6}>
                <div className="fs-16 text-medium">{hint}</div>
              </Col>
              <Col lg={6}>
                <div>
                  <PackagesViewBox {...academicStudyPlanVersionOffer} />
                </div>
                <div className="mt-5 text-right">
                  <PackagesEnrollStudents
                    beforeEnroll={() => setLoading(true)}
                    afterEnroll={() => setLoading(false)}
                  />
                </div>
              </Col>
            </Row>
            <div className="g-table-container p-0">
              <PackagesPendingStudentsLoader
                academicOfferStudyPlanVersionOfferId={
                  academicOfferStudyPlanVersionOfferId
                }
              >
                {(pendingStudentsLoader) => (
                  <PackagesViewPendingStudents {...pendingStudentsLoader} />
                )}
              </PackagesPendingStudentsLoader>
            </div>
          </>
        )}
      </PackagesPendingStudentsOfferLoader>
      <GeneratingFileModal isOpen={loading} />
    </Card>
  );
}

export default PackagesPendingStudents;
