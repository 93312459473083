import { useState } from 'react';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

export interface TooltipProps {
  id: string;
  children: any;
  text: string;
}

const Tooltip = ({ id, children, text }: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <span id={id}>{children}</span>

      <ReactstrapTooltip
        placement="right"
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
      >
        {text}
      </ReactstrapTooltip>
    </div>
  );
};

export default Tooltip;
