import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'reactstrap';
import { addToast, DisplayInfo } from '@octano/global-ui';
import {
  StudentsTable,
  TableRowData,
  SearchControls,
  SearchParams,
  ProcessingGraduateModal,
  FailedProcessingGraduateModal,
} from './parts';
import {
  processGraduate,
  searchStudents,
} from '../../api/requests/graduationProcessSingle';
import { getReducedAndMappedSearchResponse } from './helpers';
import { PathsLayouts } from '../../config/routes';

const dictPrefix = 'graduationProcessSingle';

enum ModalType {
  PROCESSING_GRADUATE = 'processing-graduate',
  FAILED_PROCESSING = 'failed-processing-graduate',
  NONE = 'none',
}

export function GraduationProcessSingle() {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: routerState } = useLocation<{ searchParams: SearchParams }>();

  const [lastSearchParams, setLastSearchParams] = useState<SearchParams>();

  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const [isLoadingTable, setIsTableLoading] = useState(false);

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const [failedStudyPlanEnrolmentId, setFailedStudyPlanEnrolmentId] =
    useState<number>();

  const handleSearch = useCallback(
    async (searchParams: SearchParams) => {
      setIsTableLoading(true);

      const { searchBy, search } = searchParams;

      const { data, error } = await searchStudents({
        searchBy: searchBy,
        search:
          searchBy === 'identificator'
            ? search.replace(/[^a-z0-9]/gi, '')
            : search,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        const mappedData = getReducedAndMappedSearchResponse(data || []);
        setTableData(mappedData);
        setLastSearchParams(searchParams);
      }

      setIsTableLoading(false);
    },
    [t],
  );

  const handleClear = () => {
    setLastSearchParams(undefined);
    setTableData([]);
  };

  const handleGraduateProcessing = async (studyPlanEnrolmentId: number) => {
    setOpenedModal(ModalType.PROCESSING_GRADUATE);
    const res = await processGraduate(studyPlanEnrolmentId);

    if (res.status === 204) {
      setOpenedModal(ModalType.FAILED_PROCESSING);
      setFailedStudyPlanEnrolmentId(studyPlanEnrolmentId);
    } else {
      setOpenedModal(ModalType.NONE);

      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${dictPrefix}.processedSuccessfully`),
      });
    }
  };

  const handleGoBackOnFailedProcessingModal = () => {
    setFailedStudyPlanEnrolmentId(undefined);
    setOpenedModal(ModalType.NONE);
  };

  const handleGoToStudentPendingRequirermentsSummary = () => {
    history.push(
      `${PathsLayouts.graduationProcessSingle}/pending-requirerments`,
      {
        studyPlanEnrolmentId: failedStudyPlanEnrolmentId,
        searchParams: lastSearchParams,
      },
    );
  };

  useEffect(() => {
    if (routerState?.searchParams) {
      handleSearch(routerState.searchParams);
    }
  }, [routerState, handleSearch]);

  return (
    <>
      <Card className="mx-3 p-4">
        <h1 className="text-uppercase text-primary fs-20 fw-600">
          {t(`${dictPrefix}.title`)}
        </h1>

        <p className="text-medium fs-16 mt-3 mb-5">
          {t(`${dictPrefix}.description`)}
        </p>

        <div className="mb-5">
          <SearchControls
            forcedSearchParams={lastSearchParams}
            onSearch={handleSearch}
            onClear={handleClear}
          />
        </div>

        <StudentsTable
          data={tableData}
          onProcessGraduate={handleGraduateProcessing}
          isLoading={isLoadingTable}
          noResultsText={
            <div className="d-flex justify-content-center mb-3">
              {lastSearchParams ? (
                <DisplayInfo
                  title={t(`${dictPrefix}.noResultsTableMsg.title`)}
                  textBody={t(`${dictPrefix}.noResultsTableMsg.description`)}
                />
              ) : (
                <DisplayInfo
                  title={t(`${dictPrefix}.initialTableMsg.title`)}
                  textBody={t(`${dictPrefix}.initialTableMsg.description`)}
                />
              )}
            </div>
          }
        />
      </Card>

      <ProcessingGraduateModal
        isOpen={openedModal === ModalType.PROCESSING_GRADUATE}
      />

      <FailedProcessingGraduateModal
        isOpen={openedModal === ModalType.FAILED_PROCESSING}
        onGoToSummary={handleGoToStudentPendingRequirermentsSummary}
        onGoBack={handleGoBackOnFailedProcessingModal}
      />
    </>
  );
}
