import {
  formatBaseOption,
  getSelectedOption,
} from '../../../../utils/selectFormat';
import {
  educationalLevelOptions,
  familyGroupOptions,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  parentalSurvivalOptions,
} from '../../../../utils/selectOptions';

type LoaderData = {
  postulant: any;
  educationalBackground: any;
  parentalEducationalBackground: any;
  contact: any;
  familyContact: any;
  emergencyContact: any;
  healthCare: any;
};

export const formatDataToUpdateFormDefaults = ({
  postulant,
  contact,
  familyContact,
  emergencyContact,
  parentalEducationalBackground,
  healthCare,
}: LoaderData) => ({
  namesStudent: postulant?.names,
  paternalSurname: postulant?.paternalLastName,
  maternalSurname: postulant?.maternalLastName,
  personalEmail: postulant?.student?.account?.personalEmail,
  birthday: postulant?.birthday,
  // Contact
  addressExtra: contact?.addressExtra,
  addressStreet: contact?.addressStreet,
  addressNumber: contact?.addressNumber,
  phone: contact?.phone,
  cellphone: contact?.cellPhone,
  email: contact?.email,
  commune: contact?.commune ? formatBaseOption(contact?.commune) : undefined,
  region: contact?.commune?.region
    ? formatBaseOption(contact?.commune?.region)
    : undefined,
  // Family
  addressExtraFamily: familyContact?.addressExtra,
  addressStreetFamily: familyContact?.addressStreet,
  addressNumberFamily: familyContact?.addressNumber,
  phoneFamily: familyContact?.phone,
  cellphoneFamily: familyContact?.cellPhone,
  emailFamily: familyContact?.email,
  communeFamily: familyContact?.commune
    ? formatBaseOption(familyContact?.commune)
    : undefined,
  regionFamily: familyContact?.commune?.region
    ? formatBaseOption(familyContact?.commune?.region)
    : undefined,
  // Emergency
  emergencyNames: emergencyContact?.name,
  mobilePhoneNumberEmergency: emergencyContact?.cellPhone,
  emailEmergency: emergencyContact?.email,
  // Parental
  fatherEducationalLevel: getSelectedOption(
    parentalEducationalBackground?.educationalLevelPaternalId,
    educationalLevelOptions,
  ),
  motherEducationalLevel: getSelectedOption(
    parentalEducationalBackground?.educationalLevelMaternalId,
    educationalLevelOptions,
  ),
  familyGroup: getSelectedOption(
    parentalEducationalBackground?.familyGroupId,
    familyGroupOptions,
  ),
  parentalSurvival: getSelectedOption(
    parentalEducationalBackground?.parentalSurvivalId,
    parentalSurvivalOptions,
  ),
  // Health
  healthCareContributorID: healthCare?.contributorRut,
  healthInsuranceContributorID: healthCare?.insuranceContributorRut,
  phoneNumberHealthCare: healthCare?.insuranceCellPhone,
  otherHealthCare: healthCare?.otherSystem,
  healthInsurance: getSelectedOption(
    healthCare?.hasInsurance ? 1 : 0,
    healthInsuranceOptions,
  ),
  healthCareSystem: getSelectedOption(
    healthCare?.systemId,
    healthCareSystemOptions,
  ),
  healthCareProvider: getSelectedOption(
    healthCare?.providerId,
    healthCareProviderOptions,
  ),
});

export const formatDataToUpdateFormBackground = ({
  postulant,
}: LoaderData) => ({
  student: {
    rut: postulant?.rut,
    fullName: postulant?.fullName,
    names: postulant?.names,
    paternalName: postulant?.paternalLastName,
    maternalName: postulant?.maternalLastName,
    birthday: postulant?.birthday,
    nationality: postulant?.nationality,
    sexId: postulant?.sexId,
    maritalStatusId: postulant?.maritalStatusId,
    passport: postulant?.lastPassport,
    photo: postulant?.photo,
    officialEmail: postulant?.student?.account?.email,
    personalEmail: postulant?.student?.account?.personalEmail,
  },
});
