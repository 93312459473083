import { Box, Button, Icon, Modal } from '@octano/global-ui';
import React from 'react';
import { TcTariffSubmitPendingData } from '../../../../types/tuitionContinuityTrariff';

interface TcTariffConfirmationModalProp {
  open: boolean;
  pendingTariff: TcTariffSubmitPendingData[];

  submit: () => void;

  isLoading: boolean;
  texts: {
    modal: Record<string, any>;
  };
}

export default function TcTariffConfirmationModal({
  pendingTariff,
  open,
  submit,
  isLoading,
  texts,
}: TcTariffConfirmationModalProp) {
  return (
    <Modal isOpen={open} size="md" toggle={() => null} unmountOnClose>
      <div className="text-center">
        <Icon name="warning" size="52px" />
        <p className="mt-5 mb-2 fs-22 text-dark">{texts.modal.title}</p>
        <p className="fs-18 mb-5">{texts.modal.subTitle}</p>

        <h5 className="fs-12 text-medium text-left mb-1">
          {texts.modal.description}
        </h5>
        <Box
          fullwidth
          color="primary"
          body={
            <ul className="px-3 text-left text-dark fs-14 py-0">
              {pendingTariff.map(
                ({ studyPlanName, campusName, scheduleName }, index) => (
                  <>
                    <li key={index.toString()}>
                      {texts.modal.li.studyPlan}:{' '}
                      <strong>{studyPlanName}</strong>
                      <ul>
                        <li>
                          {texts.modal.li.schedule}:{' '}
                          <strong>{scheduleName}</strong>
                        </li>
                        <li>
                          {texts.modal.li.campus}:<strong>{campusName}</strong>
                        </li>
                      </ul>
                    </li>
                    <br />
                  </>
                ),
              )}
            </ul>
          }
          style={{ height: 145, padding: '0.75rem 1rem', overflow: 'auto' }}
        />
        <div
          className="d-flex justify-content-between mt-5 mb-3"
          style={{ gap: '1.25rem' }}
        >
          <Button
            fullwidth
            text={texts.modal.underStood}
            className="mt-0"
            loading={isLoading}
            onClick={() => {
              submit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
