import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Form } from 'reactstrap';

import { FieldsStudentNoSuaFile } from '../../../../types/studentFileNoSuaTypes';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../types/studentFileSectionTypes';
import StudentFileNoSuaFormComponents from '../../../TuitionProcessNoSua/parts/studentFileNoSua/StudentFileNoSuaFormComponents';
import { formatStudenFileNoSuaData } from '../../../TuitionProcessNoSua/parts/studentFileNoSua/utils';
import { savePostulant } from '../api';
import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
// import { formatStudenFileNoSuaData } from './utils';
// import PostulantUpdateFormComponents from './PostulantUpdateFormComponents';

type PostulantUpdateFormProps = {
  postulantId?: string | number;
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  defaultValues?: DefaultValues<FieldsStudentNoSuaFile>;
};

const PostulantUpdateForm = ({
  backgroundData,
  educationalBackground,
  defaultValues,
  postulantId,
}: PostulantUpdateFormProps) => {
  const prefix = 'tuitionProcess.onSite.studentFile';
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm<FieldsStudentNoSuaFile>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: FieldsStudentNoSuaFile) => {
    if (true) {
      const formatData = formatStudenFileNoSuaData(values, 'sin-sua');
      formatData.loginProcessType = undefined;
      const { data, error } = await savePostulant(postulantId ?? 0, formatData);
      if (data === '') {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.saveError`),
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <StudentFileNoSuaFormComponents
          backgroundData={backgroundData}
          educationalBackground={educationalBackground}
          prefix={prefix}
          isSubmitting={methods.formState.isSubmitting}
          isStudentFileUpdate={true}
          saveI18nKey="common.actions.save"
          postulantId={postulantId}
          onPressCancel={() => history.goBack()}
        />
      </Form>
    </FormProvider>
  );
};

export default PostulantUpdateForm;
