import { Button } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';

export interface TuitionContinuityProcessDocumentReviewDocumentsBackProps {
  periodId: number | string;
  studyPlanVersionOfferId: number | string;
}

export default function TuitionContinuityProcessDocumentReviewDocumentsBack({
  periodId,
  studyPlanVersionOfferId,
}: TuitionContinuityProcessDocumentReviewDocumentsBackProps) {
  const { t } = useTranslation();

  const prefix = 'tuitionContinuityDocumentReviewStudent';
  const texts = useMemo(
    () => ({
      backToList: t(`${prefix}.form.backToList`),
    }),
    [t],
  );

  const history = useHistory();

  const goBack = useCallback(() => {
    history.push(
      `${PathsLayouts.tuitionContinuityDocumentReview}/student-list/${periodId}/${studyPlanVersionOfferId}/sin_revision`,
    );
  }, [history, periodId, studyPlanVersionOfferId]);

  return (
    <Button
      size="sm"
      icon="back"
      onClick={goBack}
      text={texts.backToList}
      rounded
      outlined
    />
  );
}
