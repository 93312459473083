import { Button, Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useValidations } from '../../../hooks/useValidations';
import { CourseEnrollment } from '../types';

type ModalSelectBodyProps = {
  onClose: () => void;
  onSuccess: (data: { courseEnrollment: CourseEnrollment }) => void;
  semesterCourseToValidate: { id: string | number; name: string };
  courseEnrollments: CourseEnrollment[];
};

interface Option extends SelectOptionType {
  courseEnrollment: {
    id: number;
    course: {
      name: string;
      shortening: string;
      credits: number;
    };
    grade: number | string | null;
  };
}

export default function ModalSelectBody({
  onClose,
  onSuccess,
  semesterCourseToValidate,
  courseEnrollments,
}: ModalSelectBodyProps) {
  const prefix = 'courseValidationInternalStudent.curriculum.modal';
  const { t } = useTranslation();
  const { msgValidations } = useValidations();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      label: {
        courseToValidate: t(`${prefix}.label.courseToValidate`),
        course: t(`${prefix}.label.course`),
        code: t(`${prefix}.label.code`),
        credits: t(`${prefix}.label.credits`),
        grade: t(`${prefix}.label.grade`),
      },
      cancel: t(`common.actions.cancel`),
      accept: t(`${prefix}.accept`),
      error: t(`${prefix}.error`),
      required: msgValidations.required,
    }),
    [t, msgValidations],
  );

  const methods = useForm<{ course: Option | null }>({
    mode: 'onSubmit',
    defaultValues: {
      course: null,
    },
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    watch,
  } = methods;

  const onSubmit = useCallback(
    async (values: { course: Option }) => {
      onSuccess(values.course);
    },
    [onSuccess],
  );

  const options = useMemo(
    () =>
      courseEnrollments.map<Option>((c) => ({
        label: c.course.name,
        value: c.id,
        courseEnrollment: c,
      })),
    [courseEnrollments],
  );

  const courseSelected = watch('course');

  return (
    <>
      <Row className="text-center">
        <Col xs={12} className="pb-3">
          <span className="fs-22 text-dark">{texts.title}</span>
        </Col>
        <Col xs={12}>
          <p className="fs-16 fw-300">{texts.body}</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-4">
          <Col xs={12}>
            <TextInput
              name="courseToValidate"
              label={texts.label.courseToValidate}
              value={semesterCourseToValidate.name}
              disabled
            />
          </Col>
          <Col xs={12} md={courseSelected ? 8 : undefined}>
            <Select
              name="course"
              label={texts.label.course}
              options={options}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          {courseSelected && (
            <>
              <Col xs={12} md={4}>
                <TextInput
                  name="code"
                  label={texts.label.code}
                  value={courseSelected.courseEnrollment.course.shortening}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  name="credits"
                  label={texts.label.credits}
                  value={courseSelected.courseEnrollment.course.credits}
                  disabled
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  name="grade"
                  label={texts.label.grade}
                  value={courseSelected.courseEnrollment.grade}
                  disabled
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              onClick={onClose}
              text={t('postulationDocs.buttons.close')}
              outlined
              fullwidth
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              type="submit"
              text={t('postulationDocs.buttons.save')}
              fullwidth
              loading={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
