import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Card, Col, Row } from 'reactstrap';
import {
  Button,
  TableCollapsable,
  ColumnTable,
  addToast,
  CellFormatOptions,
  Icon,
} from '@octano/global-ui';
import {
  EditableGrade,
  StudentAndSectionInfoBox,
  useConfigurableGradesValidator,
} from './parts';
import {
  getStudentPostCloseDetail,
  updateStudentPostCloseGrades,
} from '../../api/requests/studentsPostClose';
import { mapStudentPostCloseDetailResponse } from './helpers';
import { StudentPostCloseDetail } from '../../types/studentPostClose';
import {
  SearchParams,
  StudentStatusBadge,
} from '../StudentsPostCloseSearch/parts';
import { PathsLayouts } from '../../config/routes';
import { gradeToFixed } from '../../utils/math';
import SubevaluationsTable from './parts/SubevaluationsTable';

type MappedData = ReturnType<typeof mapStudentPostCloseDetailResponse>;
type DetailBoxData = MappedData['summary'];
type TableData = MappedData['grades'];

const prefix = 'studentGradesClosure.detail';

export function StudentsPostCloseDetail() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<TableData>([]);
  const [detailBoxData, setDetailBoxData] = useState<DetailBoxData>();

  const [isUpdatingGrades, setIsUpdatingGrades] = useState(false);

  const { formatGrade, isRequestingConfig, errorGettingConfig, config } =
    useConfigurableGradesValidator();

  const [pendingTestGradesChanges, setPendingTestGradesChanges] = useState<
    { testGradeId: number; grade: string | number }[]
  >([]);
  const [pendingExamGradeChange, setPendingExamGradeChange] =
    useState<{ finalGradeId: number; grade: string | number }>();

  const {
    state: { courseEnrollmentId, savedSearchParams } = {
      courseEnrollmentId: null,
    },
  } = useLocation<{
    courseEnrollmentId: string | number;
    savedSearchParams: SearchParams;
  }>();
  const history = useHistory();

  if (!courseEnrollmentId) {
    history.push(PathsLayouts.studentsPostClose);
  }

  const goBack = () => {
    history.push(PathsLayouts.studentsPostClose, { savedSearchParams });
  };

  const isColoredRow = (index: number) =>
    index === tableData.length - 1 || index === tableData.length - 3;

  const isBoldRow = (index: number) => index >= tableData.length - 3;

  const getData = useCallback(async () => {
    setIsLoading(true);

    const { data, error } = await getStudentPostCloseDetail(
      courseEnrollmentId!,
    );

    if (error) {
      console.log(error);
    } else if (data) {
      const { grades, summary } = mapStudentPostCloseDetailResponse(data);
      setDetailBoxData(summary);
      setTableData(grades);
    }

    setIsLoading(false);
  }, [courseEnrollmentId]);

  const handleSave = async () => {
    setIsUpdatingGrades(true);

    const studentTestGrades = pendingTestGradesChanges.map((tg) => ({
      testGradeId: tg.testGradeId,
      grade: gradeToFixed(String(tg.grade), config.decimalsLength),
    }));

    const studentFinalGrade = pendingExamGradeChange && {
      finalGradeId: pendingExamGradeChange.finalGradeId,
      grade: gradeToFixed(
        String(pendingExamGradeChange.grade),
        config.decimalsLength,
      ),
    };

    const { error } = await updateStudentPostCloseGrades({
      courseEnrollmentId: `${courseEnrollmentId}`,
      studentTestGrades,
      studentFinalGrade,
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorWhileUpdating`),
      });
    } else {
      // Reseteamos los cambios pendientes
      setPendingTestGradesChanges([]);
      setPendingExamGradeChange(undefined);

      await getData();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.updatedSuccessfully`),
      });
    }

    setIsUpdatingGrades(false);
  };

  /*
    Solo se usa para exam grade y test grades, no todas las notas de la tabla, algunas
    son calculadas y no editables
  */
  const handleGradeEdit = (
    grade: StudentPostCloseDetail.Grade,
    newValue: string,
  ) => {
    if (grade.hasOwnProperty('testGradeId') && 'testGradeId' in grade) {
      // Conseguimos la nota original para saber si el valor ingresado es distinto al ya guardado
      const originalGrade = tableData.find((g) => {
        return (
          (g as StudentPostCloseDetail.TestGrade).testGradeId ===
          grade.testGradeId
        );
      });

      setPendingTestGradesChanges((curr) => {
        const filteredChanges = curr.filter(
          (gc) => gc.testGradeId !== grade.testGradeId,
        );

        // Si no es igual al anterior, lo añadimos como cambio pendiente por guardar
        return originalGrade?.grade !== +newValue
          ? [...filteredChanges, { ...grade, grade: +newValue }]
          : filteredChanges;
      });
    }

    if (grade.hasOwnProperty('finalGradeId') && 'finalGradeId' in grade) {
      // Conseguimos la nota original para saber si el valor ingresado es distinto al ya guardado
      const originalGrade = tableData.find((g) => {
        return (
          (g as StudentPostCloseDetail.ExamGrade).finalGradeId ===
          grade.finalGradeId
        );
      });

      // Si no es igual al anterior, lo añadimos como cambio pendiente por guardar
      if (originalGrade?.grade !== +newValue) {
        setPendingExamGradeChange({ ...grade, grade: +newValue });
      } else {
        setPendingExamGradeChange(undefined);
      }
    }
  };

  const COLUMNS: ColumnTable<TableData[number]>[] = [
    {
      headerText: t(`${prefix}.test`),
      columnName: 'name',
      thClassName: 'text-center',
      tdClassName: ({ index }) =>
        clsx('text-center', {
          'bg-tertiary': isColoredRow(index),
          'fw-600': isBoldRow(index),
        }),
      cellFormat: ({ row }) => {
        if (
          StudentPostCloseDetail.isTestGrade(row) &&
          row.type === 'subevaluations'
        ) {
          return (
            <>
              {row.name}
              <span className="mx-1">
                <Icon name="stack" color="primary" />
              </span>
              {row.grades.length}
            </>
          );
        }
        return <>{row.name}</>;
      },
    },
    {
      headerText: t(`${prefix}.grade`),
      columnName: 'grade',
      thClassName: 'text-center',
      tdClassName: ({ index }) =>
        clsx('d-flex justify-content-center', {
          'bg-tertiary': isColoredRow(index),
          'fw-600': isBoldRow(index),
        }),
      cellFormat: (options) => {
        const userHasMadeChanges =
          pendingExamGradeChange || pendingTestGradesChanges.length;

        if (isColoredRow(options.index) && userHasMadeChanges) {
          return (
            <p className="mb-0 d-flex align-items-center">
              {t(`${prefix}.calculating`)}
            </p>
          );
        }

        return (
          <EditableGrade
            initialGrade={options.value}
            onEdit={(newValue) => handleGradeEdit(options.row, newValue)}
            editable={
              !isRequestingConfig &&
              !errorGettingConfig &&
              !isColoredRow(options.index) &&
              !(
                StudentPostCloseDetail.isTestGrade(options.row) &&
                options.row.type === 'subevaluations'
              )
            }
            inputFormatter={formatGrade}
          />
        );
      },
    },
    {
      headerText: t(`${prefix}.percentage`),
      columnName: 'percentage',
      thClassName: 'text-center',
      tdClassName: ({ index }) =>
        clsx('text-center', {
          'bg-tertiary': isColoredRow(index),
          'fw-600': isBoldRow(index),
        }),
    },
    {
      headerText: t(`${prefix}.date`),
      columnName: 'date',
      thClassName: 'text-center',
      cellFormat: ({ value }) =>
        value ? dayjs(value).format('DD/MM/YYYY') : '-',
      tdClassName: ({ index }) =>
        clsx('text-center', {
          'bg-tertiary': isColoredRow(index),
          'fw-600': isBoldRow(index),
        }),
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <div
          className="d-flex flex-wrap justify-content-between mb-4"
          style={{ gap: 12 }}
        >
          <Button
            icon="back"
            text={t(`${prefix}.goBack`)}
            size="sm"
            rounded
            outlined
            onClick={goBack}
            style={{ height: 'fit-content' }}
          />
          {detailBoxData && <StudentAndSectionInfoBox {...detailBoxData} />}
        </div>
        <h2 className="text-primary text-uppercase fs-18 fw-600 mt-2">
          {t(`${prefix}.title`)}
        </h2>

        <Row>
          <Col className="pb-4" md={10}>
            <p className="text-medium text-justify fs-14 mb-0">
              {t(`${prefix}.description`)}
            </p>
          </Col>
          <Col
            className="pb-4 d-flex align-content-center "
            style={{ gap: 8, minWidth: 212 }}
            md={2}
          >
            <p className="pt-1">{t(`${prefix}.currentStatus`)}: </p>
            <StudentStatusBadge
              status={
                pendingExamGradeChange ||
                pendingTestGradesChanges.length ||
                !detailBoxData ||
                isLoading
                  ? 'calc'
                  : detailBoxData.state
              }
            />
          </Col>
        </Row>

        <TableCollapsable<StudentPostCloseDetail.Grade>
          isLoading={isLoading}
          data={tableData}
          collapsableTdProps={({ index }) => ({
            className: clsx({ 'bg-tertiary': isColoredRow(index) }),
          })}
          isCollapsable={(row) => {
            return (
              StudentPostCloseDetail.isTestGrade(row) &&
              row.type === 'subevaluations'
            );
          }}
          columns={COLUMNS}
          secondColumn={{
            columnName: 'second',
            headerText: 'second',
            cellFormat: ({
              row,
            }: CellFormatOptions<StudentPostCloseDetail.PartialEvaluationSubevaluations>) => (
              <SubevaluationsTable
                handleGradeEdit={handleGradeEdit}
                partialEvaluation={row}
                formatGrade={formatGrade}
                isRequestingConfig={isRequestingConfig}
                errorGettingConfig={errorGettingConfig}
              />
            ),
          }}
        />

        <div className="d-flex justify-content-end mt-5">
          <div
            className="d-flex flex-wrap justify-content-end "
            style={{ maxWidth: 1000, gap: 14 }}
          >
            <Button
              text={t(`${prefix}.cancel`)}
              outlined
              style={{ width: 333 }}
              onClick={goBack}
              className={'flex-grow-1'}
            />
            <Button
              text={t(`${prefix}.saveChanges`)}
              style={{ width: 333 }}
              className={'flex-grow-1'}
              onClick={handleSave}
              loading={isUpdatingGrades}
              disabled={
                !pendingExamGradeChange && !pendingTestGradesChanges.length
              }
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
