import { Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
}

export function ProcessingGraduatesModal({ isOpen }: Props) {
  const { t } = useTranslation();
  const prefix = 'graduationProcessMassive.processingGraduatesModal';

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center mb-5">
          <Icon className="spin" name="circle" color="primary" size="51px" />
        </div>
        <h1 className="text-dark text-center fw-600 fs-22 mb-2">
          {t(`${prefix}.title`)}
        </h1>
        <p className=" text-center fs-18 lh-30 mt-2 mb-4">
          {t(`${prefix}.description`)}
        </p>
      </div>
    </Modal>
  );
}
