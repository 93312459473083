import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleList } from './SectionForm';

type Props = {
  openCreateOrUpdate: (index?: number) => void;
  onDelete: (index: number) => void;
};

export const useColumnsSectionSchedule = ({
  openCreateOrUpdate,
  onDelete,
}: Props) => {
  const prefix = 'sectionsMaintainer.update.sectionScheduleColumns';
  const { t } = useTranslation();
  const tUntilModule = useMemo(() => {
    return t(`${prefix}.untilModule`);
  }, [t]);

  const columns: ColumnTable<ScheduleList>[] = useMemo(() => {
    return [
      {
        columnName: 'activity',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.classType`),
        cellFormat: ({ row }) => row.activity.label,
      },
      {
        columnName: 'day',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.day`),
        cellFormat: ({ row }) => t(`common.days.${row.day.value}`),
      },
      {
        columnName: 'modules',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.modules`),
        cellFormat: ({ row }) => {
          if (!row?.endModule) return row.startModule.label;
          return `${row.startModule.label} ${tUntilModule} ${
            row?.endModule?.label || row.startModule.label
          }`;
        },
      },
      {
        columnName: 'course',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.classResponsible`),
        cellFormat: ({ row }) => row.responsible?.label,
      },
      {
        columnName: 'course',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.classroomCampus`),
        cellFormat: ({ row }) =>
          `${row.classroom?.label ?? ''} ${
            row.campus?.label ? '- ' + row.campus?.label : ''
          }`,
      },
      {
        columnName: 'isActive',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.state`),
        cellFormat: ({ value }) =>
          value ? t(`common.forms.active`) : t(`common.forms.inactive`),
      },
      {
        columnName: 'id',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`common.actions.action`),
        cellFormat: ({ index, row }) => {
          if (!row.isActive) {
            return null;
          }
          return (
            <div className="d-flex d-flex justify-content-center">
              <div
                className="cursor-pointer mr-2"
                onClick={() => {
                  openCreateOrUpdate(index);
                }}
              >
                <Icon size="15" name="edit" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  onDelete(index);
                }}
              >
                <Icon size="15" name="trash" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [t, tUntilModule, openCreateOrUpdate, onDelete]);

  return columns;
};
