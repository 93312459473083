import { GRADUATION_PROCESS_MASSIVE } from '../endpoints';
import request from '../request';
import {
  CurrentPeriodAndFacultiesResponse,
  ProcessGraduatesFilterResponse,
  ProcessGraduatesResponse,
  StudyPlansByFacultyResponse,
} from '../../types/graduationProcessMassive';
import { SearchParams } from '../../views/GraduatesList/parts/SearchControls';

/**
 * Obtiene de la API el periodo actual y la lista de facultades
 */
export function getCurrentPeriodAndFaculties() {
  return request<{ data: CurrentPeriodAndFacultiesResponse }>(
    GRADUATION_PROCESS_MASSIVE.CURRENT_PERIOD_AND_FACULTIES,
  );
}

/**
 * Obtiene de la API el listado de planes de estudio que corresponden a la facultad especificada
 * @param facultyId
 * @returns
 */
export function getStudyPlansByFaculty(facultyId: number | string) {
  return request<{ data: StudyPlansByFacultyResponse }>(
    GRADUATION_PROCESS_MASSIVE.STUDY_PLANS_BY_FACULTY,
    { params: { schoolId: facultyId } },
  );
}

/**
 * Lanza el proceso de egreso para el plan de estudio indicado
 * @param studyPlanId
 * @returns
 */
export function processGraduatesByStudyPlan(studyPlanId: number | string) {
  const data = { studyPlansIds: [studyPlanId] };
  const method = 'POST';

  return request<ProcessGraduatesResponse>(
    GRADUATION_PROCESS_MASSIVE.PROCESS_GRADUATES_BY_STUDY_PLANS,
    { data, method },
  );
}

/**
 * Descarga un archivo de excel con el listado de egresados de un plan de estudio
 * @param studyPlanId
 * @returns
 */
export function downloadGraduatesList(studyPlanId: number) {
  const URL = GRADUATION_PROCESS_MASSIVE.DOWNLOAD_GRADUATES_LIST(studyPlanId);
  return request(URL, { responseType: 'blob' });
}

/**
 * Obtiene el listado de estudiantes egresados
 * @param items
 * @param page
 * @returns
 */
export function getGraduatesList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = GRADUATION_PROCESS_MASSIVE.GET_GRADUATES_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<ProcessGraduatesResponse>(URL, { method, params });
}

export function downloadAllGraduatesByFilter(filter: SearchParams) {
  const method = 'GET';
  return request(GRADUATION_PROCESS_MASSIVE.DOWNLOAD_GRADUATES_LIST_BY_FILTER, { responseType: 'blob',method, params: filter});
}

export function graduateListFilter() {
  const URL = GRADUATION_PROCESS_MASSIVE.FILTER_GRADUATES_LIST;
  const method = 'GET';
  return request<ProcessGraduatesFilterResponse>(URL, { method });
}
