import { useMemo } from 'react';
import { Card, Container, Row } from 'reactstrap';
import PostulantLoader from './parts/PostulantLoader';
import PostulantUpdateForm from './parts/UpdateFrom';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import DisplayError from '../../../components/info/DisplayError';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  formatDataToUpdateFormBackground,
  formatDataToUpdateFormDefaults,
} from './utils/formatter';
import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import { PathsLayouts } from '../../../config/routes';

const UpdateScreen = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();
  const history = useHistory();

  const hasPermission = useMemo(
    () =>
      isAuthorizedTo(
        [
          PermissionName.SHOW_STUDENT_STATUS,
          PermissionName.CHANGE_STUDENT_STATUS,
        ],
        true,
      ),
    [isAuthorizedTo],
  );

  return (
    <Container fluid>
      <Card className="p-4 mt-2 mx-3">
        <Row className="p-3">
          <GoBackToListButton path={PathsLayouts.studentFile} />
        </Row>
        {hasPermission ? (
          <PostulantLoader>
            {(props) => (
              <>
                <PostulantUpdateForm
                  postulantId={props?.postulant?.id}
                  defaultValues={formatDataToUpdateFormDefaults(props)}
                  backgroundData={formatDataToUpdateFormBackground(props)}
                  educationalBackground={props?.educationalBackground}
                />
              </>
            )}
          </PostulantLoader>
        ) : (
          <DisplayError
            insideCard
            textBody={t('common.errors.permissions')}
            buttonText={t('common.actions.back')}
            retryAction={() => history?.goBack()}
          />
        )}
      </Card>
    </Container>
  );
};

export default UpdateScreen;
