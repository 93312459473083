import { useCallback, useEffect, useState } from 'react';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import DisplayError from '../../../components/info/DisplayError';
import { Table, TablePagination } from '@octano/global-ui';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import ExpandableCell from './components/ExpandableCell';
import { getStudyPlansBySchoolRequest } from '../../../api/requests/studyPlans';
import { useTranslation } from 'react-i18next';
import styles from './AcademicOffers.module.scss';
import { StudyPlanItemCustom } from './interfaces/study-plan-item-custom.interface';

const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 0;

const AcademicOffersEditTable = ({
  periodId,
  schoolId,
  academicOfferId,
  reloadViewData,
  onlyView = false,
  title = true,
}: {
  periodId: number | null;
  schoolId: number | null;
  academicOfferId: number;
  reloadViewData: () => void;
  onlyView?: boolean;
  title?: boolean;
}) => {
  const { t } = useTranslation();
  const [studyPlans, setStudyPlans] = useState<StudyPlanItemCustom[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [showPagination, setShowPagination] = useState<boolean>(false);

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const getStudyPlansBySchool = useCallback(
    async (
      items: number = ITEMS_PER_PAGE_DEFAULT,
      page: number = PAGE_INITIAL_DEFAULT,
    ) => {
      if (schoolId && periodId) {
        const { error, data } = await getStudyPlansBySchoolRequest(
          periodId,
          schoolId,
          academicOfferId,
          items,
          page,
        );
        if (error) {
          setErrorLoading(error.code);
          return;
        }

        if (data) {
          setErrorLoading(undefined);
          const { data: dataItems = [], total = 0, total_pages = 1 } = data;
          setStudyPlans(dataItems);
          setTotalItems(total);
          setTotalPages(total_pages);
          setShowPagination(true);
        }

        setLoading(false);
      }
    },
    [setErrorLoading, setLoading, periodId, schoolId, academicOfferId],
  );

  const changePage = async (page: number = 1) => {
    if (schoolId) {
      await getStudyPlansBySchool(ITEMS_PER_PAGE_DEFAULT, page - 1);
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    if (!schoolId) {
      setStudyPlans([]);
      setLoading(false);
      return;
    }

    getStudyPlansBySchool();
  }, [periodId, schoolId, getStudyPlansBySchool, setLoading]);

  const columns = [
    {
      columnName: 'name',
      headerText: t('academicOffers.form.studyPlan'),
      width: '20%',
      cellFormat: (options: CellFormatOptions<StudyPlanItemCustom>) => {
        return (
          <ExpandableCell
            academicOfferId={academicOfferId}
            studyPlanId={options.row.StudyPlan_id}
            name={options.row.StudyPlan_name}
            studyPlanItem={options.row}
            versions={options.row.offers}
            duplicatedOffers={options.row.duplicatedOffers}
            getStudyPlansBySchool={getStudyPlansBySchool}
            reloadViewData={reloadViewData}
            onlyView={onlyView}
          />
        );
      },
    },
  ];

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  if (loading) {
    return <div className="mx-3"></div>;
  }
  return (
    <>
      <div
        className={`${styles.academicOffersCreateStep2Table} ${
          !title ? styles.notTitle : ''
        }`}
      >
        <Table
          columns={columns}
          data={studyPlans}
          noResultsText={
            title && (
              <TableEmptyContent
                title={t('academicOffers.form.studyPlansTableEmptyTitle')}
                subtitle={t('academicOffers.form.studyPlansTableEmptySubtitle')}
              />
            )
          }
        />
        {showPagination && totalItems > ITEMS_PER_PAGE_DEFAULT && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
    </>
  );
};

export default AcademicOffersEditTable;
