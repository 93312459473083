import { OutlinedSelect } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  getPeriodOFMantainerPortal,
  Period,
} from '../../api/requests/MantainerPortal';
import Loading from '../../components/info/Loading';
import { useLoadingState } from '../../hooks/useLoadingState';
import PackagesListContent from '../../packages/PackagesListContent';
import PackagesListProvider from '../../packages/parts/PackagesListContext';

function PackagesList(): JSX.Element {
  const { t } = useTranslation();
  const { loading, setLoading, setErrorLoading } = useLoadingState();
  const [periods, setPeriods] = useState<Period[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<Period | null>(null);

  const valueCurrentPeriod = useMemo(() => {
    if (!currentPeriod) return undefined;
    return {
      value: currentPeriod.id,
      label: currentPeriod.name,
    };
  }, [currentPeriod]);

  const optionPeriods = useMemo(() => {
    return periods.map((period) => ({
      value: period.id,
      label: period.name,
    }));
  }, [periods]);

  const getPeriod = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPeriodOFMantainerPortal();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setPeriods(data.periods);
      setCurrentPeriod(data.currentPeriod);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading]);

  const changeCurrentPeriod = useCallback(
    (periodOption) => {
      setCurrentPeriod(
        periods.find((period) => period.id === periodOption.value) as Period,
      );
    },
    [periods],
  );

  useEffect(() => {
    getPeriod();
  }, [getPeriod]);

  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }

  return (
    <div className="mx-3">
      <PackagesListProvider
        fetchImmediately
        periodId={currentPeriod?.id as number}
      >
        <div className="g-table-container mt-2 pb-4 pt-4">
          <div className="mb-5">{t('packages.selectFilters')}</div>
          <Row>
            <Col md={4}>
              <OutlinedSelect
                label={t('packages.period')}
                name="period"
                options={optionPeriods}
                value={valueCurrentPeriod}
                onChange={changeCurrentPeriod}
                isClearable={false}
              />
            </Col>
          </Row>
          <PackagesListContent />
        </div>
      </PackagesListProvider>
    </div>
  );
}

export default PackagesList;
