import { useFetch } from '../../../../components/contexts/FetchContext';
import {
  TariffListResponse,
  TcTariffSubmitData,
  TcTariffSubmitPendingData,
  TcTariffTableData,
} from '../../../../types/tuitionContinuityTrariff';
import React, { useCallback, useMemo, useState } from 'react';
import { addToast, CellFormatOptions, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import TcTariffAccordion from './TcTariffAccordion';
import TcTariffConfirmationModal from './TcTariffConfirmationModal';
import { saveTcTariffList } from '../../../../api/requests/tuitionContinuityTariff';

export interface TuitionContinuityTariffTableProps {
  periodId: number;
}

export default function TuitionContinuityTariffTable({
  periodId,
}: TuitionContinuityTariffTableProps) {
  const { loading, data, updateQuery } = useFetch<
    {},
    { data: TariffListResponse[] },
    { code: string }
  >();
  const { t } = useTranslation();
  const [submitData, setSubmitData] = useState<TcTariffSubmitData[]>([]);
  const [pendingData, setPendingData] = useState<TcTariffSubmitPendingData[]>(
    [],
  );

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data?: TcTariffSubmitData[]) => {
      const submit = data ?? submitData;
      setSubmitLoading(true);
      if (submit.length > 0) {
        const { error } = await saveTcTariffList(submit);
        if (error && error.code === 'HTTP_ERROR') {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.save'),
          });
        } else {
          addToast({
            icon: 'success',
            color: 'success',
            text: t('common.messages.editSuccess'),
          });
        }
      }
      setSubmitData([]);
      setPendingData([]);
      updateQuery({});
      setSubmitLoading(false);
    },
    [submitData, t, updateQuery],
  );

  const onFormat = useCallback(
    ({
      data,
      pending,
    }: {
      data: TcTariffSubmitData[];
      pending: TcTariffSubmitPendingData[];
    }) => {
      if (pending.length <= 0) {
        onSubmit(data);
      } else {
        setSubmitData(data);
        setPendingData(pending);
      }
    },
    [onSubmit],
  );
  const formatData = useMemo(
    () =>
      data?.data?.reduce((acc, value) => {
        const offer = acc.find((a) => a.id === value.studyPlanVersionId);
        if (offer) {
          offer.offers.push(value);
        } else {
          acc.push({
            id: value.studyPlanVersionId,
            name: value.studyPlanName,
            offers: new Array<TariffListResponse>(value),
          });
        }
        return acc;
      }, new Array<TcTariffTableData>()) ?? [],
    [data],
  );
  const texts = useMemo(() => {
    const prefix = 'tuitionContinuityTariff.tariff';
    return {
      header: t(`${prefix}.table.studyPlan`),
      noContent: {
        title: t(`${prefix}.table.tableEmptyTitle`),
        subtitle: t(`${prefix}.table.tableEmptySubtitle`),
      },
      modal: {
        title: t(`${prefix}.modal.title`),
        subtitle: t(`${prefix}.modal.subTitle`),
        description: t(`${prefix}.modal.description`),
        li: {
          studyPlan: t(`${prefix}.modal.studyPlan`),
          schedule: t(`${prefix}.modal.schedule`),
          campus: t(`${prefix}.modal.campus`),
        },
        underStood: t('common.selectCareer.underStood'),
      },
      accordion: {
        campus: t(`${prefix}.table.campus`),
        schedule: t(`${prefix}.table.schedule`),
        tariff: t(`${prefix}.table.tariff`),
        status: t(`${prefix}.table.status`),
        submit: t(`${prefix}.form.submitButton`),
      },
    };
  }, [t]);

  const columns = useMemo(
    () => [
      {
        columnName: 'name',
        headerText: texts.header,
        width: '20%',
        tdClassName: 'pl-4 pr-2 p-0',
        cellFormat: (options: CellFormatOptions<TcTariffTableData>) => {
          return (
            <TcTariffAccordion
              row={options.row}
              onFormatData={onFormat}
              periodId={periodId}
              isLoading={submitLoading}
              texts={texts}
            />
          );
        },
      },
    ],
    [onFormat, periodId, submitLoading, texts],
  );
  return (
    <>
      <Table
        columns={columns}
        data={formatData}
        isLoadingResults={loading}
        striped
        noResultsText={
          <TableEmptyContent
            title={texts.noContent.title}
            subtitle={texts.noContent.subtitle}
          />
        }
      />
      <TcTariffConfirmationModal
        open={pendingData.length > 0}
        pendingTariff={pendingData}
        submit={onSubmit}
        texts={texts}
        isLoading={submitLoading}
      />
    </>
  );
}
