import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DisplayError from '../components/info/DisplayError';
import DisplayInfo from '../components/info/DisplayInfo';
import Loading from '../components/info/Loading';
import PackagesList from './parts/PackagesList';
import { usePackagesList } from './parts/PackagesListContext';

export default function PackagesListContent() {
  const { t } = useTranslation();
  const { loading, error, data } = usePackagesList();

  const hasNoStudents = useMemo(() => {
    return data.every((d) => d.totalStudents <= 0);
  }, [data]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }
  if (error === 'UNKNOWN') {
    return (
      <DisplayError
        title={t('packages.loadErrorTitle')}
        textBody={t('packages.loadErrorBody')}
        insideCard
      />
    );
  }
  if (!data.length) {
    return (
      <DisplayInfo
        title={t('packages.noPackagesTitle')}
        textBody={t('packages.noPackagesBody')}
        insideCard
      />
    );
  }
  if (hasNoStudents) {
    return (
      <DisplayInfo
        title={t('packages.noPackagesWithStudentsTitle')}
        textBody={t('packages.noPackagesWithStudentsBody')}
        insideCard
      />
    );
  }
  return <PackagesList data={data} />;
}
