import {
  addToast,
  Button,
  PaginationType,
  SelectOptionType,
  Table,
} from '@octano/global-ui';
import { saveAs } from 'file-saver';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Col, Container, Row } from 'reactstrap';
import {
  getReportStatusStudent,
  requestStudentStatusList,
} from '../../../api/requests/studentStatus';
import DisplayError from '../../../components/info/DisplayError';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../hooks/useLoadingState';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import {
  STUDENT_STATUS,
  StudentStatusListType,
} from '../../../types/studentStatusTypes';
import { formatDate } from '../../../utils/dates';
import ModalChangeStatus, { InputsForm } from './../parts/ModalChangeStatus';
import SearchFilters, { SearchFiltersType } from './../parts/SearchFilters';
import { useColumnsStudentStatusList } from './../parts/useColumnsStudentStatusList';

const ITEMS_PER_PAGE = 10;

export interface ModalChangeStatusType {
  index: number;
  studentId: number;
  studyPlanEnrollmentId: number;
  actualStatus: string;
  newStatus: string;
  action: 'create' | 'update' | 'watch';
  defaultValues?: DefaultValues<InputsForm>;
}

interface UrlLinkProps {
  href?: string;
}

const UrlLink: FunctionComponent<UrlLinkProps> = ({ href, children }) => {
  return (
    <a className="fw-600" target="_blank" rel="noreferrer" href={href}>
      {children}
    </a>
  );
};

interface StudenStatusProps {
  campusOptions: SelectOptionType[];
  statusOptions: SelectOptionType[];
}

const StudentStatus = (props: StudenStatusProps) => {
  const { campusOptions, statusOptions } = props;
  const prefix = 'studentStatus';
  const { t } = useTranslation();
  const [dataList, setDataList] = useState<StudentStatusListType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<SearchFiltersType>();

  const [downloading, setDownloading] = useState<boolean>(false);
  const [modalChangeStatus, setModalChangeStatus] =
    useState<ModalChangeStatusType>();
  const { isAuthorizedTo } = useUserState();

  const columns = useColumnsStudentStatusList(
    statusOptions,
    (modalState: ModalChangeStatusType) => setModalChangeStatus(modalState),
  );
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState(false);

  const getData = useCallback(
    async (page: number) => {
      setLoading(true);
      setCurrentPage(page);

      const { data, error } = await requestStudentStatusList({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        status: searchFilters?.status?.value
          ? searchFilters.status.value.toString()
          : null,
        campusId: searchFilters?.campusId?.value
          ? searchFilters.campusId.value.toString()
          : null,
        search_text: searchFilters?.nameOrId,
      });

      if (data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => getData(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [setLoading, setErrorLoading, searchFilters],
  );

  const onSaveChangeStatus = useCallback((index: number, newStatus: string) => {
    setDataList((prevState) => {
      let newState = [...prevState];
      newState[index].status = newStatus;
      if (newStatus === STUDENT_STATUS.REGULAR) {
        newState[index].reincorporated = {
          status: 'reincorporacion_manual',
          date: formatDate(new Date(), 'YYYY-MM-DD'),
        };
      }
      return newState;
    });
  }, []);

  const getFile = async () => {
    setDownloading(true);
    const { data } = await getReportStatusStudent({
      status: searchFilters?.status?.value?.toString() ?? null,
      search_text: searchFilters?.nameOrId ?? undefined,
      campusId: searchFilters?.campusId?.value?.toString() ?? undefined,
    });
    if (data) {
      saveAs(data, 'report.xlsx', { autoBom: true });
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.download'),
      });
    }
    setDownloading(false);
  };

  useEffect(() => {
    if (searchFilters) {
      getData(1);
    }
  }, [searchFilters, getData]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading ?? ''}
          retryAction={() => {
            getData(currentPage);
            setErrorLoading(undefined);
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Container fluid>
      <Card className="p-4 mt-2 ">
        <Row>
          <Col xs={12}>
            <h1 className="fs-22 text-primary fw-700">
              {t(`${prefix}.title`)}
            </h1>
            <p className="py-3">
              <Trans
                i18nKey={t(`${prefix}.description`)}
                components={{
                  urlLink: <UrlLink />,
                }}
              />
            </p>
            <SearchFilters
              onSearch={(filters: SearchFiltersType) => {
                setSearchFilters(filters);
              }}
              statusOptions={statusOptions}
              campusOptions={campusOptions}
            />
          </Col>
          {isAuthorizedTo([PermissionName.REPORT_STUDENT_STATUS]) &&
            dataList.length > 0 && (
              <Col xs={12} className="d-flex justify-content-end pb-2">
                <div style={{ minWidth: '150px' }}>
                  <Button
                    type="button"
                    icon="download"
                    text={t('common.actions.downloadList')}
                    onClick={getFile}
                    outlined
                    size="sm"
                    loading={downloading}
                    fullwidth
                  />
                </div>
              </Col>
            )}
          <Col xs={12}>
            <Table<StudentStatusListType>
              columns={columns}
              data={dataList}
              pagination={pagination}
              isLoadingResults={loading}
              noResultsText={
                <TableEmptyContent
                  title={t(
                    `${prefix}.${
                      searchFilters ? 'noResultsTitle' : 'noSearchTitle'
                    }`,
                  )}
                  subtitle={t(
                    `${prefix}.${
                      searchFilters ? 'noResultsBody' : 'noSearchBody'
                    }`,
                  )}
                />
              }
            />
          </Col>
        </Row>

        <ModalChangeStatus
          modalChangeStatus={modalChangeStatus}
          setModalChangeStatus={(props?: ModalChangeStatusType) =>
            setModalChangeStatus(props)
          }
          onSave={onSaveChangeStatus}
        />
      </Card>
    </Container>
  );
};

export default StudentStatus;
