import { useTranslation } from 'react-i18next';
import { Table, ColumnTable, DisplayInfo } from '@octano/global-ui';
import { ProcessGraduatesResponse } from '../../../types/graduationProcessMassive';

const dictPrefix = `graduatesListProcessMassive.graduatesTable`;

interface Props {
  data: ProcessGraduatesResponse['data'];
  isLoading?: boolean;
}

export default function GraduatesTable({ data }: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<typeof data[number]>[] = [
    {
      columnName: 'run',
      headerText: t(`${dictPrefix}.identification`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'fullName',
      headerText: t(`${dictPrefix}.fullName`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'schoolName',
      headerText: t(`${dictPrefix}.faculty`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'studyPlanName',
      headerText: t(`${dictPrefix}.studyPlan`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      columnName: 'lastPeriod',
      headerText: t(`${dictPrefix}.graduationDate`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ value }) => value.name,
    },
    {
      columnName: 'status',
      headerText: t(`${dictPrefix}.status`),
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: () => {
        return (
          <span
            className="py-1 px-3"
            style={{ backgroundColor: '#CEF0DA', borderRadius: '50rem' }}
          >
            {t(`${dictPrefix}.graduated`)}
          </span>
        );
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            title={t(`${dictPrefix}.noResults.title`)}
            textBody={t(`${dictPrefix}.noResults.description`)}
            maxWidth="640px"
          />
        </div>
      }
    />
  );
}
