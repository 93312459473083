import { CreateAssistantForm } from '../../views/SectionsMaintainer/parts/ModalCreateAssistant';
import { ASSISTANTS } from '../endpoints';
import request from '../request';

/**
 * crea un asistente
 * @param values Datos del asistente
 */
export const createAssistantRequest = (values: CreateAssistantForm) => {
  const data = {
    ...values,
    passportCountry: values.passportCountry?.value,
    type: values.type ? 'passport' : 'rut',
  };
  return request<any>(ASSISTANTS.CREATE, {
    data,
    method: 'POST',
  });
};
