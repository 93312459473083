import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'reactstrap';
import {
  // Button,
  Table,
} from '@octano/global-ui';
import { searchStudentsPostClose } from '../../api/requests/studentsPostClose';
import { MappedSearchResults, mapSearchResult } from './helpers';
import { SearchBar, SearchParams, useStudentsTableColumns } from './parts';
import DisplayInfo from '../../components/info/DisplayInfo';
import { PathsLayouts } from '../../config/routes';

const STUDENTS_PER_PAGE = 10;

export function StudentsPostCloseSearch() {
  const { t } = useTranslation();
  const prefix = `studentGradesClosure.search`;

  const history = useHistory();
  const { state: routerState } =
    useLocation<{ savedSearchParams: SearchParams }>();

  // Resultados de la tabla de estudiantes
  const [studentsList, setStudentsList] = useState<MappedSearchResults>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const prevSearchParams = useRef<SearchParams>({
    search: '',
    searchBy: 'identificator',
  });
  const isFirstSearch = useRef(true);

  const COLUMNS = useStudentsTableColumns({
    onRowEdit: (row) => {
      history.push({
        pathname: `${PathsLayouts.studentsPostClose}/detail`,
        state: {
          courseEnrollmentId: row.courseEnrollmentId,
          savedSearchParams: prevSearchParams.current,
        },
      });
    },
  });

  const searchStudentsList = async ({
    search,
    searchBy,
    newPage,
  }: SearchParams & { newPage?: number }) => {
    isFirstSearch.current = false;
    setIsSearching(true);

    const { data, error } = await searchStudentsPostClose({
      search: search?.replace(/[^a-z0-9]/gi, ''),
      page: newPage || 0,
      searchBy,
    });

    if (error) {
      console.error(error);
    } else {
      prevSearchParams.current = {
        search,
        searchBy,
      };

      const mappedSearchResults = data?.data.map(mapSearchResult);
      setStudentsList(mappedSearchResults || []);
      setTotalPages(data?.total_pages || 0);

      if (newPage) setPage(newPage);
    }

    setIsSearching(false);
  };

  useEffect(() => {
    if (routerState?.savedSearchParams) {
      searchStudentsList(routerState.savedSearchParams);
    }
  }, [routerState]);

  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <h2 className="text-primary text-uppercase fs-20 fw-600">
          {t(`${prefix}.title`)}
        </h2>
        <p className="text-medium fs-16 py-2">{t(`${prefix}.description`)}</p>

        <div className="mt-3 w-100">
          <SearchBar
            initialParams={routerState?.savedSearchParams}
            onSearch={(params) => searchStudentsList(params)}
          />
        </div>

        <Table
          data={studentsList}
          columns={COLUMNS}
          isLoadingResults={isSearching}
          noResultsText={
            <div className="d-flex justify-content-center mb-3">
              {isFirstSearch.current ? (
                <DisplayInfo
                  title={t(`${prefix}.studentsTable.initialMsg.title`)}
                  textBody={t(`${prefix}.studentsTable.initialMsg.description`)}
                />
              ) : (
                <DisplayInfo
                  title={t(`${prefix}.studentsTable.noResultsMsg.title`)}
                  textBody={t(
                    `${prefix}.studentsTable.noResultsMsg.description`,
                  )}
                />
              )}
            </div>
          }
          pagination={{
            totalPages,
            currentPage: page + 1,
            itemsPerPage: STUDENTS_PER_PAGE,
            onChangePage: (newPage) => {
              searchStudentsList({
                ...prevSearchParams.current,
                newPage: newPage - 1,
              });
            },
            totalItems: Number.MAX_SAFE_INTEGER,
          }}
        />
      </Card>
    </div>
  );
}
