import { Button, Icon, Table, TablePagination } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDiscountsRequest } from '../../../api/requests/discounts';
import DisplayError from '../../../components/info/DisplayError';
import DisplayInfo from '../../../components/info/DisplayInfo';
import Loading from '../../../components/info/Loading';
import { TextEllipsis } from '../../../components/text';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import { TableColumns } from '../../../types/periodTuition';
import styles from '../style.module.scss';

import { DeleteDiscountModal } from './parts/DeleteDiscountModal';
import { DiscountModal } from './parts/DiscountModal';

export interface DiscountItem {
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

function Discount() {
  const { isAuthorizedTo } = useUserState();
  const [discounts, setDiscounts] = useState<DiscountItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState(null);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const { showTabs } = useLayoutState();
  useEffect(() => {
    showTabs();
  }, [showTabs]);

  const { t } = useTranslation();
  const prefix = 'tariffAndTuition.discounts';

  //Discounts columns
  const columns: TableColumns[] = [
    {
      columnName: 'code',
      headerText: t(`${prefix}.discount.code`),
      bold: true,
    },
    {
      columnName: 'name',
      headerText: t(`${prefix}.discount.name`),
    },
    {
      columnName: 'description',
      headerText: t(`${prefix}.discount.description`),
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis key={options.index} textWidth={350}>
          {options.value}
        </TextEllipsis>
      ),
    },
    {
      columnName: 'percentage',
      headerText: t(`${prefix}.discount.percentage`),
      cellFormat: (options: CellFormatOptions) => `${options.value}%`,
    },
    {
      columnName: 'id',
      headerText: '',
      width: '40px',
      cellFormat: (options: CellFormatOptions) => (
        <div className="d-flex justify-content-end">
          <span
            onClick={() => {
              setRecordToEdit(options.row);
              if (isAuthorizedTo([PermissionName.EDIT_DISCOUNTS])) {
                setShowModal(true);
              }
            }}
            className="cursor-pointer"
          >
            <Icon
              name="edit"
              key="edit"
              color="primary"
              className={
                !isAuthorizedTo([PermissionName.EDIT_DISCOUNTS])
                  ? 'icon-disabled'
                  : ''
              }
            />
          </span>
          <span
            onClick={() => {
              setItemToDelete(options.row.id);
              if (isAuthorizedTo([PermissionName.DELETE_DISCOUNTS])) {
                setShowModalDelete(true);
              }
            }}
            className="cursor-pointer ml-2"
          >
            <Icon
              name="trash"
              key="edit"
              color="secondary"
              className={
                !isAuthorizedTo([PermissionName.DELETE_DISCOUNTS])
                  ? 'icon-disabled'
                  : ''
              }
            />
          </span>
        </div>
      ),
    },
  ];

  /**
   * getDiscounts request
   * @param items quantity items
   * @param page page to load
   */

  const getDiscounts = useCallback(
    async (items: number = 10, page: number = 0) => {
      const { data, error } = await getDiscountsRequest(items, page);

      if (error) {
        setErrorLoading(error.code);
        return;
      }

      if (data) {
        setErrorLoading(undefined);
        const { data: discountItems = [], total = 0, total_pages = 1 } = data;
        setDiscounts(discountItems);
        setTotalItems(total);
        setTotalPages(total_pages);
        setShowPagination(true);
        setCurrentPage(1);
      }

      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  /**
   * changePage
   * @param page
   */
  const changePage = async (page: number = 1) => {
    await getDiscounts(10, page - 1);
    setCurrentPage(page);
  };

  useEffect(() => {
    getDiscounts();
  }, [getDiscounts]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
      />
    );
  }
  if (loading) {
    return <Loading insideCard className="mt-4" />;
  }

  return (
    <div className="g-table-container">
      <Button
        text={t(`${prefix}.newDiscount`)}
        type="button"
        size="sm"
        icon="plus"
        onClick={() => setShowModal(true)}
        className="g-add-button mb-3 mt-3"
        disabled={!isAuthorizedTo([PermissionName.CREATE_DISCOUNTS])}
      />

      <div className={styles.list}>
        <Table columns={columns} data={discounts} noResultsText={<div></div>} />
        {discounts.length === 0 ? (
          <DisplayInfo
            insideCard
            title={t(`${prefix}.discountTableEmptyTitle`)}
            textBody={t(`${prefix}.discountTableEmptySubtitle`)}
          />
        ) : null}
        {showPagination && totalItems > 10 && (
          <TablePagination
            pagination={{
              currentPage: currentPage,
              itemsPerPage: 10,
              onChangePage: (page) => {
                changePage(page);
              },
              totalItems: totalItems,
              totalPages: totalPages,
            }}
          />
        )}
      </div>
      <DiscountModal
        show={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
          setRecordToEdit(null);
        }}
        reloadDiscountList={getDiscounts}
        recordToEdit={recordToEdit}
      />
      <DeleteDiscountModal
        show={showModalDelete}
        toggleModal={() => {
          setShowModalDelete(!showModalDelete);
        }}
        reloadDiscountList={getDiscounts}
        itemToDelete={itemToDelete}
      />
    </div>
  );
}

export default Discount;
