import { useCallback, useState } from 'react';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';

type ModalData =
  | {
      open: true;
      onConfirm: (data: StudentDocumentReviewInfDocumentOther) => void;
      onClose: () => void;
    }
  | {
      open: false;
      document?: undefined;
    };

export function useOnUpload({
  onConfirm,
}: {
  onConfirm: (row: StudentDocumentReviewInfDocumentOther) => void;
}) {
  const [modalAddDocument, setModalAddDocument] = useState<ModalData>({
    open: false,
  });

  const onUpload = useCallback(async () => {
    const onClose = () => {
      setModalAddDocument({ open: false });
    };

    setModalAddDocument({ open: true, onConfirm, onClose });
  }, [onConfirm]);

  return {
    data: modalAddDocument,
    onUpload,
  };
}
