import { TextInput } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';

export interface SummaryPetitionProps {
    petitionTitle: string;
    petitionType: string;
    studyPlan: string;
    texts: {
        petitionTitle: string;
        petitionType: string;
        studyPlan: string;
    };
}

export default function StudentPetitionSummary({
    texts,
    ...data
}: SummaryPetitionProps) {
    return (
        <Row>
            <Col xs={12} sm={6} md={4} xl={''}>
                <TextInput
                    name="petitionTitle"
                    label={texts.petitionTitle}
                    value={data.petitionTitle}
                    disabled
                />
            </Col>
            <Col xs={12} sm={6} md={4} xl="">
                <TextInput
                    name="petitionType"
                    label={texts.petitionType}
                    value={data.petitionType}
                    disabled
                />
            </Col>
            <Col xs={12} sm={6} md={4} xl="">
                <TextInput
                    name="studyPlan"
                    label={texts.studyPlan}
                    value={data.studyPlan}
                    disabled
                />
            </Col>
        </Row>
    );
}
