import { Button, Modal as ModalCore } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DisplayError from '../../../components/info/DisplayError';

type ModalNoCoursesProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ModalNoCourses({
  isOpen,
  onClose,
}: ModalNoCoursesProps) {
  const prefix = 'courseValidationInternalStudent.curriculum.modalNoCourses';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      accept: t(`${prefix}.accept`),
    }),
    [t],
  );

  return (
    <ModalCore
      isOpen={isOpen}
      toggle={onClose}
      closeOnBackdrop={true}
      size="md"
    >
      <DisplayError title={texts.title} textBody={texts.body} />
      <Row>
        <Col xs={12}>
          <Button
            onClick={onClose}
            text={t('postulationDocs.buttons.close')}
            outlined
            fullwidth
          />
        </Col>
      </Row>
    </ModalCore>
  );
}
