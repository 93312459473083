import { useTranslation } from 'react-i18next';
import { ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { MissingObligatoryCourses } from '../../../types/graduationProcessSingle';

const dictPrefix =
  'graduationProcessSinglePendingRequirerments.failedMandatoryCoursesTable';

interface Props {
  data: MissingObligatoryCourses[];
}

export function FailedMandatoryCoursesTable({ data }: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<MissingObligatoryCourses>[] = [
    {
      headerText: t(`${dictPrefix}.code`),
      columnName: 'shortening',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.courseName`),
      columnName: 'name',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.credits`),
      columnName: 'credits',
      thClassName: 'text-center',
      tdClassName: 'text-center',
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center">
          <DisplayInfo
            iconName="information"
            textBody={t(`${dictPrefix}.missingObligatoryCoursesAlert`)}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
