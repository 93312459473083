import { Button, Icon, addToast } from '@octano/global-ui';
import { useCallback } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { ViewStudentsPetition } from '../Received/types/petitionDetail';
import { useTranslation } from 'react-i18next';

export interface FileDownloadProps {
  file?: ViewStudentsPetition['doc'];
  text: string;
  label: string;
}

const prefix = 'studentPetitionDetail.common';
export default function FileDownload({ file, label, text }: FileDownloadProps) {
  const { t } = useTranslation();

  const onDownload = useCallback(() => {
    if (file) {
      const link = document.createElement('a');
      link.download = file.name;
      link.href = file.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.downloads.downloadSuccess`)
      });
    }
  }, [file, t]);

  return (
    <Card>
      <label className="g-file-input-label text-left">{label}</label>
      <Row>
        <Col xs={12} sm={6}>
          <div className="g-file-input-container">
            {!!file && (
              <>
                <span>
                  <Icon name="file_img" size="25px" color="primary" />
                </span>
                <span className="g-file-input-name">{file.name}</span>
              </>
            )}
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            text={text}
            color="primary"
            outlined
            size="md"
            disabled={!file}
            onClick={onDownload}
            icon="download"
            fullwidth
          />
        </Col>
      </Row>
    </Card>
  );
}
