import { Collapse, ColumnTable, Table } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Legend } from '../../../components/curriculum';
import { getColor } from '../../../components/curriculum/utils';

import {
  CourseValidatedHistorySection,
  CourseHistorySection,
  CourseStatusTypes,
} from '../types/history';

type CourseStatusEnumType = {
  [key in CourseStatusTypes]: {
    color?: string;
    text: string;
  };
};

export const CourseStatus: CourseStatusEnumType = {
  E: { color: getColor('E'), text: 'enrolled' },
  A: { color: getColor('A'), text: 'approved' },
  P: { color: getColor('P'), text: 'pending' },
  R: { color: getColor('R'), text: 'failed' },
  V: { color: getColor('V'), text: 'validated' },
};

export interface ICourseHistoryAccordeonProps {
  courses: CourseValidatedHistorySection[];
  average?: string | number;
  title: string;
}

export default function CourseHistoryAccordeon({
  courses,
  average,
  title,
}: ICourseHistoryAccordeonProps) {
  const { t } = useTranslation();
  const hasSummary = average !== undefined;

  const prefix = 'curriculumProgressStudent.history.table';
  const texts = useMemo(
    () => ({
      headers: {
        shortening: t(`${prefix}.shortening`),
        name: t(`${prefix}.name`),
        credits: t(`${prefix}.credits`),
        status: t(`${prefix}.status`),
        score: t(`${prefix}.score`),
        semesterAverage: t(`${prefix}.semesterAverage`),
      },
      noScore: t(`${prefix}.noScore`),
      curriculumStatus: t<string, Record<string, string>>(
        `curriculumProgressStudent.curriculum.status`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const columns: ColumnTable<Partial<CourseHistorySection>>[] = useMemo(
    () => [
      {
        columnName: 'courseShorting',
        headerText: texts.headers.shortening,
      },
      {
        columnName: 'courseName',
        headerText: texts.headers.name,
      },
      {
        columnName: 'courseCredits',
        headerText: texts.headers.credits,
      },
      {
        columnName: 'studentSectionStatus',
        headerText: texts.headers.status,
        width: '15%',
        cellFormat: ({ row: course, data, index }) => {
          if (hasSummary && data!.length - 1 === index) {
            return (
              <div className="d-flex pl-5 ">
                <p className="m-auto">
                  <strong>{texts.headers.semesterAverage}</strong>
                </p>
              </div>
            );
          }

          const courseLegend = course.studentSectionStatus
            ? CourseStatus[course.studentSectionStatus]
            : null;

          return (
            <div className="d-flex justify-content-start pl-5">
              <Row className="g-legend-progression d-flex">
                <Col xs={6} sm="auto" className="g-status-container pr-4 py-2">
                  {courseLegend && (
                    <Legend
                      label={texts.curriculumStatus[courseLegend.text]}
                      color={courseLegend.color}
                    />
                  )}
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        columnName: 'gradeAvg',
        headerText: texts.headers.score,
        cellFormat: ({ row: course, data, index }) => {
          if (hasSummary && data!.length - 1 === index) {
            return (
              <p className="m-auto">
                <strong>{average ?? texts.noScore}</strong>
              </p>
            );
          }

          return <p>{course.grade ?? texts.noScore}</p>;
        },
      },
    ],
    [texts, average, hasSummary],
  );

  return (
    <div className="pb-4">
      <Collapse
        headerStyles={{ backgroundColor: '#f7f7f7', height: 50 }}
        contentClassName="pt-4 text-center"
        renderTitle={() => (
          <p className="fs-18  fw-600 my-auto text-primary">{title}</p>
        )}
      >
        <Table
          columns={columns}
          data={hasSummary ? [...courses, {}] : courses}
          striped
        />
      </Collapse>
    </div>
  );
}
