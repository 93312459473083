import { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import {
  Table,
  CellFormatOptions,
  Icon,
  useMobile,
  useWindowSize,
  ColumnTable,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { TextEllipsis } from '../../components/text';
import ReportActionsCell from './parts/ReportActionsCell/ReportActionsCell';

import { getAllReports } from '../../api/requests/reports';
import { Report } from '../../types/Reports';

const ITEMS_PER_PAGE = 6;

const filterColumnsByMobile = (
  onMobile: boolean,
  columns: ColumnTable<Report>[],
) => {
  return columns.filter((column) => {
    if (!onMobile) return true;
    return column.columnName !== 'description';
  });
};

export default function Reports() {
  const { t } = useTranslation();
  const prefix = 'reports';

  const isMobile = useMobile();
  const { width: windowWidth } = useWindowSize();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReports, setTotalReports] = useState(0);

  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const [reports, setReports] = useState<Report[]>([]);
  const [error, setError] = useState(false);

  const TABLE_COLUMNS = [
    {
      headerText: t(`${prefix}.tableColumns.report`),
      columnName: 'name',
    },
    {
      headerText: t(`${prefix}.tableColumns.description`),
      columnName: 'description',
      cellFormat: (data: CellFormatOptions<Report>) => {
        return (
          <TextEllipsis
            key={data.index}
            textWidth={windowWidth ? windowWidth * 0.35 : 320}
          >
            {data.row.description}
          </TextEllipsis>
        );
      },
    },
    {
      headerText: t(`${prefix}.tableColumns.action`),
      columnName: 'action',
      width: !isMobile ? '300px' : undefined,
      cellFormat: (data: CellFormatOptions<Report>) => (
        <ReportActionsCell report={data.row} />
      ),
    },
  ];

  useEffect(() => {
    const fetchReportsList = async () => {
      setIsLoadingReports(true);
      const { data, error: fetchError } = await getAllReports();

      if (fetchError) return setError(true);

      setTotalPages(data?.total_pages || 0);
      setTotalReports(data?.total || 0);

      const orderedReports = data?.data.sort((rp1, rp2) =>
        rp1.name > rp2.name ? 1 : -1,
      );
      setReports(orderedReports || []);

      setIsLoadingReports(false);
    };

    fetchReportsList();
  }, []);

  return (
    <>
      <Card className="p-4 mx-3" style={{ minHeight: 400 }}>
        <p className="mb-4 mt-2">{t(`${prefix}.pageDescription`)}</p>
        {error ? (
          <div className="d-flex justify-content-center align-items-center text-center">
            <div className="p-5" style={{ maxWidth: 500 }}>
              <Icon name="warning" size="50px" />
              <h1 className="fs-20 mt-4 text-dark">
                {t(`${prefix}.errors.reportsListError.title`)}
              </h1>
              <p className="fs-16 mt-3">
                {t(`${prefix}.errors.reportsListError.subtitle`)}
              </p>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <Table
              data={reports}
              columns={filterColumnsByMobile(isMobile, TABLE_COLUMNS)}
              pagination={{
                currentPage: currentPage + 1,
                itemsPerPage: ITEMS_PER_PAGE,
                totalItems: totalReports,
                totalPages: totalPages,
                onChangePage: (page) => setCurrentPage(page),
              }}
              isLoadingResults={isLoadingReports}
              loadingView={{
                title: t(`${prefix}.loading.generatingFile.title`),
                subtitle: t(`${prefix}.loading.generatingFile.description`),
              }}
              striped
            />
          </div>
        )}
      </Card>
    </>
  );
}
