import { Modal, SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import CreateAssistantFormFields from './CreateAssistantFormFields';

type ModalCreateAssistantProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  onSave: (values: CreateAssistantForm, reset: Function) => void;
};

export type CreateAssistantForm = {
  type: boolean;
  name: string;
  run: string;
  passportNumber: string;
  passportCountry: SelectOptionType;
  paternalLastName: string;
  maternalLastName: string;
  personalEmail: string;
  birthday: string;
};

const ModalCreateAssistant = ({
  isModalOpen,
  closeModal,
  onSave,
}: ModalCreateAssistantProps) => {
  const prefix = 'sectionsMaintainer.update.createAsistantForm';
  const { t } = useTranslation();

  const methods = useForm<CreateAssistantForm>();
  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    (values: CreateAssistantForm) => {
      onSave(values, methods.reset);
    },
    [onSave, methods],
  );

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal} size="xl">
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <p className="fs-22 text-dark text-center mb-4">
            {t(`${prefix}.title`)}
          </p>
          <Row>
            <Col xs={12} lg={10} className="mx-auto text-center">
              <p className="fs-16 text-light mb-5">{t(`${prefix}.subtitle`)}</p>
            </Col>
          </Row>

          <CreateAssistantFormFields onClose={closeModal} />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ModalCreateAssistant;
