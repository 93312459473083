import {
  Button,
  CheckInput,
  DateInput,
  Select,
  TextInput,
} from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useParameters } from '../../../hooks/useParameters';
import { useValidations } from '../../../hooks/useValidations';
import { CreateAssistantForm } from './ModalCreateAssistant';

type CreateAssistantFormFieldsProps = {
  onClose: () => void;
};

const CreateAssistantFormFields = ({
  onClose,
}: CreateAssistantFormFieldsProps) => {
  const prefix = 'sectionsMaintainer.update.createAsistantForm';
  const { t } = useTranslation();
  const { control, watch } = useFormContext<CreateAssistantForm>();

  const { validateTextNotEmpty, validateRut, validateEmail, msgValidations } =
    useValidations();

  const { countryOptions } = useParameters();

  const isPassport = watch('type');

  return (
    <>
      <Row className="pb-2">
        <Col xs={12} lg={4}>
          <TextInput
            name="name"
            label={t(`${prefix}.name`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col xs={12} lg={4}>
          <TextInput
            name="paternalLastName"
            label={t(`${prefix}.paternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col xs={12} lg={4}>
          <TextInput
            name="maternalLastName"
            label={t(`${prefix}.maternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col xs={12} lg={isPassport ? 8 : 4}>
          {isPassport ? (
            <Row>
              <Col>
                <Select
                  label={t(`${prefix}.country`)}
                  name="passportCountry"
                  isSearchable={true}
                  options={countryOptions}
                  rules={{
                    required: msgValidations.required,
                    validate: validateTextNotEmpty,
                  }}
                  control={control}
                />
              </Col>
              <Col>
                <TextInput
                  name="passportNumber"
                  control={control}
                  label={t(`${prefix}.passport`)}
                  rules={{ validate: validateTextNotEmpty }}
                />
              </Col>
            </Row>
          ) : (
            <TextInput
              name="run"
              label={t(`${prefix}.rut`)}
              control={control}
              formatter="rut"
              rules={{
                validate: {
                  empty: validateTextNotEmpty,
                  rut: validateRut,
                },
              }}
            />
          )}

          <CheckInput
            name="type"
            label={t(`${prefix}.passportCheck`)}
            value={isPassport}
            control={control}
          />
        </Col>
        <Col xs={12} lg={4}>
          <TextInput
            name="personalEmail"
            label={t(`${prefix}.email`)}
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                email: validateEmail,
              },
            }}
          />
        </Col>
        <Col xs={12} lg={4}>
          <DateInput
            name="birthday"
            control={control}
            label={t(`${prefix}.birthdate`)}
            rules={{ required: msgValidations.required }}
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col xs={12} lg={8} xl={7} className="mx-auto">
          <Row>
            <Col xs={12} lg={6} className="pb-2 pb-lg-0">
              <Button
                text={t('common.actions.close')}
                outlined
                onClick={() => {
                  onClose();
                }}
                fullwidth
              />
            </Col>
            <Col xs={12} lg={6}>
              <Button
                type="submit"
                text={t('common.actions.confirm')}
                fullwidth
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreateAssistantFormFields;
