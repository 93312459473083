import { ReactNode, useCallback } from 'react';
import FetchProvider from '../../../components/contexts/FetchContext';
import { getCurriculumProgressStudents } from '../requests';
import {
  CurriculumProgressStudents,
  CurriculumProgressStudentsQuery,
} from '../types';

export interface CurriculumProgressLoaderTableProps {
  children: ReactNode;
}

export function CurriculumProgressLoaderTable({
  children,
}: CurriculumProgressLoaderTableProps) {
  const request = useCallback(
    async (query: CurriculumProgressStudentsQuery) => {
      return getCurriculumProgressStudents(query);
    },
    [],
  );
  return (
    <FetchProvider<CurriculumProgressStudentsQuery, CurriculumProgressStudents>
      request={request}
      fetchImmediately
      defaultQuery={{
        search: {
          studyPlanVersionId: undefined,
          status: undefined,
          searchText: undefined,
          periodId: undefined,
          campusId: undefined,
        },
        page: 1,
      }}
    >
      {children}
    </FetchProvider>
  );
}
