import { ReactElement, useCallback } from 'react';

import { AxiosResultDefaultError } from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { getPostulant } from '../api';
import { StudentFileShowResponse } from '../type';
import { useParams } from 'react-router-dom';

export interface StudentFileFilterLoaderProps {
  onError?: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    refresh: () => Promise<void>;
    postulant: any;
    educationalBackground: any;
    parentalEducationalBackground: any;
    contact: any;
    familyContact: any;
    emergencyContact: any;
    healthCare: any;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentFileShowResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function PostulantLoader({
  onError,
  children,
}: StudentFileFilterLoaderProps) {
  const { postulantId } = useParams<{ postulantId: string }>();

  const request = useCallback(async () => {
    return getPostulant(postulantId);
  }, [postulantId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError && onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            refresh,
            postulant: data,
            educationalBackground: data?.educationalBackground,
            parentalEducationalBackground: data?.parentalEducationalBackground,
            contact: data?.contact,
            familyContact: data?.familyContact,
            emergencyContact: data?.emergencyContact,
            healthCare: data?.healthCare,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
