import request from '../request';
import { TUITION_CONTINUITY_DOCUMENT_REVIEW } from '../endpoints';
import {
  TcDocumentReviewResponse,
  TcDocumentReviewStudentTabResponse,
} from '../../types/tuitionContinuityDocumentReview';
import { StudentListParams } from '../../views/TuitionContinuityProcessDocumentReviewDetail/parts/TuitionContinuityDocumentReviewDetailLoader';

export const getTcDocumentReviewList = (periodId: number | string) =>
  request<{ data: TcDocumentReviewResponse[] }>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.LIST(periodId),
    {
      method: 'get',
    },
  );

export const getTcDocumentReviewStudentTabs = (query: StudentListParams) =>
  request<{ data: TcDocumentReviewStudentTabResponse }>(
    TUITION_CONTINUITY_DOCUMENT_REVIEW.STUDENT_TABS,
    {
      method: 'get',
      params: query,
    },
  );
