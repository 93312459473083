import { CellFormatOptions, ColumnTable } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TuitionContinuityProcessDetailStudentResponseDto } from '../../../api/requests/tuitionContinuityProcessManagement';
import { formatDate } from '../../../utils/dates';
import { format as formatPercentage } from '../../../utils/percentage';
import TuitionContinuityProcessManagementDetailStudentsLoader from '../parts/TuitionContinuityProcessManagementDetailStudentsLoader';
import TuitionContinuityProcessManagementDetailTab from '../parts/TuitionContinuityProcessManagementDetailTab';

export interface TuitionContinuityProcessManagementDetailCompleteProps {
  studyPlanVersionOfferId: number | string;
  periodId: number | string;
}

export default function TuitionContinuityProcessManagementDetailComplete({
  studyPlanVersionOfferId,
  periodId,
}: TuitionContinuityProcessManagementDetailCompleteProps) {
  const { t } = useTranslation();

  const columns: ColumnTable<TuitionContinuityProcessDetailStudentResponseDto>[] =
    [
      {
        columnName: 'rut',
        headerText: t(`common.forms.rut`),
        width: '110px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        bold: true,
      },
      {
        columnName: 'names',
        headerText: t(`common.forms.name`),
        width: '320px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions) =>
          `${options.row['lastNames']}, ${options.row['names']}`,
      },
      {
        columnName: 'cellPhone',
        headerText: t(`common.forms.phone`),
        width: '150px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'email',
        headerText: t(`common.forms.email`),
        width: '150px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'enrollmentDate',
        headerText: t(`common.forms.enrollmentDate`),
        width: '150px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<TuitionContinuityProcessDetailStudentResponseDto>,
        ) =>
          options.row.enrollmentDate
            ? formatDate(options.row.enrollmentDate, 'DD-MM-YYYY')
            : '',
      },
      {
        columnName: 'discountPercentage',
        headerText: t(`common.forms.discount`),
        width: '150px',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<TuitionContinuityProcessDetailStudentResponseDto>,
        ) => formatPercentage(options.row.discountPercentage ?? 0),
      },
    ];

  return (
    <TuitionContinuityProcessManagementDetailStudentsLoader
      studyPlanVersionOfferId={studyPlanVersionOfferId}
      periodId={periodId}
      status="completed"
    >
      <TuitionContinuityProcessManagementDetailTab columns={columns} />
    </TuitionContinuityProcessManagementDetailStudentsLoader>
  );
}
