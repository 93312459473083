import {
  Button,
  Collapse,
  ColumnTable,
  Table,
  TextOutlinedInput,
} from '@octano/global-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TariffListResponse,
  TcTariffSubmitData,
  TcTariffSubmitPendingData,
  TcTariffTableData,
} from '../../../../types/tuitionContinuityTrariff';
import { Form } from 'reactstrap';
import { TariffFormatter } from '../../../../utils/currency';
import CustomBadge from '../../../tariffAndTuition/tariff/pats/TariffCustomBadge';
import { useForm } from 'react-hook-form';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';

interface TcTariffAccordionProps {
  row: TcTariffTableData;
  onFormatData: (data: {
    data: TcTariffSubmitData[];
    pending: TcTariffSubmitPendingData[];
  }) => void;
  periodId: number;
  isLoading: boolean;
  texts: {
    accordion: Record<string, string>;
  };
}

interface IForm {
  [key: string]: string;
}

export default function TcTariffAccordion({
  row,
  onFormatData,
  periodId,
  isLoading,
  texts,
}: TcTariffAccordionProps) {
  const namePattern = '-offerId-:';
  const { handleSubmit, control } = useForm<IForm>({ mode: 'onChange' });
  const [globalStatus, setGlobalStatus] = useState<number | undefined>(1);
  const { isAuthorizedTo } = useUserState();
  const submitData = useCallback(
    (data: Record<string, string>) => {
      const updatedOffer = Object.entries(data).reduce(
        (acc, [key, value]) => {
          const offerId = Number(key.replace(namePattern, ''));
          const offer = row.offers.find((x) => x.offerId === offerId);
          const finalValue = Number(value.replace(/\D/g, ''));
          if (finalValue > 0) {
            acc.data.push({
              offerId: Number(key.replace(namePattern, '')),
              periodId,
              tariff: { id: offer?.tariff?.id, value: finalValue },
              offerPeriodId: offer?.offerPeriodId,
            });
          } else if (offer) {
            acc.pending.push({
              campusName: offer.campusName,
              scheduleName: offer.scheduleName,
              studyPlanName: offer.studyPlanName,
            });
          }

          return acc;
        },
        {
          data: new Array<TcTariffSubmitData>(),
          pending: new Array<TcTariffSubmitPendingData>(),
        },
      );
      onFormatData(updatedOffer);
    },
    [onFormatData, periodId, row.offers],
  );

  useEffect(() => {
    for (const offer of row.offers) {
      if (!offer.tariff) {
        setGlobalStatus(undefined);
        break;
      }
    }
  }, [row.offers]);
  const columns = useMemo<ColumnTable<Partial<TariffListResponse>>[]>(
    () => [
      {
        columnName: 'campusName',
        bold: false,
        headerText: texts.accordion.campus,
        width: '20%',
        cellFormat: (options) => {
          return <p>{options.row.campusName}</p>;
        },
      },
      {
        columnName: 'schedule',
        headerText: texts.accordion.schedule,
        width: '20%',
        cellFormat: (options) => {
          return <p>{options.row.scheduleName}</p>;
        },
      },
      {
        columnName: 'tariff',
        headerText: texts.accordion.tariff,
        width: '20%',
        cellFormat: ({ row: offer }) => {
          if (offer.offerId === 0) {
            return (
              <Button
                type="submit"
                text={texts.accordion.submit}
                size="sm"
                loading={isLoading}
                disabled={
                  !isAuthorizedTo([
                    PermissionName.TUITION_CONTINUITY_TARIFF_SAVE_CREATE,
                  ])
                }
                fullwidth
              />
            );
          } else {
            const inputName = `${namePattern}${offer.offerId}`;
            return (
              <TextOutlinedInput
                textAlign="center"
                type="text"
                name={inputName}
                defaultValue={TariffFormatter(
                  offer.tariff?.value,
                  offer.tariff?.currency,
                )}
                control={control}
                formatter={(value) =>
                  TariffFormatter(value, offer.tariff?.currency)
                }
              />
            );
          }
        },
      },

      {
        columnName: 'status',
        headerText: texts.accordion.status,
        width: '20%',
        tdClassName: 'text-center',
        cellFormat: ({ row: offer, index, data }) => {
          return (
            index !== data!.length - 1 && (
              <CustomBadge value={offer.tariff?.value} />
            )
          );
        },
      },
    ],
    [texts, isLoading, isAuthorizedTo, control],
  );
  return (
    <Form key={row.id} onSubmit={handleSubmit(submitData)}>
      <Collapse
        renderTitle={() => <p className="fs-14 text-dark fw-400">{row.name}</p>}
        renderBtn={({ isOpened, toggle }) => (
          <>
            <CustomBadge value={globalStatus} className="ml-auto" />
            <Collapse.ToggleButton
              iconName={isOpened ? 'minus' : 'plus'}
              toggle={toggle}
              btnClassName="bg-primary ml-3"
              iconColor="white"
            />
          </>
        )}
      >
        <Table
          columns={columns}
          data={[
            ...row.offers,
            { offerId: 0, studyPlanName: '', campusName: '', scheduleName: '' },
          ]}
          striped
          color="transparent"
          borderless={false}
        />
      </Collapse>
    </Form>
  );
}
