import { ColumnTable, DisplayInfo, Icon, Table } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { TextEllipsis } from '../../../components/text';
import { StudentsDegreeRow } from '../../../types/studentsDegree';
import StudentDegreeStatusBadge from '../../../components/badgeStatus/StudentDegreeStatusBadge';
import { Link } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { useMemo } from 'react';

const dictPrefix = 'studentsDegree.table';

interface Props {
  status: STUDENT_STATUS;
  data: StudentsDegreeRow[];
  isLoadingResults: boolean;
  totalPetitions: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function PetitionsTable({
  status,
  data,
  isLoadingResults,
  totalPages,
  totalPetitions,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();

  const columnsAndFilter = useMemo<
    {
      column: ColumnTable<StudentsDegreeRow>;
      filter: { status: string } | null;
    }[]
  >(() => {
    return [
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.studentName`),
          columnName: 'studentName',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: ({ row }) => {
            return (
              <p>
                {row?.studentLastnames}, {row?.studentName}
                <br />
                {format(row?.studentRut)}
              </p>
            );
          },
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.faculty`),
          columnName: 'schoolName',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: (options) => options.value,
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.graduationPeriod`),
          columnName: 'periodEntryName',
          tdClassName: 'text-center',
          thClassName: 'text-center',
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.studyPlanVersionName`),
          columnName: 'studyPlanVersionName',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: (options: CellFormatOptions) => (
            <TextEllipsis
              key={options.index}
              textWidth={300}
              tooltip={options.value}
            >
              {options.value}
            </TextEllipsis>
          ),
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.campusName`),
          columnName: 'campusName',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: (options: CellFormatOptions) => (
            <TextEllipsis
              key={options.index}
              textWidth={150}
              tooltip={options.value}
            >
              {options.value}
            </TextEllipsis>
          ),
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.status`),
          columnName: 'studyPlanEnrollmentStatus',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: ({ row, value }) => {
            return (
              <StudentDegreeStatusBadge
                status={value}
                name={row.studentId}
                showToolTips={false}
              />
            );
          },
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.gradeGraduation`),
          columnName: 'gradeGraduation',
          tdClassName: 'text-center',
          thClassName: 'text-center',
        },
      },
      {
        filter: { status: STUDENT_STATUS.TITLED },
        column: {
          headerText: t(`${dictPrefix}.columns.gradeDegree`),
          columnName: 'gradeDegree',
          tdClassName: 'text-center',
          thClassName: 'text-center',
        },
      },
      {
        filter: null,
        column: {
          headerText: t(`${dictPrefix}.columns.action`),
          columnName: 'studyPlanEnrollmentId',
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: ({ row, value }) => {
            return row.studyPlanEnrollmentStatus === STUDENT_STATUS.GRADUATE ? (
              <Link to={`${PathsLayouts.studentsDegree}/${value}`}>
                <Icon
                  className="custom-edit"
                  name="edit"
                  key="edit"
                  size="22px"
                />
              </Link>
            ) : (
              <Link to={`${PathsLayouts.studentsDegree}/${value}`}>
                <Icon className="custom-eye" name="eye" key="eye" size="22px" />
              </Link>
            );
          },
        },
      },
    ];
  }, []);

  const columns = useMemo(() => {
    return columnsAndFilter
      .filter((c) => (c.filter ? c.filter.status === status : true))
      .map((c) => c.column);
  }, [columnsAndFilter, status]);

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: totalPetitions,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(
                `${dictPrefix}.${
                  hasFilters ? 'noResultsFilter' : 'noResults'
                }.description`,
              )}
              textBody=""
              maxWidth="650px"
            />
          </div>
        }
      />
    </>
  );
}
