import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getPackageOfSection } from '../../api/requests/MantainerPortal';
import { PackagesListHeader } from '../../types/packageTypes';

export enum PackagesListContextErrorCode {
  Connection = 'CONNECTION',
  Authentication = 'AUTHENTICATION',
}
export interface PackagesListContextState {
  loading: boolean;
  data: Array<PackagesListHeader>;
  error?: 'UNKNOWN';
  fetchData: () => void;
}

const contextDefaultValues: PackagesListContextState = {
  loading: true,
  data: [],
  error: undefined,
  fetchData: () => {
    throw new Error('Method not implemented');
  },
};

export const PackagesListContext =
  createContext<PackagesListContextState>(contextDefaultValues);

export function usePackagesList() {
  return useContext(PackagesListContext);
}

const PackagesListProvider: FC<{
  fetchImmediately?: boolean;
  periodId: number;
}> = ({ fetchImmediately = false, children, periodId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<PackagesListHeader>>([]);
  const [error, setError] = useState<'UNKNOWN' | undefined>(undefined);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const result = await getPackageOfSection(periodId);
    if (result.error) {
      setError('UNKNOWN');
    } else {
      setError(undefined);
      setData(result.data);
    }
    setLoading(false);
  }, [periodId]);

  useEffect(() => {
    if (fetchImmediately) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  return (
    <PackagesListContext.Provider value={{ loading, data, error, fetchData }}>
      {children}
    </PackagesListContext.Provider>
  );
};

export default PackagesListProvider;
