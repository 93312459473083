import { useCallback, useEffect, useState } from 'react';
import { getPackageEnrolledStudents } from '../../api/requests/packages';
import DisplayError from '../../components/info/DisplayError';
import { PackagesViewsStudent } from '../../types/packageTypes';

export interface PackagesViewStudentsLoaderProps {
  packageId: number | string;
  children: (
    sections: null | PackagesViewsStudent[],
    loading: boolean,
  ) => JSX.Element;
}

export const PackagesViewStudentsLoader = ({
  packageId,
  children,
}: PackagesViewStudentsLoaderProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PackagesViewsStudent[] | null>(null);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  const fetchData = useCallback(async (id: string | number) => {
    setLoading(true);
    const response = await getPackageEnrolledStudents(id);
    if (response.data) {
      /**
       * Si es que hay data pero no ID entonces no lo encontró
       */
      setData(response.data.data);
    } else if (response.error) {
      setError(response.error.code);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(packageId);
  }, [fetchData, packageId]);

  if (error) {
    return <DisplayError textBody={error} />;
  }

  return children(data, loading);
};
