import { CheckInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Card } from 'reactstrap';
import DisplayInfo from '../../components/info/DisplayInfo';
import TuitionProcessSearchStudentForm from '../TuitionProcessSearchStudentForm';
import { TuitionProcessSearchStudentByPassport } from '../TuitionProcessSearchStudentFormByPassport';
import { TuitionProcessForm } from '../TuitionProcessForm';
import {
  searchByPassportNumberAndCountry,
  searchRUTRequest,
} from '../../api/requests/postulants';
import { useLoadingState } from '../../hooks/useLoadingState';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { useLocation } from 'react-router';
import { validate } from 'rut.js';
import { Student } from '../../types/tuitionProcessOnSite';
import { useHistory } from 'react-router-dom';

export const SearchStudentOnSite = () => {
  const [submited, setSubmited] = useState<boolean>(false);
  const { t } = useTranslation();
  const [student, setStudent] = useState<Student | null>();
  const location = useLocation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const history = useHistory();

  const id = useMemo<string | null>(() => {
    return location.search.split('?id=')[1];
  }, [location]);

  const query = useMemo<{
    rut?: string;
    country?: string;
    passport?: string;
  } | null>(() => {
    if (!id) return null;
    else {
      let isRut = validate(id);
      let passport = id.split('|');
      return isRut
        ? {
            rut: id,
          }
        : {
            country: passport[0],
            passport: passport[passport.length - 1],
          };
    }
  }, [id]);

  const [showPassport, setShowPassport] = useState<boolean>(
    id ? !validate(id) : false,
  );

  const onsubmit = useCallback(
    async (data: any) => {
      setSubmited(false);
      setLoading(true);
      let { data: dataResponse, error } = data.rut
        ? await searchRUTRequest(data.rut)
        : await searchByPassportNumberAndCountry(data.country, data.passport);
      if (error) {
        if (error?.data?.statusCode === 404) {
          newStudentPending(data);
        } else {
          setErrorLoading(error.code);
        }
      } else if (dataResponse) {
        const { Admission, New_admission } = dataResponse;
        setStudent({
          postulant_id: dataResponse.Postulant_id,
          rut: dataResponse.Postulant_rut ? dataResponse.Postulant_rut : '',
          passportNumber: dataResponse.Postulant_passportNumber
            ? dataResponse.Postulant_passportNumber
            : '',
          country: data.country ? data.country : '',
          names: dataResponse.Postulant_names,
          lastnamePather: dataResponse.Postulant_paternalLastName,
          lastnameMother: dataResponse.Postulant_maternalLastName,
          email: dataResponse.Contact_email,
          phone: dataResponse.Contact_cellPhone,
          birthdate: dataResponse.Postulant_birthday,
          status: dataResponse.Admission.Details_status,
          admissionTypeId: Admission.Postulation_admissionTypeId,
          studyPlanVersion_name: Admission.StudyPlanVersion_name,
          campus_name: Admission.Campus_name,
          schedule_name: Admission.Schedule_name,
          Details_id: Admission.Details_id,
          newAdmission: New_admission
            ? {
                postulationDetailId: New_admission.Details_id,
                postulationDetailStatus: New_admission.Details_status,
                admissionTypeId: New_admission.Postulation_admissionTypeId,
                studyPlanVersionName: New_admission.StudyPlanVersion_name,
                campusName: New_admission.Campus_name,
                scheduleName: New_admission.Schedule_name,
              }
            : undefined,
        });
      }
      setLoading(false);
      setSubmited(true);
    },
    [setErrorLoading, setLoading],
  );

  const onSubmitAndSavePath = useCallback(
    async (data: any) => {
      let queryID = showPassport
        ? `${data.country}|${data.passport}`
        : data.rut;
      await onsubmit(data);
      history.replace(`${location.pathname}?id=${queryID}`);
    },
    [onsubmit, showPassport, history, location.pathname],
  );

  useEffect(() => {
    if (query) {
      onsubmit(query);
    } else {
      setLoading(false);
    }
  }, [onsubmit, setLoading, query]);

  useEffect(() => {
    setSubmited(false);
    setStudent(null);
  }, [showPassport]);

  function newStudentPending(data: {
    rut?: string;
    passport?: string;
    country?: string;
  }) {
    setStudent({
      rut: data.rut ? data.rut : '',
      passportNumber: data.passport ? data.passport : '',
      country: data.country ? data.country : '',
      names: '',
      lastnamePather: '',
      lastnameMother: '',
      email: '',
      phone: '',
      status: 'sin_postulacion',
      // TODO: Como sabemos a que proceso se va a inscribir ahora?
      admissionTypeId: 'SIN_SUA',
    });
  }

  const preload = useCallback((): void => {
    onsubmit(query);
  }, [onsubmit, query]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  } else if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }

  return (
    <div className="mx-3">
      <div className="mb-n2">
        {!showPassport ? (
          <TuitionProcessSearchStudentForm onSubmit={onSubmitAndSavePath} />
        ) : (
          <TuitionProcessSearchStudentByPassport
            onSubmit={onSubmitAndSavePath}
          />
        )}
      </div>
      <CheckInput
        name="idType"
        value={showPassport}
        label={`${t('tuitionProcess.searchStudent.enterPassport')}`}
        onChange={() => {
          setSubmited(false);
          setShowPassport(!showPassport);
        }}
      />

      <Card className="p-3 p-md-4">
        {!submited && !student && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100%', margin: 'auto' }}
          >
            <DisplayInfo
              maxWidth="376px"
              title={t(`tuitionProcess.searchStudent.enterRutPassportTitle`)}
              textBody={t(`tuitionProcess.searchStudent.enterRutPassportBody`)}
            />
          </div>
        )}
        {student && submited && (
          <TuitionProcessForm
            student={student}
            passport={showPassport}
            preload={preload}
          />
        )}
      </Card>
    </div>
  );
};
