import { Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Container } from 'reactstrap';

export const SearchIdNotFound = () => {
  const { t } = useTranslation();
  const prefix = 'common.searchId.notFound';
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
    }),
    [t],
  );

  return (
    <Container className="mb-5">
      <Row className="text-center justify-content-center">
        <Col xs={10}>
          <Icon name="error" color="secondary" size="65px" />
        </Col>
        <Col xs={10} className="py-4">
          <h4 className="text-dark">{texts.title}</h4>
        </Col>
        <Col xs={7}>
          <span className="text-light fw-400 fs-16">{texts.body}</span>
        </Col>
      </Row>
    </Container>
  );
};
