import { Loading, TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import {
  FetchContext,
  FetchContextState,
} from '../../components/contexts/FetchContext';
import DisplayError from '../../components/info/DisplayError';
import { StudentDocumentReviewInfResponseDto } from '../../types/tuitionContinuityDocumentReviewDocuments';
import { TuitionContinuityProcessManagementDetailBox } from '../TuitionContinuityProcessManagementDetail/parts/TuitionContinuityProcessManagementDetailBox';
import { TuitionContinuityDocumentReviewDocumentsLoader } from './parts/TuitionContinuityDocumentReviewDocumentsLoader';
import { TuitionContinuityDocumentReviewDocumentsTable } from './parts/TuitionContinuityDocumentReviewDocumentsTable';
import TuitionContinuityProcessDocumentReviewDocumentsBack from './parts/TuitionContinuityDocumentReviewDocumentsBack';
import { TuitionContinuityDocumentReviewDocumentsOthers } from './parts/others/TuitionContinuityDocumentReviewDocumentsOthers';

export default function TuitionContinuityProcessDocumentReviewDocuments() {
  const { t } = useTranslation();
  const { tcProcessId } = useParams<{
    tcProcessId: string;
  }>();

  const prefix = 'tuitionContinuityDocumentReviewStudent';
  const texts = useMemo(
    () => ({
      label: {
        id: t(`${prefix}.form.id`),
        names: t(`${prefix}.form.names`),
        lastNames: t(`${prefix}.form.lastNames`),
        cellPhone: t(`${prefix}.form.cellPhone`),
        email: t(`${prefix}.form.email`),
      },
    }),
    [t],
  );

  return (
    <Card className="mx-4 p-4">
      <TuitionContinuityDocumentReviewDocumentsLoader tcProcessId={tcProcessId}>
        <FetchContext.Consumer>
          {({
            loading,
            data,
            error,
          }: FetchContextState<
            undefined,
            StudentDocumentReviewInfResponseDto,
            { code: string; data: any }
          >) => {
            if (loading) {
              return <Loading textBody="Cargando..." />;
            }
            if (error) {
              return <DisplayError textBody={error.code} />;
            }
            if (!data) {
              return <DisplayError textBody="HTTP_ERROR" />;
            }
            return (
              <>
                <Row>
                  <Col className="mt-3">
                    <TuitionContinuityProcessDocumentReviewDocumentsBack
                      periodId={data.periodId}
                      studyPlanVersionOfferId={
                        data.studyPlan.studyPlanVersionOfferId
                      }
                    />
                  </Col>
                  <Col md={8} lg={6} className="">
                    <TuitionContinuityProcessManagementDetailBox
                      campusName={data.studyPlan.campusName}
                      scheduleName={data.studyPlan.schedule}
                      studyPlanVersionName={data.studyPlan.studyPlanVersionName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="mt-4">
                    <TextInput
                      name="id"
                      disabled
                      value={data.student.rut}
                      label={texts.label.id}
                    />
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextInput
                      name="names"
                      disabled
                      value={data.student.name}
                      label={texts.label.names}
                    />
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextInput
                      name="lastNames"
                      disabled
                      value={data.student.lastNames}
                      label={texts.label.lastNames}
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <TextInput
                      name="cellPhone"
                      disabled
                      value={data.student.cellPhone}
                      label={texts.label.cellPhone}
                    />
                  </Col>
                  <Col md={6} className="mt-4">
                    <TextInput
                      name="email"
                      disabled
                      value={data.student.email}
                      label={texts.label.email}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4">
                    <TuitionContinuityDocumentReviewDocumentsTable
                      tcProcessId={tcProcessId}
                      data={data.documents}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-4">
                    <TuitionContinuityDocumentReviewDocumentsOthers
                      tcProcessId={tcProcessId}
                      data={
                        data.documentsOthers || (data as any).otherDocuments
                      }
                    />
                  </Col>
                </Row>
              </>
            );
          }}
        </FetchContext.Consumer>
      </TuitionContinuityDocumentReviewDocumentsLoader>
    </Card>
  );
}
