import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import { useLoadingState } from '../../../hooks/useLoadingState';
import {
  CellFormatOptions,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import DisplayError from '../../../components/info/DisplayError';
import { Table } from '@octano/global-ui';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { AcademicOffersTypeDuplicate } from '../../../types/AcademicOffersType';
import { getAcademicOffersNoDraftRequest } from '../../../api/requests/academicOffers';
import { AcademicOffersListDuplicateType } from './interfaces/academic-offer.interface';
// valores iniciales para la paginación
const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;

const AcademicOffersListDuplicate = ({
  periodId,
  selectedRow,
  setSelectedRow,
}: AcademicOffersListDuplicateType) => {
  const { t } = useTranslation();

  // data para la tabla
  const [academicOffersDuplicate, setAcademicOffersDuplicate] = useState<
    PaginationRequestType<AcademicOffersTypeDuplicate>
  >({
    data: [],
    total_pages: 0,
    total: 0,
  });

  // actualizacion de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);

  // loading
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  // obtener la lista de versiones
  const getAcademicOffersDuplicate = useCallback(async () => {
    if (!periodId) {
      setAcademicOffersDuplicate({
        data: [],
        total_pages: 0,
        total: 0,
      });
      setLoading(false);
      return;
    }

    const { error, data: response } = await getAcademicOffersNoDraftRequest(
      Number(periodId),
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setAcademicOffersDuplicate(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [periodId, itemsPerPage, currentPage, setErrorLoading, setLoading]);

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  // cuando cambia el evento del radio
  const inputRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = +e.target.value;
    const row = academicOffersDuplicate.data.find(
      (valueF) => valueF.id === value,
    );
    setSelectedRow(row);
  };

  // si es el mismo lo quita
  const clickRadio = (row: AcademicOffersTypeDuplicate) => {
    if (row.id === selectedRow?.id) {
      setSelectedRow(null);
    }
  };

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (academicOffersDuplicate.total > itemsPerPage) {
      return {
        totalItems: academicOffersDuplicate.total,
        itemsPerPage: itemsPerPage,
        totalPages: academicOffersDuplicate.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [academicOffersDuplicate, itemsPerPage, currentPage, handlerOnChangePage]);

  // columnas para las tablas
  const columnsTable = [
    {
      columnName: 'radio',
      headerText: '',
      width: '7%',
      cellFormat: (options: CellFormatOptions<AcademicOffersTypeDuplicate>) => {
        return (
          <input
            type="radio"
            className={'radio-button-table'}
            checked={selectedRow?.id === +options.row.id}
            value={options.row.id}
            onChange={inputRadioChange}
            onClick={() => {
              clickRadio(options.row);
            }}
          />
        );
      },
    },
    {
      columnName: 'name',
      headerText: t('academicOffers.list.academicPeriod'),
      width: '20%',
      cellFormat: (options: CellFormatOptions<AcademicOffersTypeDuplicate>) => {
        return <div className={'ellipsis'}>{options.row.period.name}</div>;
      },
    },
    {
      columnName: 'startDate',
      headerText: t('academicOffers.list.startDate'),
      width: '20%',
      cellFormat: (options: CellFormatOptions<AcademicOffersTypeDuplicate>) =>
        dayjs(options.row.period.startDate).format('DD/MM/YYYY'),
    },
    {
      columnName: 'endDate',
      headerText: t('academicOffers.list.endDate'),
      width: '20%',
      cellFormat: (options: CellFormatOptions<AcademicOffersTypeDuplicate>) =>
        dayjs(options.row.period.endDate).format('DD/MM/YYYY'),
    },
    {
      columnName: 'quota',
      headerText: t('academicOffers.list.quota'),
      width: '20%',
    },
  ];

  useEffect(() => {
    getAcademicOffersDuplicate();
  }, [getAcademicOffersDuplicate]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <></>;
  }

  return (
    <div>
      <div className="g-table-container padding-none">
        <div id={'academic-offers-duplicate-table'}>
          <Table
            columns={columnsTable}
            data={academicOffersDuplicate.data}
            pagination={pagination}
            noResultsText={
              <TableEmptyContent
                title={
                  !periodId
                    ? t('academicOffers.list.ShouldSelectPeriod')
                    : t('academicOffers.list.TableEmptyTitle')
                }
                subtitle={''}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AcademicOffersListDuplicate;
