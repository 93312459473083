import { AxiosRequestConfig } from 'axios';
import { ReportsResponse } from '../../types/Reports';
import { StudyPlanVersion } from '../../types/studyPlan';
import { REPORTS } from '../endpoints';
import request from '../request';

/**
 * Pide la lista de reportes disponibles para descargar
 * @returns
 */
export const getAllReports = () => {
  const url = REPORTS.GET_ALL;
  return request<ReportsResponse>(url);
};

/**
 * Descarga el archivo de reporte del url especificado
 * @param downloadUrl El trozo final del endpoint que identifica de donde se decarga el documnento
 * @param queryParams
 * @param responseType
 * @returns
 */
export const downloadReport = (
  downloadUrl: string,
  queryParams?: any,
  responseType: AxiosRequestConfig['responseType'] = 'blob',
) => {
  const url = REPORTS.DOWNLOAD_BY_URL(downloadUrl);

  return request(url, { params: queryParams, responseType });
};

export const uploadReportData = (uploadUrl: string, data: any) => {
  const url = REPORTS.UPLOAD_DATA_BY_URL(uploadUrl);
  return request<{
    statusCode: number;
    message: string;
    rows_with_conflicts?: any[];
  }>(url, { method: 'POST', data });
};

export type StudyPlanVersionSelect = Pick<StudyPlanVersion, 'name' | 'id'> & {
  id: number;
};

export interface GetStudyPlanVersionOFPortalResponse {
  studyPlanVersionList: StudyPlanVersionSelect[];
}

/**
 * Pide la lista de StudyPlanVersion disponibles
 * @returns
 */
export function getStudyPlanVersionOFPortal() {
  const url = REPORTS.GET_STUDY_PLAN_VERSION_REPORTS;
  return request<GetStudyPlanVersionOFPortalResponse>(url, {
    method: 'get',
  });
}
