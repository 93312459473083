import { Redirect, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { PathsLayouts } from '../../config/routes';
import PackagesViewContent from '../../packages/PackagesViewContent';
import { PackagesViewLoader } from '../../packages/parts/PackagesViewLoader';

function PackagesView(): JSX.Element {
  const { id: packageId } = useParams<{ id?: string }>();

  if (!packageId) {
    return <Redirect to={PathsLayouts.packages} />;
  }

  return (
    <Card className="p-4 mx-3">
      <PackagesViewLoader packageId={packageId}>
        {({ package: packageData, timeModules }) => {
          return (
            <PackagesViewContent {...packageData} timeModules={timeModules} />
          );
        }}
      </PackagesViewLoader>
    </Card>
  );
}

export default PackagesView;
