import {
  CourseMaintainerListResponse,
  CourseRequest,
  CreateCourseBody,
  UpdateCourseBody,
} from '../../types/courseMaintainerTypes';
import { COURSE_MAINTAINER } from '../endpoints';
import request from '../request';

/**
 * Obtiene listado de las asignaturas
 */
export const requestCoursesMaintainerList = (data: {
  items_per_page: number;
  page: number;
  schoolId?: string | number;
  search_text?: string | null;
}) => {
  return request<CourseMaintainerListResponse>(`${COURSE_MAINTAINER.LIST}`, {
    params: data,
  });
};

export const requestCourseTypes = () => {
  return request<{ data: { id: number; name: string; priority: number }[] }>(
    `${COURSE_MAINTAINER.COURSE_TYPES}`,
  );
};

export const requestCreateCourse = (data: CreateCourseBody) => {
  return request<any>(COURSE_MAINTAINER.CREATE_COURSE, {
    method: 'POST',
    data,
  });
};

export const requestGetCourse = (courseId: number) => {
  return request<{ data: CourseRequest }>(
    `${COURSE_MAINTAINER.GET_COURSE(courseId)}`,
  );
};

export const requestUpdateCourse = (data: UpdateCourseBody) => {
  return request<any>(COURSE_MAINTAINER.UPDATE_COURSE(data.id), {
    method: 'put',
    data,
  });
};
