import { useMemo } from 'react';
import { Button, ColumnTable, Icon, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { SectionCourseRegistration } from '../../../types/courseRegistrationTypes';

const DEFAULT_PARAMS = {
  onTrashClick: () => null,
  onSectionChangeClick: () => null,
};

interface Params {
  onTrashClick: (sectionId: number) => void;
  onSectionChangeClick: (
    sectionCourseRegistration: SectionCourseRegistration,
  ) => void;
}

export const useColumnsCourseRegistrationDetail = (params?: Params) => {
  const prefix = 'courseRegistrationList.detail';
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { onTrashClick, onSectionChangeClick } = params || DEFAULT_PARAMS;

  const columns: ColumnTable<SectionCourseRegistration>[] = useMemo(() => {
    const fullSizeColumns: ColumnTable<SectionCourseRegistration>[] = [
      {
        columnName: 'courseShortening',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center vertical-align-top',
        headerText: t(`${prefix}.courseShortening`),
        cellFormat: ({ row }) => row.course.shortening,
      },
      {
        columnName: 'courseName',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.courseName`),
        cellFormat: ({ row }) => row.course.name,
      },
      {
        columnName: 'name',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.section`),
      },
      {
        columnName: 'professors',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.teacher`),
        cellFormat: ({ row }) =>
          row.professors.map((professor) => (
            <div key={`${row.id}_${professor.account.id}`}>
              {professor.account.fullName}
            </div>
          )),
      },
      {
        columnName: 'action',
        thClassName: 'text-center',
        tdClassName: 'text-center text-nowrap',
        headerText: t(`${prefix}.action`),
        cellFormat: ({ row }) => {
          return (
            <Button
              size="sm"
              text={t(`${prefix}.btnChangeSection`)}
              onClick={() => onSectionChangeClick(row)}
            />
          );
        },
      },
      {
        columnName: 'delete',
        tdClassName: 'text-center text-nowrap',
        headerText: '',
        cellFormat: ({ row }) => {
          return (
            <div
              className="cursor-pointer text-light mx-2"
              onClick={() => onTrashClick(row.id)}
            >
              <Icon name="trash" size={18} color="inherit" />
            </div>
          );
        },
      },
    ];

    const mobileColumnsNames = ['courseName', 'action', 'delete'];
    const mobileColumns = fullSizeColumns
      .filter((column) => mobileColumnsNames.includes(column.columnName))
      .map((mobileColumn) =>
        mobileColumn.columnName === 'courseName'
          ? { ...mobileColumn, tdClassName: `${mobileColumn.tdClassName} w-50` }
          : mobileColumn,
      );

    return isMobile ? mobileColumns : fullSizeColumns;
  }, [t, onTrashClick, onSectionChangeClick, isMobile]);

  return columns;
};
