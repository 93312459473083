import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Card, Col, Row } from 'reactstrap';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { PathsLayouts } from '../../../config/routes';
import { SectionDetail } from '../../../types/sectionMaintainerTypes';
import AsignPackageToSectionForm from './AsignPackageToSectionForm';
import SectionBox from './SectionBox';
import SectionUpdateGeneral from './SectionUpdateGeneral';
import SectionUpdateSchedules from './SectionUpdateSchedules';

export interface SectionUpdateTabsProps {
  section: SectionDetail;
  onRefresh: () => void;
}

export default function SectionUpdateTabs({
  section,
  onRefresh,
}: SectionUpdateTabsProps) {
  const { t } = useTranslation();
  const tabsPrefix = 'sectionsMaintainer.tabs';

  const location = useLocation();
  const history = useHistory();

  const goBack = () => {
    history.push({
      pathname: PathsLayouts.sectionsMaintainer,
    });
  };

  const tabs = useMemo(
    () => [
      {
        name: 'general',
        path: '',
        content: (
          <SectionUpdateGeneral section={section} onRefresh={onRefresh} />
        ),
      },
      {
        name: 'schedules',
        path: 'horarios',
        content: <SectionUpdateSchedules section={section} />,
      },
      {
        name: 'packages',
        path: 'paquetes',
        content: <AsignPackageToSectionForm />,
      },
    ],
    [section, onRefresh],
  );

  const initialTab = useMemo(() => {
    const currentTab = location.pathname.split('/').reverse()[0];

    if (tabs.some((tab) => tab.name === currentTab)) {
      return currentTab;
    }

    return tabs[0].name;
  }, [location, tabs]);

  return (
    <Card className="p-4 mt-2 mx-3">
      <Row className="mb-3">
        <Col xs={12} lg={6}>
          <GoBackButton onClick={goBack} />
        </Col>
        <Col xs={12} lg={6} className="py-3 py-lg-0">
          <SectionBox section={section} />
        </Col>
      </Row>
      <Tabs defaultTab={initialTab}>
        <TabsList>
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              id={tab.name}
              children={t(`${tabsPrefix}.${tab.name}`)}
            />
          ))}
        </TabsList>
        <TabPanels>
          {tabs.map((tab) => (
            <TabPanel key={tab.name} id={tab.name} children={tab.content} />
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
}
