import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DisplayInfo from '../../../components/info/DisplayInfo';
import TuitionContinuityProcessManagementItem from './TuitionContinuityProcessManagementItem';
import TuitionContinuityProcessManagementTotalItem from './TuitionContinuityProcessManagementTotalItem';

export interface TuitionContinuityProcessManagementListProps {
  periodId: number | string;
  data: {
    studyPlanVersionOfferId: number;
    studyPlanVersionName: string;
    campusName: string;
    scheduleName: string;
    total: number;
    completed: number;
    started: number;
    notStarted: number;
  }[];
}

export default function TuitionContinuityProcessManagementList(
  props: TuitionContinuityProcessManagementListProps,
) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagement.listEmpty';

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
    }),
    [t],
  );

  const totals = useMemo(
    () =>
      props.data.reduce(
        (prev, curr) => ({
          total: prev.total + curr.total,
          completed: prev.completed + curr.completed,
          started: prev.started + curr.started,
          notStarted: prev.notStarted + curr.notStarted,
        }),
        { total: 0, completed: 0, started: 0, notStarted: 0 },
      ),
    [props.data],
  );

  if (!props.data.length) {
    return <DisplayInfo insideCard title={texts.title} textBody={texts.body} />;
  }
  return (
    <Row>
      <Col md={4} className="mb-4">
        <TuitionContinuityProcessManagementTotalItem {...totals} />
      </Col>
      {props.data.map((info) => (
        <Col md={4} key={info.studyPlanVersionOfferId} className="mb-4">
          <TuitionContinuityProcessManagementItem
            periodId={props.periodId}
            {...info}
          />
        </Col>
      ))}
    </Row>
  );
}
