import { Button, ColumnTable } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';
import { PathsLayouts } from '../../../config/routes';
import { CurriculumProgressStudent } from '../types';

export function useColumns() {
  const prefix = 'curriculumProgress.headers';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      headers: t<string, Record<string, string>>(`${prefix}`, {
        returnObjects: true,
      }),
      status: t<string, Record<string, string>>(`studyPlanEnrollmentStatus`, {
        returnObjects: true,
      }),
      action: t(`curriculumProgress.action`),
    }),
    [t],
  );

  const history = useHistory();
  const onAction = useCallback(
    (row: { studyPlanEnrollmentId: number }) => () => {
      history.push(
        `${PathsLayouts.curriculumProgress}/${row.studyPlanEnrollmentId}`,
      );
    },
    [history],
  );

  const columns: ColumnTable<CurriculumProgressStudent>[] = useMemo(() => {
    return [
      {
        columnName: 'id',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.nameAndID,
        cellFormat: ({ row }) => getNameAndId(row),
      },
      {
        columnName: 'studyPlanVersionName',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.studyPlanVersion,
      },
      {
        columnName: 'campusName',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.campus,
      },
      {
        columnName: 'scheduleName',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.schedule,
      },
      {
        columnName: 'periodEntryName',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.periodEntry,
      },
      {
        columnName: 'status',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.status,
        cellFormat: ({ row }) => {
          return (
            <div style={{ minWidth: '150px' }}>
              {texts.status[row.studyPlanEnrollmentStatus]}
            </div>
          );
        },
      },
      {
        columnName: 'action',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: texts.headers.action,
        cellFormat: ({ row }) => {
          return (
            <Button
              text={texts.action}
              size="sm"
              color="primary"
              onClick={onAction(row)}
            />
          );
        },
      },
    ];
  }, [texts, onAction]);

  return columns;
}

function getNameAndId(row: CurriculumProgressStudent) {
  return (
    <div className="text-uppercase">
      <div>
        {row.studentLastnames}, {row.studentName}
      </div>
      <div className="text-nowrap">
        {row.studentRut ? format(row.studentRut) : row.studentPassport}
      </div>
    </div>
  );
}
