import {
  Button,
  Modal,
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  getStudyPlanVersionOFPortal,
  GetStudyPlanVersionOFPortalResponse,
  StudyPlanVersionSelect,
} from '../../../api/requests/reports';
import { useLoadingState } from '../../../hooks/useLoadingState';
import GeneratingFileModal from './GeneratingFileModal';
import NoResultsModal from './NoResultsModal';

interface Props {
  isOpen: boolean;
  modalTitle: string;
  onConfirm?: (periodId: number) => void;
  onCancel?: () => void;
}

export default function SelectStudyPlanVersionModal({
  isOpen,
  modalTitle,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const prefix = 'reports.studyPlanVersion';

  const isMobile = useMobile();
  const { t } = useTranslation();

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [studyPlanVersions, setStudyPlanVersions] = useState<
    GetStudyPlanVersionOFPortalResponse['studyPlanVersionList']
  >([]);
  const [currentStudyPlanVersion, setCurrentPeriod] =
    useState<StudyPlanVersionSelect>();

  const getPeriods = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStudyPlanVersionOFPortal();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading(undefined);
      setStudyPlanVersions(data.studyPlanVersionList);
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  const changePeriod = useCallback(
    (option: OutlinedSelectOptionType) => {
      const studyPlanVersion = studyPlanVersions.find(
        ({ id }) => option.value === id,
      ) as StudyPlanVersionSelect;
      setCurrentPeriod(studyPlanVersion);
    },
    [studyPlanVersions],
  );

  useEffect(() => {
    if (isOpen) getPeriods();
  }, [getPeriods, isOpen]);

  const options = useMemo<OutlinedSelectOptionType[]>(() => {
    return studyPlanVersions.map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    });
  }, [studyPlanVersions]);

  const studyPlanVersionSelected = useMemo(() => {
    return options.find(({ value }) => currentStudyPlanVersion?.id === value);
  }, [options, currentStudyPlanVersion]);

  if (loading && isOpen) {
    return <GeneratingFileModal isOpen />;
  }

  if (errorLoading && isOpen) {
    return (
      <NoResultsModal
        isOpen
        onConfirm={onCancel}
        texts={{
          title: t(`${prefix}.noResults.title`),
          description: t(`${prefix}.noResults.description`),
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">{modalTitle}</h1>
        <p className=" text-center fs-16 lh-30 mt-4 mb-5">
          {t(`${prefix}.datesConfig.subtitle`)}
        </p>
        <Row>
          <Col xs={12}>
            <OutlinedSelect
              name="studyPlanVersionId"
              label={t(`${prefix}.form.studyPlanVersion`)}
              value={studyPlanVersionSelected}
              options={options}
              isClearable={false}
              onChange={changePeriod}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-between mt-5"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common.actions.cancel`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={t(`common.actions.download`)}
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={() => onConfirm(studyPlanVersionSelected!.value as number)}
          />
        </div>
      </div>
    </Modal>
  );
}
