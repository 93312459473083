import { useTranslation } from 'react-i18next';
import { Button, Modal, Icon } from '@octano/global-ui';

const dictPrefix = 'graduationProcessMassive.failedProcessingGraduatesModal';

interface Props {
  isOpen: boolean;
  onAcceptClick: () => void;
}

export function FailedProcessingGraduatesModal({
  isOpen,
  onAcceptClick = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size={50} />
        <h2 className="text-dark fs-22 mb-3 mt-4">
          {t(`${dictPrefix}.title`)}
        </h2>
        <p className="text-medium fs-18 mb-5">
          {t(`${dictPrefix}.description`)}
        </p>
        <Button
          text={t(`common.actions.accept`)}
          onClick={onAcceptClick}
          style={{ width: 270 }}
        />
      </div>
    </Modal>
  );
}
