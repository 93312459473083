import React from 'react';
import { Col, Row } from 'reactstrap';
import { PackagesListHeader } from '../../types/packageTypes';
import { PackagesListCard } from './PackagesListCard';
import { PackagesListHeaderCard } from './PackagesListHeaderCard';

export interface PackagesListProps {
  data: Array<PackagesListHeader>;
}

export default function PackagesList({ data }: PackagesListProps) {
  return (
    <React.Fragment>
      {data.map((offer) => (
        <React.Fragment key={offer.academicOfferStudyPlanVersionOfferId}>
          <Row>
            <Col md={6} lg={3} className="mb-4">
              <PackagesListHeaderCard {...offer} />
            </Col>
            {offer.packages.map((packageData) => {
              return (
                <Col key={packageData.id} md={6} lg={3} className="mb-4">
                  <PackagesListCard {...packageData} />
                </Col>
              );
            })}
          </Row>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
