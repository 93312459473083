import { CellFormatOptions, ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';

export interface UseColumnsProps {
  onDownload: (documentId: number | string) => Promise<void>;
  onDelete: (row: StudentDocumentReviewInfDocumentOther) => Promise<void>;
}

export function useColumns({ onDownload, onDelete }: UseColumnsProps) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityDocumentReviewStudent';

  const texts = useMemo(
    () => ({
      headers: {
        name: t(`${prefix}.headers.name`),
        description: t(`${prefix}.headers.description`),
      },
    }),
    [t],
  );

  return useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: texts.headers.name,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '20%',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocumentOther>) => {
          if (!row.id) {
            return '-';
          }
          return `${row.name}.${row.extension}`;
        },
      },
      {
        columnName: 'description',
        headerText: texts.headers.description,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '60%',
        cellFormat: ({
          row,
        }: CellFormatOptions<StudentDocumentReviewInfDocumentOther>) => {
          return row.metadata?.description;
        },
      },
      {
        columnName: 'action',
        headerText: '',
        width: '20%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<StudentDocumentReviewInfDocumentOther>,
        ) => {
          return (
            <div className="d-flex" style={{ gap: '24px' }}>
              <div onClick={() => onDownload(options.row.id)}>
                <Icon
                  name="download"
                  color={options.row.id ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div onClick={() => onDelete(options.row)}>
                <Icon name="trash" color="primary" className="icon-selector" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [texts, onDownload, onDelete]);
}
