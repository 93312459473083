import { OutlinedSelectOptionType } from '@octano/global-ui';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  TuitionContinuityProcessManagementErrors,
  TuitionContinuityProcessManagementList,
  TuitionContinuityProcessManagementListFormat,
  getTuitionContinuityProcessManagementList,
} from '../../../api/requests/tuitionContinuityProcessManagement';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';

export interface TuitionContinuityProcessManagementLoaderProps<T> {
  periodId: number;
  campusId?: number;
  children: (props: T) => ReactElement;
}

export function TuitionContinuityProcessManagementLoader({
  periodId,
  campusId,
  children,
}: Readonly<
  TuitionContinuityProcessManagementLoaderProps<TuitionContinuityProcessManagementListFormat>
>) {
  const prefix = 'tuitionContinuityProcessManagement';
  const { t } = useTranslation();

  const { loading, setLoading } = useLoadingState();

  const [data, setData] = useState<TuitionContinuityProcessManagementList>({
    studyPlanVersionOffers: [],
    period: { id: 0, name: '' },
  });

  const [selectedSchool, setSchool] = useState<
    OutlinedSelectOptionType | undefined
  >();

  const onChangeSchool = useCallback((option?: OutlinedSelectOptionType) => {
    setSchool(option);
  }, []);

  const schoolOption = useMemo<OutlinedSelectOptionType[]>(
    () =>
      data.studyPlanVersionOffers.reduce(
        (previousValue, { schoolId, schoolName }) => {
          const school = previousValue.find((s) => s.value === schoolId);
          if (!school) {
            previousValue.push({ label: schoolName, value: schoolId });
          }
          return previousValue;
        },
        new Array<OutlinedSelectOptionType>(),
      ),
    [data],
  );

  const [error, setError] =
    useState<TuitionContinuityProcessManagementErrors>();

  const filterData = useMemo(
    () =>
      data.studyPlanVersionOffers.filter(
        (st) => !selectedSchool || st.schoolId === selectedSchool.value,
      ),
    [data.studyPlanVersionOffers, selectedSchool],
  );

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);
    const response = await getTuitionContinuityProcessManagementList(
      periodId,
      campusId,
    );

    if (response.data) {
      setData(response.data);
      setError(undefined);
      setSchool(undefined);
    }
    if (response.error) {
      setError(response.error);
    }
    setLoading(false);
  }, [setLoading, periodId, campusId]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    const { title, body } = getErrorTexts(error, t, prefix);
    return (
      <DisplayError
        insideCard
        title={title}
        textBody={body}
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!data) {
    return (
      <DisplayError
        insideCard
        textBody="Data no cargada"
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  return children({
    ...data,
    schoolOption,
    onChangeSchool,
    studyPlanVersionOffers: filterData,
  });
}

function getErrorTexts(
  error: TuitionContinuityProcessManagementErrors,
  t: TFunction<'translation'>,
  prefix: string,
) {
  if (error.code === 'MISSING_EVENT') {
    return {
      title: t(`${prefix}.error.no-event.title`),
      body: t(`${prefix}.error.no-event.body`),
    };
  }
  if (error.code === 'INCOMING_EVENT' || error.code === 'PERIOD_EXCEEDED') {
    return {
      title: t(`${prefix}.error.event-locking.title`),
      body: t(`${prefix}.error.event-locking.body`, {
        start: dayjs(error.range[0]).format('DD-MM-YYYY'),
        end: dayjs(error.range[1]).format('DD-MM-YYYY'),
      }),
    };
  }
  return {
    body: error.code,
  };
}
