import { useTranslation } from 'react-i18next';
import { PackagesViewsSection } from '../../../types/packageTypes';

import { ColumnTable, Table } from '@octano/global-ui';
import TableEmptyContent from '../../../components/text/TableEmptyContent';

type SectionDetailTableProps = {
  sections: PackagesViewsSection[] | null;
};

const SectionDetailTable = ({ sections }: SectionDetailTableProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.create.step2.table';

  const columns: ColumnTable<any>[] = [
    {
      columnName: 'maximumStudent',
      headerText: t(`${prefix}.maximumStudent`),
    },
    {
      columnName: 'availability',
      headerText: t(`${prefix}.availability`),
    },
    {
      columnName: 'name',
      headerText: t(`${prefix}.sections`),
    },
  ];

  return (
    <>
      <Table
        borderless={false}
        striped={false}
        height={700}
        columns={columns}
        data={
          sections?.map((row: any) => ({
            maximumStudent: row.maximumStudent,
            availability: row.id,
            name: row.name,
          })) ?? []
        }
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
};

export default SectionDetailTable;
