import { TextInput, Select } from '@octano/global-ui';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { SectionTitle } from '../../../components/text';
import useUserState from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import { PermissionName } from '../../../types/Auth';
import AcademicOffersEditTable from './AcademicOffersEditTable';
import {
  AcademicOffersEditFormProps,
  AcademicOffersEditValues,
} from './interfaces/academic-offer.interface';

const AcademicOffersEditForm = (props: AcademicOffersEditFormProps) => {
  const [confirmed] = useState<boolean>(false);

  const { isAuthorizedTo } = useUserState();

  const { t } = useTranslation();
  const prefix = 'academicOffers.form';

  const methods = useForm<AcademicOffersEditValues>({
    mode: 'onSubmit',
    defaultValues: {
      school: null,
      places: null,
    },
  });
  const { handleSubmit, control, watch } = methods;

  const [school] = watch(['school']);

  const {
    periodId,
    schools,
    hasDuplicatedPeriod,
    academicOfferId,
    reloadViewData,
    setSchoolSelected,
  } = props;

  const { msgValidations } = useValidations();
  const onSubmit = useCallback(async () => {
    return null;
  }, []);
  useEffect(() => {
    if (confirmed) {
      //  agregar logica para guardar
    }
  }, [confirmed]);

  useEffect(() => {
    if (school) setSchoolSelected(school);
  }, [school, setSchoolSelected]);

  const {
    quota,
    enrolled = '',
    duplicatedQuota = '',
  } = schools.find((e) => school && e.id === school.value) || {};

  return (
    <Fragment>
      <Form
        className="mt-5"
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Row className="mt-5">
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.placesConfirmTitle`)} />
            <div className="fs-18 mb-3">
              {t(`${prefix}.placesConfirmSubtitle`)}
            </div>
            <Row>
              <Col xs={12} md={hasDuplicatedPeriod ? 3 : 6} className="pb-3">
                <Select
                  name="school"
                  label={t('common.forms.school')}
                  options={schools.map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))}
                  control={control}
                  rules={{ required: msgValidations.required }}
                />
              </Col>
              {hasDuplicatedPeriod && (
                <>
                  <Col xs={12} md={3} className="pb-3">
                    <TextInput
                      name="lastPlaces"
                      disabled
                      value={`${duplicatedQuota}`}
                      label={t(`${prefix}.lastPlaces`)}
                    />
                  </Col>
                  <Col xs={12} md={3} className="pb-3">
                    <TextInput
                      name="duplicatedEnrolled"
                      disabled
                      value={enrolled}
                      label={t('academicOffers.list.enrolled')}
                    />
                  </Col>
                </>
              )}
              <Col xs={12} md={hasDuplicatedPeriod ? 3 : 6} className="pb-3">
                <TextInput
                  name="newPlaces"
                  label={t(`${prefix}.newPlaces`)}
                  value={`${quota === undefined ? '' : quota}`}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col xs={12}>
            <AcademicOffersEditTable
              periodId={periodId}
              schoolId={school ? Number(school.value) : null}
              academicOfferId={academicOfferId}
              reloadViewData={reloadViewData}
              onlyView={!isAuthorizedTo([PermissionName.EDIT_ACADEMIC_OFFER])}
            />
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AcademicOffersEditForm;
