import { Button, TextAreaInput, TextInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { AxiosResult, AxiosResultDefaultError } from '../../../../api/request';
import {
  DiscountItem,
  saveDiscountRequest,
} from '../../../../api/requests/discounts';
import CardTitle from '../../../../components/text/CardTitle';
import { useValidations } from '../../../../hooks/useValidations';

export interface Discount {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: string;
}

interface DiscountFormProps {
  recordToEdit: Discount | null;
  onSubmit: (
    result: AxiosResult<DiscountItem, AxiosResultDefaultError>,
  ) => void;
  onCancel: () => void;
}

const getTraslationsTexts = (editing: boolean) => {
  const prefix = 'tariffAndTuition.discounts';
  return editing
    ? {
        title: `${prefix}.discount.formEditDiscount`,
        button: 'common.actions.confirm',
      }
    : {
        title: `${prefix}.discount.formNewDiscount`,
        button: `${prefix}.discount.formCreateDiscount`,
      };
};

export const DiscountForm = ({
  recordToEdit,
  onSubmit: onSubmitProp,
  onCancel,
}: DiscountFormProps) => {
  const { t } = useTranslation();
  const { msgValidations, validateTextNotEmpty } = useValidations();
  const prefix = 'tariffAndTuition.discounts';
  const getDefaultValues = (record: Discount | null) =>
    record
      ? {
          code: record.code,
          name: record.name,
          description: record.description ?? '',
          percentage: String(record.percentage),
        }
      : {
          code: '',
          name: '',
          description: '',
          percentage: '',
        };
  const methods = useForm<Discount>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(recordToEdit),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setError,
  } = methods;

  const onSubmit = useCallback(
    async (values: Discount) => {
      const { code, name, description, percentage } = values;
      const requestBody = {
        code,
        name,
        description,
        percentage: Number(percentage),
        id: recordToEdit?.id,
      };

      const result = await saveDiscountRequest(requestBody);
      if (result.error && result.error.code === 'HTTP_ERROR') {
        let messageError = t('common.errors.codeError', { code: values.code });
        //
        if (
          result.error.data.message[0] ===
          'code must contain only letters and numbers'
        ) {
          messageError = t('common.errors.onlyLettersNumbers');
        }
        setError('code', {
          message: messageError,
        });
        return;
      }
      onSubmitProp(result);
    },
    [onSubmitProp, setError, t, recordToEdit],
  );

  const editing = !!recordToEdit;
  const texts = getTraslationsTexts(editing);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle children={t(texts.title)} />
            </div>
          </Col>
        </Row>
        <Row className="mt-0 mt-md-4">
          <Col xs="12" md="12">
            <TextInput
              name="name"
              disabled={editing}
              label={t('common.forms.name')}
              control={control}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 50,
                  message: t('common.validations.maxLength', {
                    length: 50,
                  }),
                },
                minLength: {
                  value: 3,
                  message: t('common.validations.minLength', {
                    length: 3,
                  }),
                },
                validate: validateTextNotEmpty,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <TextInput
              name="code"
              control={control}
              disabled={editing}
              label={t('common.forms.code')}
              rules={{
                required: msgValidations.required,
                maxLength: {
                  value: 15,
                  message: t('common.validations.maxLength', {
                    length: 15,
                  }),
                },
                minLength: {
                  value: 3,
                  message: t('common.validations.minLength', {
                    length: 3,
                  }),
                },
                validate: validateTextNotEmpty,
              }}
            />
          </Col>
          <Col xs="12" md="6">
            <TextInput
              name="percentage"
              control={control}
              label={t(`${prefix}.discount.percentage`)}
              formatter={(value: string) => {
                const percentage = Number(value.replace(/\D/g, ''));
                return percentage < 1 || percentage > 100
                  ? ''
                  : String(percentage);
              }}
              rules={{
                required: msgValidations.required,
                validate: validateTextNotEmpty,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextAreaInput
              name="description"
              control={control}
              disabled={editing}
              label={`${t('common.forms.description')} ${t(
                'common.forms.optionalLabel',
              )}`}
              height={200}
              placeholder={t(`common.forms.descriptionPlaceholder`)}
              rules={{
                maxLength: {
                  value: 200,
                  message: t('common.validations.maxLength', {
                    length: 200,
                  }),
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <Button
              type="button"
              text={t('common.actions.cancel')}
              outlined
              onClick={onCancel}
              fullwidth
            />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
            <Button
              type="submit"
              text={t(texts.button)}
              loading={isSubmitting}
              fullwidth
              className="mb-3 mb-md-0"
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
