import { useTranslation } from 'react-i18next';
import { Card } from '../../components/article/Card';
import { PathsLayouts } from '../../config/routes';

export interface PackagesListCardProps {
  id: number;
  name: string;
  location: string;
  sections: number;
  quota: number;
  enrolled: number;
  spaceAvailable: number | null;
}

export function PackagesListCard({
  id,
  name,
  location,
  sections,
  quota,
  enrolled,
  spaceAvailable,
}: PackagesListCardProps) {
  const { t } = useTranslation();

  return (
    <Card
      title={name}
      subtitle={location}
      body={[
        {
          label: t('packages.sections'),
          value: sections,
        },
        {
          label: t('packages.totalQuotas'),
          value: quota,
        },
        {
          label: t('packages.inscribed'),
          value: enrolled,
        },
        {
          label: t('packages.availableQuotas'),
          value: spaceAvailable === null ? 'null' : spaceAvailable,
        },
      ]}
      markedText={{
        label: t('packages.taken'),
        value: quota ? `${Math.round((enrolled * 100) / quota)}%` : '-',
      }}
      to={`${PathsLayouts.packages}/${id}`}
      toText={t('packages.viewDetails')}
      className="package-card"
    />
  );
}
