import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { Table, Button, useMobile, addToast } from '@octano/global-ui';
import { Badge } from 'reactstrap';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { useTranslation } from 'react-i18next';
import { TextEllipsis } from '../../../../../components/text';
import AcademicOffersAddModal, {
  ConfirmModalData,
} from '../../modals/AcademicOffersAddModal';
import { CampusBody } from '../../../../../types/CampusTypes';
import { ScheduleItem } from '../../../../../maintainers/schedule/ScheduleList';
import { useLayoutState } from '../../../../../hooks/useLayoutState';
import { getCampusRequest } from '../../../../../api/requests/campus';
import { getScheduleList } from '../../../../../api/requests/schedules';
import { confirmQuota } from '../../../../../api/requests/academicOffers';
import styles from './index.module.scss';
import {
  StudyPlanItemCustom,
  VersionOffer,
} from '../../interfaces/study-plan-item-custom.interface';

import {
  buildDuplicatedRow,
  buildEmptyRow,
  buildRequestBody,
  buildRow,
} from './expandable-cell.service';

interface ExpandableCellProps {
  name: string;
  versions: VersionOffer[];
  duplicatedOffers?: VersionOffer[];
  studyPlanId: number;
  academicOfferId: number;
  studyPlanItem: StudyPlanItemCustom;
  getStudyPlansBySchool: () => void;
  reloadViewData: () => void;
  onlyView?: boolean;
  textEmpaty?: {
    subtitle?: string;
  };
}

const ExpandableCell = ({
  name,
  versions,
  duplicatedOffers,
  studyPlanId,
  academicOfferId,
  studyPlanItem,
  getStudyPlansBySchool,
  reloadViewData,
  onlyView = false,
  textEmpaty = {},
}: ExpandableCellProps) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<VersionOffer[]>([]);
  const [pristine, setPristine] = useState<boolean>(true);
  const [tableQuota, setTableQuota] = useState<string[]>([]);

  // abrir modal
  const [modalToShow, setModalToShow] = useState<string | null>(null);
  const [campusModal, setCampusModal] = useState<CampusBody[]>([]);
  const [schedulesModal, setSchedulesModal] = useState<ScheduleItem[]>([]);

  const empatySubtitle = textEmpaty.subtitle
    ? textEmpaty.subtitle
    : t('academicOffers.form.StudyPlanAcademicOffersTableEmptySubtitle');

  // mostrar error al guardar
  const { showErrorModal } = useLayoutState();

  // llamar datos antes de abrir el modal
  const onclick = () => {
    const campusRequest = getCampusRequest(9999);
    const schedulesRequest = getScheduleList(9999);

    Promise.all([campusRequest, schedulesRequest]).then(
      (values) => {
        // SEDE
        setCampusModal(values[0].data?.data ? values[0].data.data : []);
        // JORNADA
        setSchedulesModal(values[1].data?.data ? values[1].data.data : []);
        setModalToShow('show');
      },
      () => {
        showErrorModal();
      },
    );
  };

  // Agregar nueva oferta
  const addData = (values: ConfirmModalData) => {
    const newOffer = buildRow(studyPlanItem, values, academicOfferId);

    setPristine(false);
    setTableQuota([...tableQuota, String(values.quota)]);
    setTableData((data) => {
      const filter = data.filter(
        (item) => item.StudyPlanVersionOffer_id !== -1,
      );

      filter.push(newOffer);
      return addEmptyRow(filter);
    });
    setModalToShow(null);
  };

  useEffect(() => {
    if (versions.length) {
      const newTableQuota: string[] = versions.map((item) => {
        if (item.AcademicOfferStudyPlanVersionOffers_quota !== null) {
          return `${item.AcademicOfferStudyPlanVersionOffers_quota}`;
        }
        return '';
      });
      setTableQuota(newTableQuota);
    }

    let duplicated: VersionOffer[] = [];
    if (duplicatedOffers?.length) {
      duplicated = duplicatedOffers.map((item) => {
        return buildDuplicatedRow(studyPlanItem, academicOfferId, item);
      });
      const newTableQuota: string[] = duplicatedOffers.map((item) => {
        if (item?.AcademicOfferStudyPlanVersionOffers_quota !== null) {
          return `${item?.AcademicOfferStudyPlanVersionOffers_quota}`;
        }
        return '';
      });
      setTableQuota(newTableQuota);
    }

    const merged = [...versions, ...duplicated];
    if (onlyView) {
      setTableData(merged);
    } else {
      setTableData(merged.length ? addEmptyRow(merged) : merged);
    }
  }, [versions, academicOfferId, duplicatedOffers, onlyView, studyPlanItem]);

  // Fila vacia para mostrar el boton de confirmar cupos
  const addEmptyRow = (data: VersionOffer[]) => {
    const emptyObject = buildEmptyRow();

    if (!data.find((item) => item.StudyPlanVersionOffer_id === -1)) {
      data.push(emptyObject);
    }

    return data;
  };

  // Grabar ofertas nuevas y editadas
  const saveQuota = useCallback(
    async (options: CellFormatOptions<VersionOffer>) => {
      const { data } = options;
      if (data && data.length) {
        const requestBody = buildRequestBody(
          data,
          studyPlanId,
          studyPlanItem,
          tableQuota,
        );
        const response = await confirmQuota(requestBody, academicOfferId);
        if (!response.error) {
          setPristine(true);
          reloadViewData();
        }
        getStudyPlansBySchool();
        addToast(
          response.error
            ? {
                icon: 'error',
                color: 'danger',
                text: t('common.errors.save'),
              }
            : {
                icon: 'success',
                color: 'success',
                text: t('common.messages.editSuccess'),
              },
        );
      }
    },
    [
      academicOfferId,
      getStudyPlansBySchool,
      reloadViewData,
      studyPlanId,
      studyPlanItem,
      t,
      tableQuota,
    ],
  );

  // Revisar cambios del input quota
  const onChangeInput = (
    e: ChangeEvent<HTMLInputElement> | { target: { value: string } },
    index: number,
  ) => {
    const { value } = e.target;
    let newTableQuota = JSON.parse(JSON.stringify(tableQuota));
    let newValue = value.replace(/\D/g, '');
    if (Number(newValue) < 0) {
      newValue = '';
    }

    if (newTableQuota[index] !== newValue) {
      setPristine(false);
      newTableQuota[index] = newValue;
      setTableQuota(newTableQuota);
    }
  };

  const columnWidth = `${100 / 6}%`;
  const columns = [
    {
      columnName: 'campus',
      headerText: t('academicOffers.form.studyPlansTableCampus'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<VersionOffer>) => {
        return (
          options.row.Campus_name && (
            <TextEllipsis
              key={options.index}
              textWidth={80}
              style={{ margin: 'auto' }}
            >
              {options.row.Campus_name}
            </TextEllipsis>
          )
        );
      },
    },
    {
      columnName: 'schedule',
      headerText: t('common.forms.schedule'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<VersionOffer>) => {
        return isMobile
          ? options.row.Schedule_name.toUpperCase()
          : options.row.Schedule_name;
      },
    },
    {
      columnName: 'academicOfferStudyPlanVersionOffers',
      headerText: t('academicOffers.form.lastPlaces'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<VersionOffer>) => {
        if (options.row.StudyPlanVersionOffer_id === -1) {
          return '';
        }

        return options.row.duplicatedQuota || 0;
      },
    },
    {
      columnName: 'academicOfferStudyPlanVersionOffers',
      headerText: t('academicOffers.list.enrolled'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<VersionOffer>) => {
        if (options.row.StudyPlanVersionOffer_id === -1) {
          return;
        }

        return options.row.enrolled;
      },
    },
    {
      columnName: 'academicOfferStudyPlanVersionOffers',
      headerText: t('academicOffers.form.newPlaces'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<VersionOffer>) => {
        const { index = 0 } = options;

        if (options.row.StudyPlanVersionOffer_id === -1 && !onlyView) {
          return (
            <Button
              type="button"
              text={t('academicOffers.form.studyPlansTableConfirmPlaces')}
              className={styles.confirmButton}
              onClick={() => saveQuota(options)}
              disabled={pristine}
            />
          );
        }

        if (!onlyView) {
          return (
            <input
              disabled={onlyView}
              value={`${tableQuota[index]}`}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeInput(e, index)
              }
            />
          );
        } else {
          return tableQuota[index] || '0';
        }
      },
    },
  ];

  const state = {
    columnName: 'status',
    headerText: t('maintainers.periodsList.status'),
    width: columnWidth,
    cellFormat: (options: CellFormatOptions<VersionOffer>) => {
      const { index = 0 } = options;
      const color = tableQuota[index] ? 'primary' : 'danger';
      return (
        options.row.StudyPlanVersionOffer_id !== -1 && (
          <Badge
            color={color}
            className={`${!tableQuota[index] ? 'Borrador' : ''} ${
              styles.badge
            }`}
          >
            {tableQuota[index] ? 'Actualizado' : 'Pendiente'}
          </Badge>
        )
      );
    },
  };

  const columnsTableInfo = onlyView ? columns : [...columns, state];

  // Boton Agregar Nueva Oferta
  const addOfferButton = (fullWidth: boolean = false) => (
    <>
      <AcademicOffersAddModal
        show={!!modalToShow}
        campus={campusModal}
        schedules={schedulesModal}
        studyPlanName={name}
        onCloseModal={() => setModalToShow(null)}
        onConfirm={(values: ConfirmModalData) => addData(values)}
        title={t('academicOffers.form.dataOffers')}
        tableData={tableData}
      />
      {!onlyView && (
        <Button
          type="button"
          text={t('academicOffers.form.studyPlansTableAddOffer')}
          outlined
          className={styles.newOfferButton}
          onClick={onclick}
          fullwidth={fullWidth}
        />
      )}
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column ${styles.expandableCell}`}>
        <div className="d-flex align-items-center justify-content-between">
          <span className={styles.name}>{name}</span>
          <div className="d-flex align-items-center">
            {expanded && !isMobile && addOfferButton(true)}
            <button
              type="button"
              onClick={() => setExpanded(!expanded)}
              className={`${styles.plusButton} bg-secondary  d-flex align-items-center justify-content-center`}
            >
              {expanded ? <>&#x2212;</> : <>&#x2b;</>}
            </button>
          </div>
        </div>
        {expanded && isMobile && addOfferButton()}
        {expanded && (
          <div className="expandable-cell-table mt-4">
            {tableData.length ? (
              <Table striped columns={columnsTableInfo} data={tableData} />
            ) : (
              <TableEmptyContent
                title={t(
                  'academicOffers.form.StudyPlanAcademicOffersTableEmptyTitle',
                )}
                subtitle={empatySubtitle}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ExpandableCell;
