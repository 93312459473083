import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../components/info/Loading';
import DisplayError from '../components/info/DisplayError';
import { PeriodType, PERIOD_STATES } from '../types/sectionsAndPackageTypes';
import { getPeriodById } from '../api/requests/sectionsAndPackages';
import PeriodsTable from './parts/PeriodsTable';
import DisplayInfo from '../components/info/DisplayInfo';
import DownloadBaseInformationDocument from './parts/DownloadBaseInformationDocument';
import { Col, Row } from 'reactstrap';
import DownloadLoadedInformationDocument from './parts/DownloadLoadedInformationDocument';
import UploadSections from './parts/UploadSections';
import LoadingModal from '../components/modals/LoadingModal';

export interface PeriodViewProps {
  periodId: number;
}
const PeriodsList = ({ periodId }: PeriodViewProps) => {
  const { t } = useTranslation();
  const prefix = 'packagesMaintainer';

  const [periods, setPeriods] = useState<PeriodType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loadingTitle, setLoadingTitle] = useState<string>('');
  const [loadingDesc, setLoadingDesc] = useState<string>('');
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data } = await getPeriodById(periodId);
    if (data && data.data) {
      setError(false);
      setPeriods([data.data]);
      setIsDisabled(data.data.state === PERIOD_STATES.EMPTY);
      setIsClosed(isCompletedTime(data.data.loadingDeadline));
    } else {
      setError(true);
    }
    setLoading(false);
  }, [periodId, setLoading, setError, setPeriods, setIsDisabled]);

  const isCompletedTime = (limitDate: string | null): boolean => {
    let timePassed = false;
    if (limitDate) {
      const date = new Date(limitDate);
      const now = new Date();
      if (now.getTime() > date.getTime()) {
        timePassed = true;
      }
    }
    return timePassed;
  };

  const beforeDownload = () => {
    setLoadingTitle(t(`${prefix}.downloads.loadingModal.title`));
    setLoadingDesc(t(`${prefix}.downloads.loadingModal.desc`));
    setOpenModal(true);
  };

  const beforeUpload = () => {
    setLoadingTitle(t(`${prefix}.upload.loadingModal.title`));
    setLoadingDesc(t(`${prefix}.upload.loadingModal.desc`));
    setOpenModal(true);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <DisplayError
        title={t(`${prefix}.loadErrorTitle`)}
        textBody={t(`${prefix}.loadErrorBody`)}
        insideCard
      />
    );
  }

  if (!periods.length) {
    return (
      <DisplayInfo
        title={t(`${prefix}.noPeriodsTitle`)}
        textBody={t(`${prefix}.noPeriodFound`)}
        insideCard
      />
    );
  }

  return (
    <React.Fragment>
      <LoadingModal
        isOpen={openModal}
        title={loadingTitle}
        description={loadingDesc}
      />
      <PeriodsTable periods={periods} />
      <Row>
        <Col>
          <DownloadBaseInformationDocument
            beforeDownload={beforeDownload}
            afterDownload={() => setOpenModal(false)}
            disabled={isClosed}
            periodId={periodId}
          />
        </Col>
        <Col>
          <DownloadLoadedInformationDocument
            beforeDownload={beforeDownload}
            afterDownload={() => setOpenModal(false)}
            disabled={isDisabled}
            periodId={periodId}
          />
        </Col>
        <Col>
          <UploadSections
            beforeUpload={beforeUpload}
            afterUpload={() => fetchData()}
            setOpenModal={(bol: boolean) => setOpenModal(bol)}
            beforeDownload={beforeDownload}
            afterDownload={() => setOpenModal(false)}
            disabled={isClosed}
            loadAgain={!isDisabled}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PeriodsList;
