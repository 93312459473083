import { ColumnTable, Table } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { StudentPostCloseDetail } from '../../../types/studentPostClose';
import { EditableGrade } from './EditableGrade';
import clsx from 'clsx';
import { useMemo } from 'react';

export interface SubevaluationsTableProps {
  partialEvaluation: StudentPostCloseDetail.PartialEvaluationSubevaluations;
  handleGradeEdit: (
    grade: StudentPostCloseDetail.PartialEvaluationSubevaluationsGrade,
    newValue: string,
  ) => void;
  formatGrade: (value: string) => string;
  isRequestingConfig: boolean;
  errorGettingConfig: boolean;
}

const prefix = 'studentGradesClosure.detail';

export default function SubevaluationsTable(props: SubevaluationsTableProps) {
  const {
    handleGradeEdit,
    partialEvaluation,
    formatGrade,
    isRequestingConfig,
    errorGettingConfig,
  } = props;
  const { t } = useTranslation();

  const COLUMNS: ColumnTable<StudentPostCloseDetail.PartialEvaluationSubevaluationsGrade>[] =
    useMemo(
      () => [
        {
          headerText: t(`${prefix}.test`),
          columnName: 'name',
          thClassName: 'text-center',
          tdClassName: 'text-center',
        },
        {
          headerText: t(`${prefix}.grade`),
          columnName: 'grade',
          thClassName: 'text-center',
          tdClassName: clsx('d-flex justify-content-center'),
          cellFormat: (options) => {
            return (
              <EditableGrade
                initialGrade={options.value}
                onEdit={(newValue) => handleGradeEdit(options.row, newValue)}
                editable={!isRequestingConfig && !errorGettingConfig}
                inputFormatter={formatGrade}
              />
            );
          },
        },
        {
          headerText: t(`${prefix}.percentage`),
          columnName: 'percentage',
          thClassName: 'text-center',
          tdClassName: 'text-center',
        },
        {
          headerText: t(`${prefix}.date`),
          columnName: 'date',
          thClassName: 'text-center',
          tdClassName: 'text-center',
          cellFormat: ({ value }) =>
            value ? dayjs(value).format('DD/MM/YYYY') : '-',
        },
      ],
      [errorGettingConfig, formatGrade, handleGradeEdit, isRequestingConfig, t],
    );

  return (
    <Table
      borderless={false}
      color="transparent"
      columns={COLUMNS}
      data={partialEvaluation.grades ?? []}
      notResponsive
    />
  );
}
