import { DetailBox, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import {
  requestCourseTypes,
  requestGetCourse,
} from '../../api/requests/coursesMaintainer';
import { getActivitiesRequest } from '../../api/requests/parameters';
import { getSchoolListRequest } from '../../api/requests/sectionsMaintainer';
import GoBackButton from '../../components/buttons/GoBackButton';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { useLoadingState } from '../../hooks/useLoadingState';
import { formatDate } from '../../utils/dates';
import { getSelectedOption } from '../../utils/selectFormat';
import CourseForm, {
  CourseFormFields,
  CourseFormMode,
} from './parts/CourseForm';

const CourseUpdate = () => {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const history = useHistory();
  const [schools, setSchools] = useState<SelectOptionType[]>([]);
  const [courseTypes, setCourseTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [activityTypes, setActivityTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [courseDetail, setCourseDetail] =
    useState<{ createdAt: string; updatedAt: string }>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<CourseFormFields>>();
  const { id: courseId } = useParams<{ id: string }>();
  const location = useLocation();

  const mode = useMemo(() => {
    if (courseId && location.pathname.includes('watch')) {
      return CourseFormMode.WATCH;
    } else if (courseId && location.pathname.includes('update')) {
      return CourseFormMode.UPDATE;
    } else {
      return CourseFormMode.CREATE;
    }
  }, [courseId, location]);

  const watchOrUpdateRequest = useCallback(async () => {
    setLoading(true);
    setErrorLoading(undefined);
    const [
      { data: schoolsResponse },
      { data: courseTypesResponse },
      { data: getActivitiesResponse },
      { data: courseResponse },
    ] = await Promise.all([
      getSchoolListRequest(),
      requestCourseTypes(),
      getActivitiesRequest(),
      requestGetCourse(+courseId),
    ]);

    if (
      schoolsResponse &&
      courseTypesResponse &&
      getActivitiesResponse &&
      courseResponse
    ) {
      const { data: course } = courseResponse;
      const arraySchools = schoolsResponse.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSchools(arraySchools);
      setCourseTypes(courseTypesResponse.data);
      setActivityTypes(getActivitiesResponse);
      setDefaultValues({
        shortening: course.shortening,
        code: course.shortening,
        name: course.name,
        credits: course.credits.toString(),
        school: getSelectedOption(course.schoolId, arraySchools),
        courseTypes: course.types.map((type) => {
          return type.id;
        }),
        attendance:
          course?.assistanceRequirements?.map((att) => {
            return {
              minPercentage: att.minPercentage,
              activityTypeId: att.activityTypeId,
            };
          }) ?? [],
        prerequisites:
          course?.dependsOn?.map((prerequisite) => {
            return {
              id: prerequisite.id,
              shortening: prerequisite.shortening,
              name: prerequisite.name,
              credits: prerequisite.credits,
            };
          }) ?? [],
      });
      setCourseDetail({
        createdAt: course.createdAt,
        updatedAt: course.updatedAt,
      });
    } else {
      setErrorLoading('ERROR');
    }
    setLoading(false);
  }, [courseId, setLoading, setErrorLoading]);

  const createRequest = useCallback(async () => {
    setLoading(true);
    setErrorLoading(undefined);
    const [
      { data: schoolsResponse },
      { data: courseTypesResponse },
      { data: getActivitiesResponse },
    ] = await Promise.all([
      getSchoolListRequest(),
      requestCourseTypes(),
      getActivitiesRequest(),
    ]);

    if (schoolsResponse && courseTypesResponse && getActivitiesResponse) {
      const arraySchools = schoolsResponse.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSchools(arraySchools);
      setCourseTypes(courseTypesResponse.data);
      setActivityTypes(getActivitiesResponse);
    } else {
      setErrorLoading('ERROR');
    }
    setLoading(false);
  }, [setErrorLoading, setLoading]);

  useEffect(() => {
    if (mode !== CourseFormMode.CREATE) {
      watchOrUpdateRequest();
    } else {
      createRequest();
    }
  }, [mode, createRequest, watchOrUpdateRequest]);

  if (loading && !errorLoading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12} lg={8}>
          <GoBackButton
            onClick={() => history.push('/courses-maintainer')}
            text={t(`common.actions.goBackStep`)}
          />
        </Col>
        {mode !== CourseFormMode.CREATE && (
          <Col xs={12} lg={4}>
            <DetailBox
              columns={[
                {
                  title: t('common.createdAt'),
                  body: courseDetail
                    ? formatDate(courseDetail.createdAt, 'DD-MM-YYYY')
                    : '',
                  size: { xs: 12, md: 6 },
                },
                {
                  title: t('common.updatedAt'),
                  body: courseDetail
                    ? formatDate(courseDetail.updatedAt, 'DD-MM-YYYY')
                    : '',
                  size: { xs: 12, md: 6 },
                },
              ]}
            />
          </Col>
        )}
      </Row>
      <CourseForm
        mode={mode}
        defaultValues={defaultValues ?? {}}
        schools={schools}
        courseTypes={courseTypes}
        activityTypes={activityTypes}
      />
    </Card>
  );
};

export default CourseUpdate;
