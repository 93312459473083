import {
  Button,
  CheckInput,
  OutlinedSelect,
  SearchBox,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { useParameters } from '../../../hooks/useParameters';
import useQuery from '../../../hooks/useQuery';
import { useValidations } from '../../../hooks/useValidations';

export interface SearchPostulantProccessByStatusForm {
  country: { value: string; label: string };
  passport: string;
  rut: string;
  isActiveSearchByPassport: boolean;
}

export function SearchFormPostulantProccessByStatus(props: {
  onSearch: (params: SearchPostulantProccessByStatusForm) => void;
  onIsSubmit: (state: boolean) => void;
  onClear: () => void;
}) {
  const { onSearch, onIsSubmit, onClear } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { validateRut, validateTextNotEmpty, msgValidations } =
    useValidations();
  const { countryOptions } = useParameters();

  const isSearchByPassport = useMemo<boolean>(() => {
    return query.get('search') === 'passport' || false;
  }, [query]);

  const defaultValues = useMemo(() => {
    return {
      country: undefined,
      passport: undefined,
      rut: undefined,
      isActiveSearchByPassport: isSearchByPassport,
    };
  }, [isSearchByPassport]);

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue: setFormValues,
  } = useForm<SearchPostulantProccessByStatusForm>({
    mode: 'all',
    defaultValues,
  });

  const { isActiveSearchByPassport, passport, rut, country } = watch();

  const { isValid, isDirty, isSubmitSuccessful } = formState;

  const onSubmit = useCallback(
    async (params) => {
      setLoading(true);
      onSearch(params);
      setIsSubmit(true);
      setLoading(false);
    },
    [setLoading, onSearch],
  );

  useEffect(() => {
    const isClearPassport =
      (isSubmitSuccessful && isActiveSearchByPassport && passport === '') ||
      !country;

    const isClearRut =
      isSubmitSuccessful && !isActiveSearchByPassport && rut === '';

    if (isClearRut || isClearPassport) {
      onClear();
    }
  }, [
    isSubmitSuccessful,
    isActiveSearchByPassport,
    passport,
    country,
    rut,
    onClear,
  ]);

  useEffect(() => {
    let passportIsEmpaty = isActiveSearchByPassport && passport === '';
    let rutIsEmpaty = !isActiveSearchByPassport && rut === '';
    if (isSubmit && (passportIsEmpaty || rutIsEmpaty)) {
      setIsSubmit(false);
    }
  }, [isActiveSearchByPassport, passport, rut, isSubmit, setIsSubmit]);

  useEffect(() => {
    onIsSubmit(isSubmit);
  }, [isSubmit, onIsSubmit]);

  useEffect(() => {
    history.replace({
      search: `?search=${isActiveSearchByPassport ? 'passport' : 'rut'}`,
    });
  }, [isActiveSearchByPassport, history, isSearchByPassport]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <span>{t('documents.searching')}</span>
          <Row>
            {isSearchByPassport ? (
              <Col>
                <Row>
                  <Col
                    sm={'auto'}
                    lg={'auto'}
                    xs="auto"
                    className="pr-0"
                    style={{ width: '140px' }}
                  >
                    <OutlinedSelect
                      name="country"
                      isSearchable={true}
                      isClearable={true}
                      placeholder={t('documents.searchStudent.inputCountry')}
                      options={countryOptions}
                      control={control}
                      rules={{ required: true }}
                    />
                  </Col>
                  <Col className="pr-0">
                    <SearchBox
                      name="passport"
                      clear={() => setFormValues('passport', '')}
                      control={control}
                      placeholder={t('documents.searchStudent.inputPassport')}
                      rules={{ required: msgValidations.required }}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col className="pr-0">
                <SearchBox
                  name="rut"
                  clear={() => setFormValues('rut', '')}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                  control={control}
                  placeholder={t('documents.searchStudent.inputRut')}
                />
              </Col>
            )}
            <Col sm={'auto'} lg={'auto'} xs="auto">
              <Button
                text={t('common.actions.search')}
                color="primary"
                type="submit"
                size="md"
                disabled={!isValid || !isDirty}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <CheckInput
            name="isActiveSearchByPassport"
            label={t('documents.searchStudent.addPassport')}
            control={control}
          />
        </Col>
      </Row>
    </Form>
  );
}
