import { useMemo } from 'react';
import { SectionScheduleGetResponse } from '../types';
import SectionScheduleForm from './SectionScheduleForm';
import SectionShowSchedule from './SectionShowSchedule';

const SectionSchedules = ({
  data,
  onRefresh,
}: {
  data: SectionScheduleGetResponse;
  onRefresh: () => void;
}) => {
  /**
   * Se obtienen las opciones de los selects y
   * la información de periodo para realizar las validaciones de fechas
   */
  const options = useMemo(() => {
    const scheduleModules = data.timeModules;
    const scheduleLegends = data.activities;

    return {
      scheduleModules,
      scheduleLegends,
    };
  }, [data]);

  return (
    <div className="mt-2 mx-3">
      <SectionScheduleForm data={data} onSave={onRefresh} />
      <SectionShowSchedule
        section={data.section}
        sectionSchedules={data.section.sectionSchedules.filter(
          (schedule) => !schedule.deletedAt,
        )}
        modules={options.scheduleModules}
        legends={options.scheduleLegends}
      />
    </div>
  );
};

export default SectionSchedules;
