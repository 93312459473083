import { Modal, Button, Icon } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import styles from './../AcademicOffers.module.scss';

interface AccademicOffersModalProps {
  show: boolean;
  onCloseModal: () => void;
  title: string;
  subtitle: string;
  onConfirm: () => void;
}

const AcademicOffersModal = ({
  show,
  onCloseModal,
  title,
  subtitle,
  onConfirm,
}: AccademicOffersModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseModal();
      }}
      size="lg"
      unmountOnClose
    >
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size="52px" color="secondary" />
        <div className="mt-4 fw-600 text-black fs-22 text-center">{title}</div>
        <div
          className={`${styles.modalSubtitle} mx-auto mt-4 mb-4 fs-18 text-center`}
        >
          {subtitle}
        </div>
      </div>

      <Row className="pt-5">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <div>
            <Button
              type="button"
              text={t(`common.actions.cancel`)}
              outlined
              onClick={() => onCloseModal()}
              fullwidth
            />
          </div>
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="submit"
              text={t('common.actions.confirm')}
              fullwidth
              onClick={onConfirm}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
export default AcademicOffersModal;
