import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentStatus,
  sideType,
  statusDocument,
} from '../../../api/requests/postulants';
import {
  changeDocumentStateOfPostulantProcess,
  changeIdentityCardStateOfPostulantProcess,
  getLinkDownloadDocumentOfPostulantProcess,
  getLinkDownloadIdentityCardOfPostulantProcess,
  showOtherDocumentOfPostulantProcess,
  showIdentityCardDocumentOfPostulantProcess,
} from '../../../api/requests/tuitionProcesses';
import { showToast } from '../../../utils/toast';

export interface DocumentByStatusOfPostulantProccess {
  id: number;
  type: string;
  name: string;
  status: statusDocument;
  side?: sideType;
  inCloud: boolean;
  indexes: {
    i: number;
    j: number;
  };
  isIdentity: boolean;
}

const IDETITY_CARD_TYPE_DOCUMENTS = [
  'postulant-passport',
  'sustainer-passport',
  'cosigner-passport',
];

export function useColumnsListDocumentByStatus(props: {
  ChangeStateRow: (
    row: DocumentByStatusOfPostulantProccess,
    status: statusDocument,
  ) => void;
  documentDelete: (row: DocumentByStatusOfPostulantProccess) => void;
  uploadDocument: (row: DocumentByStatusOfPostulantProccess) => void;
}) {
  const { ChangeStateRow, documentDelete, uploadDocument } = props;
  const { t } = useTranslation();

  const changeDocumentState = useCallback(
    async (
      row: DocumentByStatusOfPostulantProccess,
      e: { value: statusDocument; label: string } | null,
    ) => {
      if (e) {
        let params: [number, { status: string }] = [
          row.id,
          {
            status: e.value,
          },
        ];
        let { error } =
          row.isIdentity && !IDETITY_CARD_TYPE_DOCUMENTS.includes(row.type)
            ? await changeIdentityCardStateOfPostulantProcess(...params)
            : await changeDocumentStateOfPostulantProcess(...params);
        if (error) {
          showToast(true, t);
        } else {
          showToast(false, t);
          ChangeStateRow(row, e.value);
        }
      }
    },
    [ChangeStateRow, t],
  );

  const fetchLinkDownloadDocument = useCallback(
    async (
      documentID: number,
      isIdentity: boolean,
      name: string,
      type: string,
    ) => {
      let { data, error } =
        isIdentity && !IDETITY_CARD_TYPE_DOCUMENTS.includes(type)
          ? await getLinkDownloadIdentityCardOfPostulantProcess(documentID)
          : await getLinkDownloadDocumentOfPostulantProcess(documentID);
      if (error) {
        showToast(true, t);
      } else if (data) {
        let link = document.createElement('a');
        link.download = `${name}`;
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [t],
  );
  const fetchViewLinkDocument = useCallback(
    async (documentId: number, isIdentity: boolean, type: string) => {
      let { data, error } =
        isIdentity && !IDETITY_CARD_TYPE_DOCUMENTS.includes(type)
          ? await showIdentityCardDocumentOfPostulantProcess(documentId)
          : await showOtherDocumentOfPostulantProcess(documentId);
      if (error) {
        showToast(true, t);
      } else if (data) {
        window.open(data.file);
      }
    },
    [t],
  );
  return useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'type',
        headerText: t('documents.studyDocuments.type'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<DocumentByStatusOfPostulantProccess>,
        ) => {
          return (
            <span className="text-lowercase">
              {t(`postulationDocs.${options.row.type}.name`)}
              &nbsp;
              {options.row.side &&
                t(`postulationDocs.attachment.${options.row.side}`)}
            </span>
          );
        },
      },
      {
        columnName: 'name',
        headerText: t('documents.studyDocuments.name'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<DocumentByStatusOfPostulantProccess>,
        ) => {
          return options.row.inCloud && options.row.name;
        },
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<DocumentByStatusOfPostulantProccess>,
        ) => {
          return (
            <div className="d-flex" style={{ gap: '24px' }}>
              <div
                className={!options.row.inCloud ? 'icon-disabled' : ''}
                onClick={() =>
                  fetchViewLinkDocument(
                    options.row.id,
                    options.row.isIdentity,
                    options.row.type,
                  )
                }
              >
                <Icon
                  name="eye"
                  color={options.row.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div
                className={!options.row.inCloud ? 'icon-disabled' : ''}
                onClick={() =>
                  fetchLinkDownloadDocument(
                    options.row.id,
                    options.row.isIdentity,
                    options.row.name,
                    options.row.type,
                  )
                }
              >
                <Icon
                  name="download"
                  color={options.row.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div
                className={!options.row.inCloud ? 'icon-disabled' : ''}
                onClick={() => documentDelete(options.row)}
              >
                <Icon
                  name="trash"
                  color={options.row.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
            </div>
          );
        },
      },
      {
        columnName: 'upload',
        headerText: t('documents.studyDocuments.newUpload'),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<DocumentByStatusOfPostulantProccess>,
        ) => {
          return (
            <div style={{ marginBottom: '.8rem' }}>
              <Button
                type="button"
                text={t('documents.studyDocuments.upload')}
                icon="export"
                disabled={options.row.inCloud}
                size="md"
                outlined
                onClick={() => uploadDocument(options.row)}
              />
            </div>
          );
        },
      },
      {
        columnName: 'state',
        headerText: t('documents.studyDocuments.state.headerText'),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({
          row,
        }: CellFormatOptions<DocumentByStatusOfPostulantProccess>) => {
          let options: OutlinedSelectOptionType[] = [
            {
              value: DocumentStatus.NOT_REVIEW,
              label: t('documents.studyDocuments.state.notReview'),
              icon: {
                name: 'error',
                color: 'danger',
              },
            },
            {
              value: DocumentStatus.WAITING,
              label: t('documents.studyDocuments.state.waiting'),
              icon: {
                name: 'warning',
                color: 'warning',
              },
            },
            {
              value: DocumentStatus.APPROVED,
              label: t('documents.studyDocuments.state.Aproved'),
              icon: {
                name: 'success',
                color: 'success',
              },
            },
          ];
          let selectOptions = options.find(({ value }) => value === row.status);
          return (
            <div className="d-flex justify-content-center">
              <div
                style={{
                  width: '180px',
                }}
              >
                <OutlinedSelect
                  name="status"
                  isSearchable={false}
                  disabled={!row.inCloud}
                  value={selectOptions}
                  isClearable={false}
                  options={options}
                  menuPosition="fixed"
                  onChange={(e) => changeDocumentState(row, e)}
                  menuShouldBlockScroll={true}
                />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    fetchLinkDownloadDocument,
    changeDocumentState,
    documentDelete,
    uploadDocument,
    fetchViewLinkDocument,
  ]);
}
