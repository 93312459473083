import { CellFormatOptions, ColumnTable, Icon, Table } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteOtherDocumentOfPostulantProcess,
  downloadOtherDocumentOfPostulantProcess,
  OtherDocumentOfPostulantProcess,
} from '../../../api/requests/tuitionProcesses';
import { ModalConfirmation } from '../../../components/modals/Confirmation';
import { showToast } from '../../../utils/toast';

export interface TableOtherDocumentProps {
  otherDocuments: OtherDocumentOfPostulantProcess[];
  loading: boolean;
  reloadData: () => void;
}

export function TableOtherDocument(props: TableOtherDocumentProps) {
  const { t } = useTranslation();

  const { otherDocuments, loading, reloadData } = props;
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [documentID, setDocumentID] = useState<number | null>();

  const openModalDeleteByID = useCallback((id: number) => {
    setDocumentID(id);
    setModalConfirm(true);
  }, []);

  const fetchDownloadOtherDocument = useCallback(
    async (otherDocument: OtherDocumentOfPostulantProcess) => {
      let { data, error } = await downloadOtherDocumentOfPostulantProcess(
        otherDocument.id,
      );
      if (error) {
        showToast(true, t);
      } else if (data) {
        let link = document.createElement('a');
        link.download = `${otherDocument.name}`;
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [t],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: t('documents.studyDocuments.name'),
        width: '20%',
        tdClassName: 'fw-400',
        cellFormat: (
          options: CellFormatOptions<OtherDocumentOfPostulantProcess>,
        ) => {
          return `${options.row.name}.${options.row.extension}`;
        },
      },
      {
        columnName: 'description',
        headerText: t('documents.studyDocuments.description'),
        width: '70%',
        tdClassName: 'text-left',
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<OtherDocumentOfPostulantProcess>,
        ) => {
          return (
            <div
              className="d-flex justify-content-center"
              style={{
                gap: '24px',
              }}
            >
              <div onClick={() => fetchDownloadOtherDocument(options.row)}>
                <Icon
                  name="download"
                  color="primary"
                  className="icon-selector"
                />
              </div>
              <div onClick={() => openModalDeleteByID(options.row.id)}>
                <Icon name="trash" color="primary" className="icon-selector" />
              </div>
            </div>
          );
        },
      },
    ];
  }, [t, fetchDownloadOtherDocument, openModalDeleteByID]);

  const fetchDeleteOtherDocumentByDetailID = useCallback(async () => {
    if (documentID) {
      let { error } = await deleteOtherDocumentOfPostulantProcess(documentID);
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        reloadData();
      }
    }
  }, [documentID, reloadData, t]);

  return (
    <>
      {otherDocuments.length !== 0 && (
        <div className="mt-4">
          <Table
            columns={columns}
            data={otherDocuments}
            isLoadingResults={loading}
          />
        </div>
      )}
      <ModalConfirmation
        title={t('documents.studyDocuments.modalDeleteOtherDocuments.title')}
        subtitle={t(
          'documents.studyDocuments.modalDeleteOtherDocuments.subtitle',
        )}
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
        onConfirm={fetchDeleteOtherDocumentByDetailID}
      />
    </>
  );
}
