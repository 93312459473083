import { ChangeEvent, useReducer, useState } from 'react';
import { TextOutlinedInput, Icon } from '@octano/global-ui';

const INPUT_WIDTH = 51;

interface Props {
  initialGrade: string;
  editable?: boolean;
  inputFormatter?: (value: string) => string;
  onEdit?: (newValue: string) => void;
}

export function EditableGrade({
  initialGrade,
  onEdit = () => null,
  editable = true,
  inputFormatter = (value: string) => value,
}: Props) {
  const [editing, toggleEditing] = useReducer((prev) => !prev, false);
  const [grade, setGrade] = useState(() => initialGrade);

  const handleEdit = (e: ChangeEvent<HTMLInputElement>) => {
    const grade = inputFormatter(e.target.value);
    setGrade(grade);
    onEdit(grade);
  };

  return (
    <div
      className="d-flex align-center-cente justify-content-between"
      style={{ minWidth: 96 }}
    >
      {/* Push elements to the right */}
      <div style={{ marginLeft: 'auto' }}></div>

      {editable && editing ? (
        <div style={{ width: INPUT_WIDTH }}>
          <TextOutlinedInput
            name=""
            value={grade}
            onChange={handleEdit}
            textAlign="center"
          />
        </div>
      ) : (
        <span
          className="d-flex align-items-center justify-content-center fs-16 d-block py-2"
          style={{ width: INPUT_WIDTH, height: 42 }}
        >
          {grade}
        </span>
      )}

      {editable ? (
        <button className="border-0 bg-transparent" onClick={toggleEditing}>
          <Icon name="edit" size="12px" />
        </button>
      ) : (
        <div style={{ marginRight: 'auto' }}></div>
      )}
    </div>
  );
}
