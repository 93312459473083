import { Button, PaginationType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { SectionMaintainerListType } from '../../types/sectionMaintainerTypes';
import { useColumnsSectionsList } from './parts/useColumnsSectionsList';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLoadingState } from '../../hooks/useLoadingState';
import DisplayError from '../../components/info/DisplayError';
import SearchFilters, { Filters } from './parts/SearchFilters';
import { requestSectionsMaintainerList } from '../../api/requests/sectionsMaintainer';
import { useHistory } from 'react-router-dom';

const ITEMS_PER_PAGE = 10;

const SectionsMaintainer = () => {
  const prefix = 'sectionsMaintainer.list';
  const { t } = useTranslation();
  const history = useHistory();
  const [dataList, setDataList] = useState<SectionMaintainerListType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastFilters, setLastFilters] = useState<Filters>();

  const columns = useColumnsSectionsList();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [loadingFilters, setLoadingFilters] = useState(true);

  const getData = useCallback(
    async (page: number) => {
      setLoading(true);

      const requestFilters = {
        periodId: lastFilters?.period?.value,
        schoolId: lastFilters?.faculty?.value,
        studyPlanId: lastFilters?.studyPlan?.value,
        campusId: lastFilters?.campus?.value,
        courseNameCode: lastFilters?.search_text,
        profesorId: lastFilters?.teacher?.value,
      };

      const { data, error } = await requestSectionsMaintainerList({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        ...requestFilters,
      });

      if (data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [setLoading, setErrorLoading, lastFilters],
  );

  const goToCreate = () => {
    history.push(`/sections-maintainer/create`);
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, getData]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => {
            getData(currentPage);
            setErrorLoading(undefined);
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12}>
          <SearchFilters
            onFiltersChange={(filters) => {
              setCurrentPage(1);
              setLastFilters(filters);
            }}
            onFiltersReady={() => setLoadingFilters(false)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-4 text-right">
          <Button
            icon="plus"
            onClick={goToCreate}
            outlined
            size="md"
            text={t(`${prefix}.createSection`)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table<SectionMaintainerListType>
            columns={columns}
            data={dataList}
            pagination={pagination}
            isLoadingResults={loading || loadingFilters}
            noResultsText={
              <TableEmptyContent
                title={t(`${prefix}.noResultsTitle`)}
                subtitle={t(`${prefix}.noResultsBody`)}
              />
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SectionsMaintainer;
