import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  OutlinedSelect,
  TextOutlinedInput,
  useMobile,
} from '@octano/global-ui';
import { useValidations } from '../../../hooks/useValidations';

type SearchType = 'identificator' | 'name';
type SearchTypeOption = { label: string; value: SearchType };

const SEARCH_TYPE_OPTIONS: SearchTypeOption[] = [
  { label: 'ID', value: 'identificator' },
  { label: 'Nombre', value: 'name' },
];

type FormValues = {
  search: string;
  searchBy: SearchTypeOption | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  search: '',
  searchBy: null,
};

const dictPrefix = 'graduationProcessSingle.searchControls';

export type SearchParams = { search: string; searchBy: SearchType };

interface Props {
  forcedSearchParams?: SearchParams;
  onSearch?: (sp: SearchParams) => void;
  onClear?: () => void;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  forcedSearchParams,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const { validateTextNotEmpty, msgValidations } = useValidations();

  const handleSearch = (values: FormValues) => {
    const { search, searchBy } = values;
    onSearch({
      search,
      searchBy: searchBy?.value!,
    });
  };

  const handleClear = () => {
    reset(DEFAULT_FORM_VALUES);
    onClear();
  };

  useEffect(() => {
    if (forcedSearchParams) {
      reset({
        search: forcedSearchParams.search,
        searchBy: SEARCH_TYPE_OPTIONS.find(
          (opt) => opt.value === forcedSearchParams.searchBy,
        ),
      });
    }
  }, [forcedSearchParams, reset]);

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '0.6fr 1fr auto',
        gridGap: isMobile ? 8 : 14,
      }}
    >
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <OutlinedSelect
          name="searchBy"
          control={control}
          rules={{ required: msgValidations.required }}
          options={SEARCH_TYPE_OPTIONS}
        />
      </div>
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <TextOutlinedInput
          name="search"
          control={control}
          placeholder={t(`${dictPrefix}.nameOrId`)}
          rules={{
            validate: {
              validateTextNotEmpty,
            },
          }}
        />
      </div>

      <div className="d-flex flex-wrap" style={{ gap: 12 }}>
        <Button
          type="submit"
          text={t(`common.actions.search`)}
          size="md"
          style={{ width: isMobile ? '100%' : 125, maxHeight: 20 }}
        />
        <Button
          text={t(`common.actions.clean`)}
          size="md"
          outlined
          style={{ width: isMobile ? '100%' : 125, maxHeight: 20 }}
          onClick={handleClear}
        />
      </div>
    </form>
  );
}
