import { TextAreaInput } from '@octano/global-ui';
import dayjs from 'dayjs';
import { Card, Col } from 'reactstrap';

import { SectionTitle } from '../../../../components/text';

import FileDownload from '../../parts/FileDownload';
import InputToolTip from '../../parts/InputToolTip';
import { ViewStudentsPetition } from '../types/petitionDetail';

interface StudentPetitionInfoProps {
  downloadFile?: ViewStudentsPetition['doc'];
  downloadFileResponse?: ViewStudentsPetition['docResponse'];
  onlyView?: boolean;
  texts: any;
  lastUpdate: any;
  lastUpdatedAt: any;
  currentStatus: any;
}

export default function StudentPetitionInfo({
  downloadFile,
  downloadFileResponse,
  onlyView = true,
  texts,
  lastUpdate,
  lastUpdatedAt,
  currentStatus,
}: StudentPetitionInfoProps) {
  return (
    <>
      <Card className="petition-info-resolution mb-4">
        <Col xs={12} sm={12} md={8} className="px-0">
          <Card className="d-flex flex-column justify-content-start ">
            <Col xs={12} md={8} lg={6} className="px-0">
              <FileDownload
                text={texts.download.button}
                label={texts.download.label}
                file={downloadFile}
              />
            </Col>
          </Card>
        </Col>
      </Card>

      <Card className="petition-comment-resolution mb-4">
        <Col xs={12} className="px-0 mb-4">
          <SectionTitle text={texts.body.petitionCommentLabel} />
          <TextAreaInput
            label=""
            placeholder={texts.body.petitionCommentPlaceHolder}
            name="description"
            onBlur={function noRefCheck() {}}
            onChange={function noRefCheck() {}}
            value={lastUpdate}
            height={95}
            disabled={onlyView}
          />
        </Col>

        <Col xs={12} className="px-0">
          <InputToolTip
            prefix={`studentPetitionDetail.resolved.body.inputTooltips`}
            name={texts.body.resolvedDateLabel}
            text={dayjs(lastUpdatedAt).format('DD/MM/YYYY')}
            status={currentStatus}
          />
        </Col>
      </Card>

      {downloadFileResponse && (
        <Card className="petition-attach-resolution mb-4">
          <Col xs={12} sm={12} md={8} className="px-0">
            <Card className="d-flex flex-column justify-content-start ">
              <Col xs={12} md={8} lg={6} className="px-0">
                <FileDownload
                  text={texts.download.button}
                  label={texts.download.filesLabel}
                  file={downloadFileResponse}
                />
              </Col>
            </Card>
          </Col>
        </Card>
      )}
    </>
  );
}
