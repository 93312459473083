import { Card } from 'reactstrap';
import DocumentsGridCardsContent from './parts/DocumentsGridCardsContent';
import { PeriodsLoader } from './parts/PeriodsLoader';
import List from './parts/FilterList';

export function DocumentsGridCards() {
  return (
    <PeriodsLoader
      renderPeriods={({ ...data }) => {
        return (
          <div className="mx-3">
            <List {...data}>
              {(periodId: number, campusId?: number, schoolId?: number) => (
                <Card>
                  <DocumentsGridCardsContent
                    periodId={periodId}
                    campusId={campusId}
                    schoolId={schoolId}
                  />
                </Card>
              )}
            </List>
          </div>
        );
      }}
    />
  );
}
