import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';
import { getAssistantsRequest } from '../../api/requests/parameters';

export const useSearchAssistant = () => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await getAssistantsRequest(searchText);
      if (data) {
        return data.data.map((assist) => ({
          value: assist.id,
          label: assist.account?.fullName ?? '',
        }));
      }
      return [];
    },
    [],
  );
};
