import {
  Button,
  CellFormatOptions,
  ColumnTable,
  PaginationType,
  Table,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  DocumentationReviewStatus,
  getDocumentationByStatus as getDocumentationByStatusRequest,
  searchPostulantByPassport,
  searchPostulantByRut,
  SearchStudyPlansOfferDocumentationByStatus,
  StudyPlansOfferDocumentationByStatus,
} from '../../../api/requests/studyPlansOffer';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { format as formatRut } from 'rut.js';
import { NavTabsStatusDocumentOfPostulantProccess } from './NavTabsStatusDocumentOfPostulantProccess';
import {
  SearchFormPostulantProccessByStatus,
  SearchPostulantProccessByStatusForm,
} from './SearchFormPostulantProccessByStatus';
import { DocumentStatus } from '../../../api/requests/postulants';

const ITEMS_PER_PAGE_DEFAULT = 10;

const statusDocumentOfPostulantProccess = {
  sin_revision: 'documentation-not-reviewed',
  en_espera: 'documentation-waiting',
  aprobado: 'documentation-approved',
};

export function TablePostulantsProcessByStatus() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, status } =
    useParams<{ id: string; status: DocumentationReviewStatus }>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [
    listPostulantProcessByDocumentStatus,
    setListPostulantProccessByDocumentStatus,
  ] = useState<StudyPlansOfferDocumentationByStatus[]>([]);

  const [listPostulantProcessByTab, setListPostulantProcessByTab] = useState<
    SearchStudyPlansOfferDocumentationByStatus[]
  >([]);

  const listPostulantProcessByTabFilter = useMemo(() => {
    return listPostulantProcessByTab.filter(({ tab }) => {
      return (
        statusDocumentOfPostulantProccess[tab as DocumentStatus] === status
      );
    });
  }, [listPostulantProcessByTab, status]);

  const dataTable = useMemo(() => {
    return isSubmit
      ? listPostulantProcessByTabFilter
      : listPostulantProcessByDocumentStatus;
  }, [
    isSubmit,
    listPostulantProcessByTabFilter,
    listPostulantProcessByDocumentStatus,
  ]);

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (!isSubmit) {
      return {
        totalItems,
        onChangePage: setCurrentPage,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages,
        currentPage,
      };
    } else return undefined;
  }, [isSubmit, totalItems, setCurrentPage, totalPages, currentPage]);

  const goDocumentOfPostulantProccess = useCallback(
    (detailId: number) => {
      history.push({
        search: `?id=${detailId}`,
      });
    },
    [history],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'rut',
        headerText: t(`common.forms.rut`),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<StudyPlansOfferDocumentationByStatus>,
        ) => {
          return (
            (options.row.Postulant_rut &&
              formatRut(options.row.Postulant_rut)) ||
            options.row.PostulantPassport_number
          );
        },
      },
      {
        columnName: 'Postulant_names',
        headerText: t(`common.forms.names`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
      },
      {
        columnName: 'lastnames',
        headerText: t(`common.forms.lastName`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
        cellFormat: (
          options: CellFormatOptions<StudyPlansOfferDocumentationByStatus>,
        ) => {
          return `${options.row.Postulant_paternalLastName} ${options.row.Postulant_maternalLastName}`;
        },
      },
      {
        columnName: 'Contact_cellPhone',
        headerText: t(`common.forms.phone`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
      },
      {
        columnName: 'Contact_email',
        headerText: t(`common.forms.email`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
      },
      {
        columnName: 'action',
        headerText: t('documents.action'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<StudyPlansOfferDocumentationByStatus>,
        ) => {
          return (
            <Button
              text={t('documents.viewDocuments')}
              size="sm"
              fullwidth
              color="primary"
              onClick={() =>
                goDocumentOfPostulantProccess(options.row.Details_id)
              }
            />
          );
        },
      },
    ];
  }, [t, goDocumentOfPostulantProccess]);

  const noResultsText = useMemo(() => {
    let title, subtitle;
    if (errorLoading) {
      title = t('documents.tableEmptyContent.connection.title');
      subtitle = t('documents.tableEmptyContent.connection.subtitle');
    } else {
      title = t('documents.tableEmptyContent.notResult.title');
      subtitle = t('documents.tableEmptyContent.notResult.subtitle');
    }
    return <TableEmptyContent title={title} subtitle={subtitle} />;
  }, [t, errorLoading]);

  const clearListPostulantProccessByDocumentStatus = useCallback(() => {
    setListPostulantProccessByDocumentStatus([]);
    setCurrentPage(1);
    setTotalItems(1);
    setTotalPages(1);
  }, [
    setListPostulantProccessByDocumentStatus,
    setCurrentPage,
    setTotalItems,
    setTotalPages,
  ]);

  const getDocumentationByStatus = useCallback(async () => {
    setLoading(true);
    let { data, error } = await getDocumentationByStatusRequest(
      Number(id),
      status,
      ITEMS_PER_PAGE_DEFAULT,
      currentPage - 1,
    );
    if (error) {
      setErrorLoading(error.code);
      clearListPostulantProccessByDocumentStatus();
    } else if (data) {
      setErrorLoading('');
      setListPostulantProccessByDocumentStatus([...data.data]);
      setTotalItems(data.total);
      setTotalPages(data.total_pages);
    }
    setLoading(false);
  }, [
    currentPage,
    id,
    status,
    setLoading,
    clearListPostulantProccessByDocumentStatus,
    setErrorLoading,
  ]);

  const onSerchOfPostulantProccess = useCallback(
    async ({
      rut,
      passport,
      country,
      isActiveSearchByPassport,
    }: SearchPostulantProccessByStatusForm) => {
      setLoading(true);
      let { data, error } = isActiveSearchByPassport
        ? await searchPostulantByPassport(Number(id), country.value, passport)
        : await searchPostulantByRut(Number(id), rut);
      if (error) {
        setErrorLoading(error.code);
        clearListPostulantProccessByDocumentStatus();
      } else if (data) {
        setErrorLoading(undefined);
        setListPostulantProcessByTab([data]);
        history.replace({
          pathname: `/documents/${id}/${
            statusDocumentOfPostulantProccess[data.tab as DocumentStatus]
          }`,
        });
      }
      setLoading(false);
    },
    [
      id,
      setLoading,
      clearListPostulantProccessByDocumentStatus,
      setErrorLoading,
      history,
      setListPostulantProcessByTab,
    ],
  );

  const onClearResult = useCallback(() => {
    if (isSubmit) {
      history.replace({
        pathname: `/documents/${id}/${statusDocumentOfPostulantProccess.sin_revision}`,
      });
    }
  }, [history, isSubmit, id]);

  useEffect(() => {
    if (!isSubmit) {
      getDocumentationByStatus();
    }
  }, [getDocumentationByStatus, isSubmit]);

  return (
    <>
      <SearchFormPostulantProccessByStatus
        onSearch={onSerchOfPostulantProccess}
        onIsSubmit={setIsSubmit}
        onClear={onClearResult}
      />
      <NavTabsStatusDocumentOfPostulantProccess />
      <Table
        pagination={paginationTable}
        columns={columns}
        data={dataTable}
        isLoadingResults={loading}
        noResultsText={noResultsText}
      />
    </>
  );
}
