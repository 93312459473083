import List from '../../admission/AdmissionsPlanesStudent/AdmissionsList';
import { AdmissionsPlanesStudentCards } from '../../admission/AdmissionsPlanesStudent/AdmissionsPlanesStudentCards';
import BaseLoader from './parts/BaseLoader';

export default function AdmissionsList() {
  return (
    <div className="mx-3">
      <BaseLoader>
        {({ data }) => (
          <List {...data}>
            {(periodId: number, campusId?: number, schoolId?: number) => (
              <>
                <AdmissionsPlanesStudentCards
                  periodId={periodId}
                  campusId={campusId}
                  schoolId={schoolId}
                />
              </>
            )}
          </List>
        )}
      </BaseLoader>
    </div>
  );
}
