import { useTranslation } from 'react-i18next';
import { Button } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { PathsLayouts } from '../../config/routes';
import { enrollStudentsRequest } from '../../api/requests/packages';

const MESSAGE_TYPE = {
  NONE: '',
  SUCCESS: 'registration completed',
  PARTIAL_SUCCESS: 'partial enrollment',
  UNEXPECTED_ERROR: 'inconsistent mesh assignment',
  NO_STUDENTS_ERROR: 'no students to enroll',
  NO_SPACE_ERROR: 'no space in packages ',
  DB_INCONSISTENCY_ERROR: 'inconsistent mesh assignment',
};

interface PackagesEnrollStudentsProps {
  beforeEnroll?: () => void;
  afterEnroll?: () => void;
}

const PackagesEnrollStudents = ({
  beforeEnroll,
  afterEnroll,
}: PackagesEnrollStudentsProps): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'packages.pending-students';
  const [openedModal, setOpenedModal] = useState<string>();
  const history = useHistory();
  const { id: academicOfferStudyPlanVersionOfferId } =
    useParams<{ id?: string }>();

  const goToAcademicOfferList = useCallback(
    () => history.replace(PathsLayouts.packages),
    [history],
  );

  const goToAcademicOfferDetail = useCallback(() => {
    history.go(0);
  }, [history]);

  const handleEnrollStudents = useCallback(async () => {
    if (beforeEnroll) beforeEnroll();
    const { data, error } = await enrollStudentsRequest(
      parseInt(academicOfferStudyPlanVersionOfferId || ''),
    );

    if (data && data.data && data.data.message) {
      setOpenedModal(data.data.message);
    } else if (error && error.data && error.data.statusCode === 400) {
      if (error.data.message !== MESSAGE_TYPE.DB_INCONSISTENCY_ERROR) {
        setOpenedModal(error.data.message);
      } else {
        setOpenedModal(MESSAGE_TYPE.UNEXPECTED_ERROR);
      }
    } else {
      setOpenedModal(MESSAGE_TYPE.UNEXPECTED_ERROR);
    }

    if (afterEnroll) afterEnroll();
  }, [beforeEnroll, afterEnroll, academicOfferStudyPlanVersionOfferId]);

  if (
    !academicOfferStudyPlanVersionOfferId ||
    isNaN(parseInt(academicOfferStudyPlanVersionOfferId))
  ) {
    return <Redirect to={PathsLayouts.packages} />;
  }

  return (
    <>
      {/* MODAL DE SUCCESS */}
      <ConfirmationModal
        iconName="success"
        isOpen={openedModal === MESSAGE_TYPE.SUCCESS}
        toggle={() => setOpenedModal(MESSAGE_TYPE.NONE)}
        title={t(`${prefix}.successModal.title`)}
        body={t(`${prefix}.successModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => {
            setOpenedModal(MESSAGE_TYPE.NONE);
            goToAcademicOfferList();
          },
        }}
      />

      {/* MODAL DE SUCCESS PARCIAL */}
      <ConfirmationModal
        iconName="warning"
        isOpen={openedModal === MESSAGE_TYPE.PARTIAL_SUCCESS}
        toggle={() => setOpenedModal(MESSAGE_TYPE.NONE)}
        title={t(`${prefix}.partialSuccessModal.title`)}
        body={t(`${prefix}.partialSuccessModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => {
            setOpenedModal(MESSAGE_TYPE.NONE);
            goToAcademicOfferDetail();
          },
        }}
      />

      {/* MODAL DE ERROR INESPERADO */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === MESSAGE_TYPE.UNEXPECTED_ERROR}
        toggle={() => setOpenedModal(MESSAGE_TYPE.NONE)}
        title={t(`${prefix}.unexpectedErrorModal.title`)}
        body={t(`${prefix}.unexpectedErrorModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setOpenedModal(MESSAGE_TYPE.NONE),
        }}
      />

      {/* MODAL DE ERROR OFERTA SIN ESTUDIANTES / STATUS CODE 400 */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === MESSAGE_TYPE.NO_STUDENTS_ERROR}
        toggle={() => setOpenedModal(MESSAGE_TYPE.NONE)}
        title={t(`${prefix}.noStudentsErrorModal.title`)}
        body={t(`${prefix}.noStudentsErrorModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setOpenedModal(MESSAGE_TYPE.NONE),
        }}
      />

      {/* MODAL DE ERROR OFERTA SIN ESPACIO / STATUS CODE 400 */}
      <ConfirmationModal
        iconName="error"
        iconColor="danger"
        isOpen={openedModal === MESSAGE_TYPE.NO_SPACE_ERROR}
        toggle={() => setOpenedModal(MESSAGE_TYPE.NONE)}
        title={t(`${prefix}.noSpaceErrorModal.title`)}
        body={t(`${prefix}.noSpaceErrorModal.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setOpenedModal(MESSAGE_TYPE.NONE),
        }}
      />

      <Button
        text={t(`${prefix}.enrollStudents`)}
        color="primary"
        size="md"
        className="fw-700"
        onClick={handleEnrollStudents}
      />
    </>
  );
};

export default PackagesEnrollStudents;
