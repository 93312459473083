import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationType } from '@octano/global-ui';
import Loading from '../components/info/Loading';
import DisplayError from '../components/info/DisplayError';
import { PeriodType } from '../types/sectionAttributesTypes';
import { getPeriods } from '../api/requests/sectionAttributes';
import PeriodsTable from './parts/PeriodsTable';
import DisplayInfo from '../components/info/DisplayInfo';

const INITIAL_PAGE = 1;
const ITEMS_PER_PAGE = 10;

const PeriodsList = () => {
  const { t } = useTranslation();
  const prefix = 'sectionAttributes';

  const [periods, setPeriods] = useState<PeriodType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalPeriods, setTotalPeriods] = useState<number>(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data } = await getPeriods(ITEMS_PER_PAGE, currentPage - 1);
    if (data && data.data) {
      setError(false);
      setPeriods(data.data);
      setTotalPages(data.total_pages);
      setTotalPeriods(data.total);
    } else {
      setError(true);
    }
    setLoading(false);
  }, [currentPage, setLoading, setError, setPeriods]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changePageHandler = useCallback(
    (page: number) => setCurrentPage(page),
    [],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (totalPeriods > ITEMS_PER_PAGE) {
      return {
        totalItems: totalPeriods,
        itemsPerPage: ITEMS_PER_PAGE,
        totalPages: totalPages,
        currentPage: currentPage,
        onChangePage: changePageHandler,
      };
    }
  }, [totalPeriods, totalPages, currentPage, changePageHandler]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <DisplayError
        title={t(`${prefix}.loadErrorTitle`)}
        textBody={t(`${prefix}.loadErrorBody`)}
        insideCard
      />
    );
  }

  if (!periods.length) {
    return (
      <DisplayInfo
        title={t(`${prefix}.noPeriodsTitle`)}
        textBody={t(`${prefix}.noPeriodsBody`)}
        insideCard
      />
    );
  }

  return <PeriodsTable periods={periods} pagination={pagination} />;
};

export default PeriodsList;
