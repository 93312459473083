import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import GoBackButton from '../components/buttons/GoBackButton';
import { Tabs } from '../components/tabs/Tabs';
import { PathsLayouts } from '../config/routes';
import { TimeModuleType } from '../types/timeModuleTypes';
import PackagesViewBox from './parts/PackagesViewBox';
import PackagesViewSchedule from './parts/PackagesViewSchedule';
import PackagesViewSections from './parts/PackagesViewSections';
import { PackagesViewSectionsLoader } from './parts/PackagesViewSectionsLoader';
import PackagesViewStudents from './parts/PackagesViewStudents';
import { PackagesViewStudentsLoader } from './parts/PackagesViewStudentsLoader';

export interface PackagesViewContentProps {
  packageId: string | number;
  packageName: string;
  studyPlanName: string;
  campusName: string;
  scheduleName: string;
  timeModules: TimeModuleType[];
}

export default function PackagesViewContent({
  packageId,
  packageName,
  studyPlanName,
  campusName,
  scheduleName,
  timeModules,
}: PackagesViewContentProps) {
  const { t } = useTranslation();

  let history = useHistory();
  function back() {
    history.push(PathsLayouts.packages);
  }

  const hint = t('packages.view.hint');
  const tabsTitles = [
    t('packages.view.tabs.sectionsAndSchedules'),
    t('packages.view.tabs.students'),
  ];
  const courseTitle = t('packages.view.sections.title');
  const scheduleTitle = t('packages.view.schedule.title');

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <Row className="mb-5">
        <Col lg={6}>
          <div className="mb-4">
            <GoBackButton onClick={back} />
          </div>
          <div className="fs-16 text-medium">{hint}</div>
        </Col>
        <Col lg={6}>
          <div className="fs-14 text-primary fw-600 text-right mb-2 text-uppercase">
            {packageName}
          </div>
          <div>
            <PackagesViewBox
              studyPlanName={studyPlanName}
              campusName={campusName}
              scheduleName={scheduleName}
            />
          </div>
        </Col>
      </Row>
      <div>
        <div style={{ margin: '0 -1.5rem' }}>
          <Tabs
            value={tabIndex}
            onChange={setTabIndex}
            tabs={tabsTitles}
            fullwidth
          />
        </div>
        {tabIndex === 0 && (
          <div className="p-4">
            <PackagesViewSectionsLoader packageId={packageId}>
              {(sections, loading) => {
                return (
                  <>
                    <div className="mb-4">
                      <div className="fs-20 fw-700 text-primary mb-4">
                        {courseTitle}
                      </div>
                      <PackagesViewSections loading={loading} data={sections} />
                    </div>
                    <div>
                      <div className="fs-20 fw-700 text-primary mb-4">
                        {scheduleTitle}
                      </div>
                      <div className="w-100 m-auto" style={{ maxWidth: 868 }}>
                        <PackagesViewSchedule
                          timeModules={timeModules}
                          sections={sections}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </PackagesViewSectionsLoader>
          </div>
        )}
        {tabIndex === 1 && (
          <div className="mt-4">
            <PackagesViewStudentsLoader packageId={packageId}>
              {(data, loading) => (
                <PackagesViewStudents data={data ?? []} loading={loading} />
              )}
            </PackagesViewStudentsLoader>
          </div>
        )}
      </div>
    </>
  );
}
