import { AcademicOffersTypeDuplicate as AcademicOffers } from '../../types/AcademicOffersType';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { TuitionListResponse } from '../../types/periodTuition';
import { StudyPlanVersion } from '../../types/studyPlan';
import { ACADEMIC_OFFERS_MAINTAINER, TARIFF_TUITION } from '../endpoints';
import request from '../request';
import { Period } from './MantainerPortal';

/**
 * Obtiene el listado de periodos sin valores de matriculas
 */
export function getPeriod() {
  const url = TARIFF_TUITION.PERIODS;
  return request<PaginationRequestType<Period>>(url, {
    method: 'get',
  });
}

/**
 * Obtiene el listado de matrículas con paginación
 */
export const getTuitionRequest = (items: number = 10, page: number = 0) => {
  const url = `${TARIFF_TUITION.TUITIONS}`;
  return request<TuitionListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

/**
 * Crear/editar una matrícula
 */
export const saveTuitionRequest = (data: any) => {
  const editing = !!data.id;
  const url = `${TARIFF_TUITION.TUITIONS}/${editing ? data.id : ''}`;
  return request(url, {
    method: editing ? 'put' : 'post',
    data,
  });
};

/**
 * Obtiene el listado de todas las ofertas academicas
 */
export const getAllAcademicOffersRequest = () => {
  const url = `${ACADEMIC_OFFERS_MAINTAINER.GET_ALL}`;
  return request<PaginationRequestType<AcademicOffers>>(url);
};

/**
 * Obtiene el listado de todas las ofertas academicas
 */
export const getAcademicOffersTariffRequest = (
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${TARIFF_TUITION.ACADEMIC_OFFER_TARIFF}`;
  return request<PaginationRequestType<StudyPlanVersion>>(url, {
    params: { items_per_page: items, page, academicOfferId },
  });
};

/**
 * Crear/editar una matrícula
 */
export const saveTariffRequest = (data: any) => {
  const url = `${TARIFF_TUITION.ACADEMIC_OFFER_TARIFF}`;
  return request(url, {
    method: 'post',
    data,
  });
};
