import { addToast, Button, Modal, TextInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { CardTitle } from '../../../components/text';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { saveTuitionContinuityProcessManagementDetailDiscount } from '../../../api/requests/tuitionContinuityProcessManagement';

interface DiscountItem {
  id: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

interface Discount {
  id: number;
  code: string;
  name: string;
  percentage: number;
}

interface TuitionContinuityProcessManagementDiscountModalProps {
  studyPlanEnrollmentId: number;
  show: boolean;
  discounts: DiscountItem[];
  discount?: Discount;
  onComplete: (completed: boolean) => void;
}

export default function TuitionContinuityProcessManagementDiscountModal({
  studyPlanEnrollmentId,
  show,
  discount: discountProp,
  discounts,
  onComplete,
}: TuitionContinuityProcessManagementDiscountModalProps) {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      title: t('tuitionContinuityProcessManagementDetail.discount.title'),
      label: {
        name: t('common.forms.name'),
        code: t('common.forms.code'),
        percentage: t('common.forms.percentage'),
      },
      notFound: t('tuitionContinuityProcessManagementDetail.discount.notFound'),
      success: t(`tuitionContinuityProcessManagementDetail.discount.success`),
      error: t('common.errors.save'),
      actions: {
        cancel: t('common.actions.cancel'),
        confirm: t('common.actions.confirm'),
      },
    }),
    [t],
  );

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState<string>(discountProp?.code ?? '');
  const [discount, setDiscount] = useState(discountProp);

  const [showNotDiscountFound, setShowNotDiscountFound] =
    useState<boolean>(false);

  const { showErrorModal } = useLayoutState();
  const saveDiscount = async () => {
    if (!discount) {
      return;
    }
    setLoading(true);
    const { error } =
      await saveTuitionContinuityProcessManagementDetailDiscount({
        studyPlanEnrollmentId,
        discountId: discount.id,
      });
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.error,
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: texts.success,
      });
      onComplete(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const found = discounts.find((element) => element.code === code);
    if (found) {
      setShowNotDiscountFound(false);
      setDiscount(found);
    } else {
      if (code) {
        setShowNotDiscountFound(true);
      } else {
        setShowNotDiscountFound(false);
      }
      setDiscount(undefined);
    }
  }, [code, discounts]);

  return (
    <Modal
      isOpen={show}
      toggle={() => onComplete(false)}
      size="md"
      unmountOnClose
    >
      <div className="text-center">
        <CardTitle children={texts.title} />
      </div>
      <Row className="mt-3">
        <Col md={6} xs={12}>
          <TextInput
            name="code"
            value={code}
            label={texts.label.code}
            onChange={(e) => setCode(e.target.value)}
          />
          <span style={{ color: 'red' }}>
            {showNotDiscountFound ? texts.notFound : ''}
          </span>
        </Col>
        <Col md={6} xs={12}>
          <TextInput
            name="percentage"
            value={discount?.percentage}
            disabled
            label={texts.label.percentage}
          />
        </Col>
      </Row>
      <Row className="mt-0 mt-md-4">
        <Col xs="12" md="12">
          <TextInput
            name="name"
            disabled
            value={discount?.name}
            label={texts.label.name}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
          <Button
            type="button"
            text={texts.actions.cancel}
            outlined
            onClick={() => onComplete(false)}
            fullwidth
          />
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
          <Button
            type="button"
            onClick={saveDiscount}
            text={texts.actions.confirm}
            fullwidth
            className="mb-3 mb-md-0"
            disabled={!discount}
            loading={loading}
          />
        </Col>
      </Row>
    </Modal>
  );
}
