import { addToast, Button, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Col, Form, Row } from 'reactstrap';
import DisplayError from '../../../components/info/DisplayError';
import CreateSectionFields from './CreateSectionFields';
import { PathsLayouts } from '../../../config/routes';
import { createSectionRequest } from '../../../api/requests/sectionsMaintainer';

export type CreateSectionFormType = {
  period: SelectOptionType;
  faculty: SelectOptionType;
  quota: string;
  campus: SelectOptionType;
  courses: SelectOptionType | null;
  courseName: string;
  section: string;
  teacher: SelectOptionType;
  teachers: SelectOptionType[];
  assistant: SelectOptionType;
  assistants: SelectOptionType[];
};

const CreateSectionForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const prefix = 'sectionsMaintainer.create';
  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);
  const location = useLocation<{ sectionId?: string | number }>();

  const methods = useForm<CreateSectionFormType>();

  const onSubmit = useCallback(
    async (values: CreateSectionFormType) => {
      const {
        period,
        courses,
        section,
        campus,
        teacher,
        teachers,
        quota,
        assistant,
        assistants,
      } = values;

      const auxTeachers: number[] =
        teachers && teachers.length > 0
          ? teachers.filter((item) => !!item).map((item) => +item.value)
          : [];
      const auxAssistants: number[] =
        assistants && assistants.length > 0
          ? assistants.filter((item) => !!item).map((item) => +item.value)
          : [];
      if (teacher?.value) auxTeachers.push(+teacher.value);
      if (assistant?.value) auxAssistants.push(+assistant.value);

      const { data, error } = await createSectionRequest({
        maximumStudent: +quota,
        campusId: +campus.value,
        professorIds: auxTeachers,
        assistantIds: auxAssistants,
        courseCode: courses?.value.toString() || '',
        periodId: +period.value,
        name: section,
      });
      if (data && !error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.createSuccess`),
        });
        history.push(`${PathsLayouts.sectionsMaintainer}/${data.id}`);
      } else if (error && error.status && error.status === 400) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.duplicatedSectionError`),
        });
      } else {
        setIsUnexpectedError(true);
      }
    },
    [t, setIsUnexpectedError, history],
  );

  useEffect(() => {
    if (location && location.state) console.log(location.state.sectionId);
  }, [location]);

  if (isUnexpectedError) {
    return (
      <div className="mx-3">
        <DisplayError
          title={t('packages.loadErrorTitle')}
          textBody={t('packages.loadErrorBody')}
          retryAction={methods.handleSubmit(onSubmit)}
          loadingAction={methods.formState.isSubmitting}
          insideCard
        />
      </div>
    );
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <CreateSectionFields />

          <Row className="py-5 justify-content-end pt-5">
            <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1">
              <Button
                type="button"
                outlined
                onClick={() => history.push(PathsLayouts.sectionsMaintainer)}
                text={t(`common.actions.cancel`)}
                disabled={methods.formState.isSubmitting}
                fullwidth
              />
            </Col>
            <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
              <Button
                type="submit"
                text={t(`common.actions.saveAndContinue`)}
                loading={methods.formState.isSubmitting}
                fullwidth
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default CreateSectionForm;
