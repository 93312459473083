import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { NavTabs } from '../../../components/headers/NavTabs';
import { PathsLayouts } from '../../../config/routes';

export function NavTabsStatusDocumentOfPostulantProccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string; status: string }>();
  const tabs = [
    {
      pathname: `${PathsLayouts.documents}/${id}/documentation-not-reviewed`,
      title: t('documents.notReview'),
    },
    {
      pathname: `${PathsLayouts.documents}/${id}/documentation-waiting`,
      title: t('documents.inWaiting'),
    },
    {
      pathname: `${PathsLayouts.documents}/${id}/documentation-approved`,
      title: t('documents.approved'),
    },
  ];
  return (
    <NavTabs
      className="mb-5 mt-4"
      tabs={tabs}
      pathname={history.location.pathname}
    />
  );
}
