import { Icon, Table, Button } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPostulantDocuments,
  PostulantDocumentation,
  sideType,
  statusDocument,
} from '../../../api/requests/postulants';
import {
  deleteDocumentOfPostulantProcess,
  deleteIdentityCardOfPostulantProcess,
  getOtherDocumentOfPostulantProcess,
  OtherDocumentOfPostulantProcess,
} from '../../../api/requests/tuitionProcesses';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { ModalConfirmation } from '../../../components/modals/Confirmation';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { IdentityTypesEnum } from '../../../types/signDocumentationTypes';
import { showToast } from '../../../utils/toast';
import { FormDataPostulantProccess } from './FormDataPostulantProccess';

import { ModalAddDocument } from './ModalAddDocument';
import { TableOtherDocument } from './TableOtherDocument';
import {
  DocumentByStatusOfPostulantProccess,
  useColumnsListDocumentByStatus,
} from './useColumnsListDocumentByStatus';

export interface ModalAddDocumentProps {
  open: boolean;
  isNewDocument: boolean;
  title?: string;
  document?: {
    id: number;
    type: string;
    side?: sideType;
  };
  onConfirm?: () => void;
}

export function StudyDocuments(props: { detailId: number }): JSX.Element {
  const { detailId } = props;
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [postulantDocuments, setPostulantDocuments] =
    useState<PostulantDocumentation | null>();

  const [otherDocuments, setOtherDocuments] = useState<
    OtherDocumentOfPostulantProcess[]
  >([]);

  const [loadingOtherDocumentList, setLoadingOtherDocumentList] =
    useState<boolean>(false);

  const documents = useMemo<DocumentByStatusOfPostulantProccess[]>(() => {
    if (!postulantDocuments) return [];
    let docs: DocumentByStatusOfPostulantProccess[] = [];
    let docIdentity: DocumentByStatusOfPostulantProccess[] = [];
    postulantDocuments.documentation.forEach((parent, i) => {
      parent.document.forEach(
        ({ type, status, id: docID, inCloud, filename }, j) => {
          docs.push({
            type,
            name: filename,
            status,
            id: docID,
            indexes: { i, j },
            isIdentity: false,
            inCloud,
          });
        },
      );
    });
    postulantDocuments.identityCards.forEach((parent, i) => {
      parent.identityCards.forEach((child, j) => {
        docIdentity.push({
          type: `${child.type}`,
          name: child.filename,
          status: child.status,
          id: child.id,
          side: child.side,
          indexes: { i, j },
          isIdentity: true,
          inCloud: child.inCloud,
        });
      });
    });
    return [...docs, ...docIdentity.reverse()];
  }, [postulantDocuments]);

  const [modalConfirm, setModalConfirm] = useState({
    title: '',
    subtitle: '',
    onConfirm: console.log,
    open: false,
  });

  const [modalAddDocument, setModalAddDocument] =
    useState<ModalAddDocumentProps>({
      open: false,
      isNewDocument: false,
    });

  const ChangeStateRow = useCallback(
    (row: DocumentByStatusOfPostulantProccess, status: statusDocument) => {
      if (postulantDocuments) {
        if (row.isIdentity) {
          postulantDocuments.identityCards[row.indexes.i].identityCards[
            row.indexes.j
          ].status = status;
        } else {
          postulantDocuments.documentation[row.indexes.i].document[
            row.indexes.j
          ].status = status;
        }
        setPostulantDocuments({ ...postulantDocuments });
      }
    },
    [setPostulantDocuments, postulantDocuments],
  );

  const fetchPostulantDocument = useCallback(async () => {
    setLoading(true);
    let { data, error } = await getPostulantDocuments(detailId);
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading('');
      setPostulantDocuments(data.data);
    }
    setLoading(false);
  }, [detailId, setErrorLoading, setLoading]);

  const fetchPostulantDocumentDelete = useCallback(
    async (row: DocumentByStatusOfPostulantProccess) => {
      setLoading(true);
      let resp;
      if (
        row.isIdentity &&
        row.type !== IdentityTypesEnum['postulant-passport'] &&
        row.type !== IdentityTypesEnum['sustainer-passport'] &&
        row.type !== IdentityTypesEnum['cosigner-passport']
      ) {
        resp = await deleteIdentityCardOfPostulantProcess(row.id);
      } else {
        resp = await deleteDocumentOfPostulantProcess(row.id);
      }
      let { error } = resp;
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        fetchPostulantDocument();
      }
      setLoading(false);
    },
    [fetchPostulantDocument, t, setLoading],
  );

  const documentDelete = useCallback(
    (row: DocumentByStatusOfPostulantProccess) => {
      modalConfirm.open = true;
      modalConfirm.title =
        row.status !== 'aprobado'
          ? t('documents.studyDocuments.modalConfirm.notAproved.title')
          : t('documents.studyDocuments.modalConfirm.aproved.title');
      modalConfirm.subtitle =
        row.status !== 'aprobado'
          ? t('documents.studyDocuments.modalConfirm.notAproved.subtitle')
          : t('documents.studyDocuments.modalConfirm.aproved.subtitle');
      modalConfirm.onConfirm = () => fetchPostulantDocumentDelete(row);
      setModalConfirm({ ...modalConfirm });
    },
    [fetchPostulantDocumentDelete, modalConfirm, setModalConfirm, t],
  );

  const uploadDocument = useCallback(
    (row: DocumentByStatusOfPostulantProccess) => {
      let title = t(`documents.studyDocuments.postulationDocs.${row.type}`);
      if (row.side) {
        title +=
          ' ' +
          t(`documents.studyDocuments.postulationDocs.attachment.${row.side}`);
      }
      setModalAddDocument({
        open: true,
        isNewDocument: false,
        title,
        document: {
          id: row.id,
          type: row.type,
          side: row.side,
        },
        onConfirm: () => fetchPostulantDocument(),
      });
    },
    [t, setModalAddDocument, fetchPostulantDocument],
  );

  const fetchOtherDocumentOfPostulantProcess = useCallback(async () => {
    setLoadingOtherDocumentList(true);
    if (postulantDocuments) {
      let { data, error } = await getOtherDocumentOfPostulantProcess(
        postulantDocuments.postulant.Details_id,
      );
      if (error) {
        setOtherDocuments([]);
      } else if (data) {
        setOtherDocuments([...data]);
      }
      setLoadingOtherDocumentList(false);
    }
  }, [setOtherDocuments, setLoadingOtherDocumentList, postulantDocuments]);

  const columns = useColumnsListDocumentByStatus({
    ChangeStateRow,
    documentDelete,
    uploadDocument,
  });

  useEffect(() => {
    fetchPostulantDocument();
  }, [fetchPostulantDocument]);

  useEffect(() => {
    fetchOtherDocumentOfPostulantProcess();
  }, [fetchOtherDocumentOfPostulantProcess]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => fetchPostulantDocument()}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  if (postulantDocuments) {
    return (
      <>
        <FormDataPostulantProccess postulantDocuments={postulantDocuments} />
        <Table
          columns={columns}
          data={documents}
          isLoadingResults={loading}
          noResultsText={
            <TableEmptyContent
              title={t('documents.tableEmptyContent.notResult.title')}
              subtitle={t('documents.tableEmptyContent.notResult.subtitle')}
            />
          }
        />
        <footer
          className="p-4 mt-4"
          style={{ border: '1px solid var(--primary)', borderRadius: '4px' }}
        >
          <div className="d-flex align-items-center" style={{ gap: '6px' }}>
            <h6 className="d-inline-block text-primary fs-20 fw-600 y mb-0">
              {t('documents.studyDocuments.otherDocuments')}
            </h6>
            <Icon name="information"></Icon>
          </div>
          <br />
          <Button
            type="button"
            text={t('documents.studyDocuments.addOtherDocuments')}
            icon="plus"
            size="md"
            onClick={() => {
              setModalAddDocument({
                open: true,
                isNewDocument: true,
                onConfirm: fetchOtherDocumentOfPostulantProcess,
              });
            }}
          />
          <TableOtherDocument
            otherDocuments={otherDocuments}
            loading={loadingOtherDocumentList}
            reloadData={fetchOtherDocumentOfPostulantProcess}
          />
        </footer>
        <ModalConfirmation
          open={modalConfirm.open}
          title={modalConfirm.title}
          subtitle={modalConfirm.subtitle}
          onClose={() => {
            modalConfirm.open = false;
            setModalConfirm({ ...modalConfirm });
          }}
          onConfirm={modalConfirm.onConfirm}
        />
        <ModalAddDocument
          details_id={postulantDocuments.postulant.Details_id}
          open={modalAddDocument.open}
          onClose={() =>
            setModalAddDocument({ open: false, isNewDocument: false })
          }
          isNewDocument={modalAddDocument.isNewDocument}
          title={modalAddDocument.title}
          document={modalAddDocument.document}
          onConfirm={modalAddDocument.onConfirm}
        />
      </>
    );
  } else {
    return <></>;
  }
}
