import { Table } from '@octano/global-ui';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';
import { useColumns, UseColumnsProps } from './useColumns';

export interface TuitionContinuityDocumentReviewDocumentsLoaderProps
  extends UseColumnsProps {
  data: StudentDocumentReviewInfDocumentOther[];
}

export function TuitionContinuityDocumentReviewDocumentsOtherTable({
  data,
  onDownload,
  onDelete,
}: TuitionContinuityDocumentReviewDocumentsLoaderProps) {
  const columns = useColumns({
    onDownload,
    onDelete,
  });

  return <Table columns={columns} data={data} />;
}
