import React, { ReactNode, useCallback, useState } from 'react';
import {
  TcDocumentReviewBoxDetailInf,
  TuitionContinuityDocumentStatusEnum,
} from '../../../types/tuitionContinuityDocumentReview';
import { getTcDocumentReviewStudentTabs } from '../../../api/requests/tuitionContinuityDocumentReview';
import FetchProvider from '../../../components/contexts/FetchContext';
import { PaginationQuery } from '../../../types/paginationRequestType';
import { CompareObjectValues } from '../../../utils/object-manipulation';

export interface TuitionContinuityDocumentReviewDetailLoaderProps {
  children: (props: {
    detailBox: TcDocumentReviewBoxDetailInf;
    isLoading: boolean;
  }) => ReactNode;
  periodId: number | string;
  studyPlanVersionOfferId: number | string;
  currentStatus: TuitionContinuityDocumentStatusEnum;
}

export interface StudentListParams extends PaginationQuery {
  periodId: number | string;
  studyPlanVersionOfferId: number | string;
  status: TuitionContinuityDocumentStatusEnum;
  rutOrPassport?: string;
}

export function TuitionContinuityDocumentReviewDetailLoader({
  studyPlanVersionOfferId,
  children,
  periodId,
  currentStatus,
}: TuitionContinuityDocumentReviewDetailLoaderProps) {
  const [detailBox, setDetailBox] = useState<
    TcDocumentReviewBoxDetailInf | undefined
  >();
  const getStudentList = useCallback(
    async ({
      status = TuitionContinuityDocumentStatusEnum.NoReview,
      ...query
    }: StudentListParams) => {
      const request = await getTcDocumentReviewStudentTabs({
        status,
        ...query,
      });
      if (request.data) {
        const { studyPlanVersionName, campusName, scheduleName } =
          request.data.data;
        setDetailBox((prevState) => {
          const obj = { studyPlanVersionName, campusName, scheduleName };
          if (prevState) {
            return !CompareObjectValues(obj, prevState) ? obj : prevState;
          }
          return obj;
        });
      }
      return request;
    },
    [],
  );
  return (
    <FetchProvider
      request={getStudentList}
      fetchImmediately
      defaultQuery={{
        studyPlanVersionOfferId,
        periodId,
        status: currentStatus,
        items_per_page: 10,
        page: 0,
      }}
    >
      {children({
        detailBox: detailBox ?? {
          campusName: '',
          scheduleName: '',
          studyPlanVersionName: '',
        },
        isLoading: !detailBox,
      })}
    </FetchProvider>
  );
}
