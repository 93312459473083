import { useCallback, useEffect, useState } from 'react';
import {
  getPackageDetail,
  getPackageTimeCalendar,
} from '../../api/requests/packages';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { TimeModuleType } from '../../types/timeModuleTypes';

export interface PackagesView {
  packageId: number;
  packageName: string;
  studyPlanName: string;
  campusName: string;
  scheduleName: string;
}

export interface PackagesViewLoaderProps {
  packageId: number | string;
  children: (data: {
    package: PackagesView;
    timeModules: TimeModuleType[];
  }) => JSX.Element;
}

export const PackagesViewLoader = (props: PackagesViewLoaderProps) => {
  const { packageId, children } = props;
  const [loading, setLoading] = useState(true);
  const [packageView, setPackage] = useState<PackagesView | null>(null);
  const [timeModules, setTimeModules] = useState<TimeModuleType[]>([]);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  const fetchData = useCallback(async (id: string | number) => {
    setLoading(true);
    const [response, timeCalendarResponse] = await Promise.all([
      getPackageDetail(id),
      getPackageTimeCalendar(),
    ]);
    if (response.error) {
      setError(response.error.code);
    } else if (timeCalendarResponse.error) {
      setError(timeCalendarResponse.error.code);
    } else if (response.data?.id) {
      /**
       * Si es que tiene ID es porque lo encontró
       */
      setPackage({
        packageId: response.data.id,
        packageName: response.data.name ?? '',
        studyPlanName: response.data.studyPlan ?? '',
        campusName: response.data.campus ?? '',
        scheduleName: response.data.schedule ?? '',
      });
      setTimeModules(timeCalendarResponse.data.data);
    } else if (response.data) {
      /**
       * Si es que hay data pero no ID entonces no lo encontró
       */
      setError('HTTP_ERROR');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(packageId);
  }, [fetchData, packageId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DisplayError textBody={error} />;
  }

  if (!packageView) {
    return <DisplayError textBody="HTTP_ERROR" />;
  }

  return children({ package: packageView, timeModules });
};
