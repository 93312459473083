import { useTranslation } from 'react-i18next';
import { addToast, Button } from '@octano/global-ui';
import { useCallback } from 'react';
import { downloadBaseInfoDocument } from '../../api/requests/sectionAttributes';
import { downloadFromBlob } from '../../utils/blob';

interface DownloadBaseInformationDocumentProps {
  beforeDownload?: () => void;
  afterDownload?: () => void;
  disabled?: boolean;
  periodId: number;
}

const DownloadBaseInformationDocument = ({
  beforeDownload,
  afterDownload,
  disabled,
  periodId,
}: DownloadBaseInformationDocumentProps): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'sectionAttributes.downloads';

  const handleDocumentDownload = useCallback(async () => {
    if (beforeDownload) beforeDownload();
    const res = await downloadBaseInfoDocument(periodId);

    if (res.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorDownloadingFile`),
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
      downloadFromBlob(blob, 'informacion-base.xlsx');
    }
    if (afterDownload) afterDownload();
  }, [beforeDownload, periodId, afterDownload, t]);

  return (
    <div
      className={`base-doc-container text-center ${disabled ? 'disabled' : ''}`}
    >
      <p className="fs-18 fw-700 text-uppercase title">
        {t(`${prefix}.baseDocTitle`)}
      </p>
      <p className="fs-14">{t(`${prefix}.baseDocDesc`)}</p>
      <Button
        outlined
        size="md"
        icon="download"
        color="primary"
        text={t(`${prefix}.baseDocButton`)}
        onClick={handleDocumentDownload}
        disabled={disabled}
      />
    </div>
  );
};

export default DownloadBaseInformationDocument;
