import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';
import { getTeachersRequest } from '../../api/requests/parameters';

export const useSearchTeachers = () => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await getTeachersRequest(searchText);
      if (data) {
        return data.data.map((teacher) => ({
          value: teacher.id,
          label: teacher.account?.fullName ?? '',
        }));
      }
      return [];
    },
    [],
  );
};
