import {
  Button,
  DateInput,
  OutlinedSelect,
  OutlinedSelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';
import { getDegreeActivity } from '../../requests';
import {
  CreateRenditionDefaultParams,
  CreateRenditionParams,
} from '../../types';

interface CreateRenditionFormProps {
  texts: any;
  onSave: (formParams: CreateRenditionParams) => void;
  onCancel: () => void;
  activityId: string | number;
}

const CreateRenditionForm = ({
  texts,
  onSave,
  onCancel,
  activityId,
}: CreateRenditionFormProps) => {
  const [activityAllowGrades, setActivityAllowGrades] = useState<Boolean>(true);
  const approvedStates: OutlinedSelectOptionType[] = useMemo(() => {
    return [
      {
        value: 0,
        label: texts.badgeState.failed,
      },
      {
        value: 1,
        label: texts.badgeState.approved,
      },
    ];
  }, [texts]);

  const getDefaultValues: CreateRenditionDefaultParams = useMemo(() => {
    return {
      activityTypeTitle: '',
      dateRendition: dayjs().format('YYYY-MM-DD'),
      approved: null,
      grade: null,
      gradeMax: null,
      comment: null,
    };
  }, []);

  const methods = useForm<CreateRenditionDefaultParams>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  const [gradeWatch] = watch(['grade']);

  const { msgValidations, rulesPatternDecimal, validateGreaterField } =
    useValidations();

  const onSubmit = useCallback(
    async (formValues: CreateRenditionDefaultParams) => {
      const { dateRendition, approved, grade, gradeMax, comment } = formValues;
      const formParams = {
        date: dateRendition as string,
        approved: Boolean(approved?.value),
        grade: grade ? Number(grade) : undefined,
        gradeMax: gradeMax ? Number(gradeMax) : undefined,
        comment: comment ? String(comment) : undefined,
      };
      await onSave(formParams);
    },
    [onSave],
  );

  const getFilters = useCallback(async () => {
    const { data } = await getDegreeActivity(activityId);
    if (data) {
      setValue('activityTypeTitle', data.type.name);
      setActivityAllowGrades(data.type.allowGrades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const handleClear = () => {
    reset(getDefaultValues);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <TextInput
                  name="activityTypeTitle"
                  disabled
                  control={control}
                  label={texts.modal.activityName}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="6">
                <DateInput
                  name="dateRendition"
                  label={texts.modal.renditionDate}
                  control={control}
                  maxDate={new Date()}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
              <Col xs="12" md="6">
                <OutlinedSelect
                  name="approved"
                  label={texts.modal.approved}
                  placeholder={texts.modal.approvedPlaceholder}
                  control={control}
                  options={approvedStates}
                  isClearable={false}
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </Col>
            </Row>

            {activityAllowGrades && (
              <Row className="mt-0 mt-md-3">
                <Col xs="12" md="6">
                  <TextInput
                    name="grade"
                    control={control}
                    label={texts.modal.grade}
                    rules={{
                      required: msgValidations.required,
                      pattern: rulesPatternDecimal,
                    }}
                  />
                </Col>
                <Col xs="12" md="6">
                  <TextInput
                    name="gradeMax"
                    control={control}
                    label={texts.modal.gradeMax}
                    rules={{
                      required: msgValidations.required,
                      pattern: rulesPatternDecimal,
                      validate: {
                        require: (value) =>
                          validateGreaterField(
                            String(gradeWatch),
                            String(value),
                          ),
                      },
                    }}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-0 mt-md-3">
              <Col xs="12" md="12">
                <TextAreaInput
                  name="comment"
                  control={control}
                  label={texts.modal.comment}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="mt-5">
            <Row>
              <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                <Button
                  type="button"
                  text={texts.action.cancel}
                  outlined
                  onClick={() => {
                    onCancel();
                    handleClear();
                  }}
                  fullwidth
                />
              </Col>
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                <Button
                  type="submit"
                  text={texts.action.send}
                  loading={isSubmitting}
                  fullwidth
                  className="mb-3 mb-md-0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
export default CreateRenditionForm;
