import {
  addToast,
  ColumnTable,
  Icon,
  OutlinedSelect,
  SelectOptionType,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';
import { requestStudentStatus } from '../../../api/requests/studentStatus';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import {
  STUDENT_STATUS,
  StudentStatusListType,
} from '../../../types/studentStatusTypes';
import { formatDate } from '../../../utils/dates';
import { ModalChangeStatusType } from './StudentStatus';

export const useColumnsStudentStatusList = (
  statusOptions: SelectOptionType[],
  onChangeStatus: (props: ModalChangeStatusType) => void,
) => {
  const prefix = 'studentStatus.columns';
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const getValuesStatus = useCallback(
    async (
      index: number,
      row: StudentStatusListType,
      action: 'update' | 'watch',
    ) => {
      const { data, error } = await requestStudentStatus({
        studyPlanEnrollmentId: row.studyPlanEnrollmentId,
      });
      if (data) {
        const { data: res, file } = data.data;
        onChangeStatus({
          index: index,
          studentId: row.studentId,
          studyPlanEnrollmentId: row.studyPlanEnrollmentId,
          actualStatus: row.status,
          newStatus: row.status,
          action: action,
          defaultValues: {
            file: {
              path: `${file.name}.${file.extension}`,
              name: `${file.name}.${file.extension}`,
              url: file.url,
            },
            startDate: res.startDate,
            endDate: res.endDate,
            checkWithoutEndDate: !res.endDate,
          },
        });
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.getDefaultValuesError`),
        });
      }
    },
    [onChangeStatus, t],
  );

  const watchOrUpdate = useCallback(
    (index: number, row: StudentStatusListType, action: 'update' | 'watch') => {
      return (
        <div onClick={() => getValuesStatus(index, row, action)}>
          <Icon
            className="mx-2 cursor-pointer"
            name={action === 'update' ? 'edit' : 'search'}
          />
        </div>
      );
    },
    [getValuesStatus],
  );

  const onChangeSelectedStatus = useCallback(
    (
      selectedOption: SelectOptionType,
      row: StudentStatusListType,
      index: number,
    ) => {
      if (selectedOption.value !== row.status) {
        onChangeStatus({
          index: index,
          studentId: row.studentId,
          studyPlanEnrollmentId: row.studyPlanEnrollmentId,
          actualStatus: row.status,
          newStatus: selectedOption.value.toString(),
          action: 'create',
        });
      }
    },
    [onChangeStatus],
  );

  const getNameAndId = (row: StudentStatusListType) => {
    const {
      paternalPreference,
      paternalLastName,
      maternalLastName,
      name,
      run,
      passportNumber,
    } = row.account;
    return (
      <div className="text-uppercase">
        {paternalPreference
          ? `${paternalLastName} ${maternalLastName}`
          : `${maternalLastName} ${paternalLastName}`}
        , {name}{' '}
        <span className="text-nowrap">
          {run ? format(run) : passportNumber}
        </span>
      </div>
    );
  };

  const statusTitledAndGraduate = useMemo<SelectOptionType[]>(() => {
    return statusOptions.filter((state) => {
      return (
        state.value === STUDENT_STATUS.TITLED ||
        state.value === STUDENT_STATUS.GRADUATE
      );
    });
  }, [statusOptions]);

  const otherStatus = useMemo<SelectOptionType[]>(() => {
    return statusOptions.filter((state) => {
      return (
        state.value !== STUDENT_STATUS.TITLED &&
        state.value !== STUDENT_STATUS.GRADUATE
      );
    });
  }, [statusOptions]);

  const columns: ColumnTable<StudentStatusListType>[] = useMemo(() => {
    return [
      {
        columnName: 'id',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.nameAndID`),
        cellFormat: ({ row }) => getNameAndId(row),
      },
      {
        columnName: 'studyPlan',
        width: '25%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.studyPlan`),
        cellFormat: ({ row }) => row.studyPlan.name,
      },
      {
        columnName: 'campus',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.campus`),
        cellFormat: ({ row }) => row.studyPlan.campus,
      },
      {
        columnName: 'studyPlan',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.schedule`),
        cellFormat: ({ row }) => row.studyPlan.schedule,
      },
      {
        columnName: 'id',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.periodStart`),
        cellFormat: ({ row }) =>
          `${row.studyPlan.semesterAdmission} - ${row.studyPlan.yearAdmission}`,
      },
      {
        columnName: 'studyPlanEnrollmentId',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.action`),
        cellFormat: ({ row, index }) => {
          if (
            row.status === STUDENT_STATUS.TITLED ||
            row.status === STUDENT_STATUS.GRADUATE
          ) {
            return t(`${prefix}.actionRegular`);
          } else if (row.status === STUDENT_STATUS.REGULAR) {
            if (row.reincorporated) {
              return (
                <div>
                  {row.reincorporated.status === 'reincorporacion_manual'
                    ? t(`${prefix}.actionRegularManualReincorporated`)
                    : t(`${prefix}.actionRegularReincorporated`)}
                  <span className="text-nowrap">
                    {formatDate(row.reincorporated.date, 'DD-MM-YYYY')}
                  </span>
                </div>
              );
            } else return t(`${prefix}.actionRegular`);
          } else {
            return (
              <div className="d-flex justify-content-center">
                {watchOrUpdate(index, row, 'watch')}
                {watchOrUpdate(index, row, 'update')}
              </div>
            );
          }
        },
      },
      {
        columnName: 'status',
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'pb-0',
        headerText: t(`${prefix}.actualStatus`),
        cellFormat: ({ row, index }) => {
          if (
            row.status === STUDENT_STATUS.TITLED ||
            row.status === STUDENT_STATUS.GRADUATE
          ) {
            return (
              <div style={{ minWidth: '150px' }}>
                <OutlinedSelect
                  disabled
                  name={`status_${index}`}
                  options={statusTitledAndGraduate}
                  value={{
                    value: row.status,
                    label: t(`studentStatus.status.${row.status}`),
                  }}
                  isClearable={false}
                />
              </div>
            );
          } else {
            return (
              <div style={{ minWidth: '150px' }}>
                <OutlinedSelect
                  name={`status_${index}`}
                  options={otherStatus}
                  value={{
                    value: row.status,
                    label: t(`studentStatus.status.${row.status}`),
                  }}
                  onChange={(selectedOption) =>
                    onChangeSelectedStatus(selectedOption, row, index)
                  }
                  isClearable={false}
                  menuPosition="fixed"
                  menuShouldBlockScroll
                  disabled={
                    !isAuthorizedTo([PermissionName.CHANGE_STUDENT_STATUS])
                  }
                />
              </div>
            );
          }
        },
      },
    ];
  }, [
    t,
    isAuthorizedTo,
    watchOrUpdate,
    statusTitledAndGraduate,
    otherStatus,
    onChangeSelectedStatus,
  ]);

  return columns;
};
// isAuthorizedTo([PermissionName.CHANGE_STUDENT_STATUS]) &&
