import {
  Button,
  DateInput,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { AxiosResult, AxiosResultDefaultError } from '../../../../api/request';
import { Period } from '../../../../api/requests/MantainerPortal';
import { saveTuitionRequest } from '../../../../api/requests/tariffAndTuitions';
import CardTitle from '../../../../components/text/CardTitle';
import { useValidations } from '../../../../hooks/useValidations';
import { TuitionFormType, TuitionItem } from '../../../../types/periodTuition';
import { saveTuitionContinuityValue } from '../../../../api/requests/tuitionContinuityTariff';

interface TuitionFormProps {
  recordToEdit: TuitionItem | null;
  onSubmit: (result: AxiosResult<TuitionItem, AxiosResultDefaultError>) => void;
  onCancel: () => void;
  periods: Period[];
  isContinuity: boolean;
}

const formatInput = (value: number) => {
  const newValue = value.toString().replace(/\D/g, '');
  if (Number(newValue) < 0) return '';
  return newValue;
};

const getModalTexts = (editing: boolean) => {
  const prefix = 'tariffAndTuition.tuition';
  return editing
    ? {
        title: `${prefix}.form.editTuition`,
        button: 'common.actions.confirm',
      }
    : {
        title: `${prefix}.form.newTuition`,
        button: `${prefix}.form.createTuition`,
      };
};

export const TuitionForm = ({
  recordToEdit,
  onSubmit: onSubmitProp,
  onCancel,
  periods,
  isContinuity,
}: TuitionFormProps) => {
  const { t } = useTranslation();

  const { msgValidations, validateTextNotEmpty, rulesPatternNumeric } =
    useValidations();

  const editing = !!recordToEdit;
  const texts = getModalTexts(editing);

  const getDefaultValues = (record: TuitionItem | null): TuitionFormType => {
    if (record) {
      return {
        value: record.value,
        period: {
          label: record.name,
          value: record.periodId,
        },
        startDate: record.startDate,
        endDate: record.endDate,
      };
    }
    return {
      value: 0,
      period: {
        label: '',
        value: 0,
      },
    };
  };

  const methods = useForm<TuitionFormType>({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(recordToEdit),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
    setError,
    watch,
    register,
  } = methods;

  const selectValue = watch('period');
  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  useEffect(() => {
    register('period');
    register('startDate');
    register('endDate');
  }, [register]);

  useEffect(() => {
    if (recordToEdit)
      setValue('period', {
        label: recordToEdit?.name,
        value: recordToEdit?.periodId,
      });
  }, [setValue, recordToEdit]);

  const handleChange = (e: SelectOptionType) => {
    setValue('period', e);
    setValue('startDate', e.startDate);
    setValue('endDate', e.endDate);
  };

  const onSubmit = useCallback(
    async (values: TuitionFormType) => {
      const { value, period } = values;

      const requestBody = {
        value: Number(value),
        periodId: Number(period?.value),
        id: recordToEdit?.code,
      };

      const result = isContinuity
        ? await saveTuitionContinuityValue(requestBody)
        : await saveTuitionRequest(requestBody);
      if (result.error && result.error.code === 'HTTP_ERROR') {
        let messageError = t('common.errors.codeError', {
          code: values.value,
        });
        if (
          result.error.data.message[0] ===
          'code must contain only letters and numbers'
        ) {
          messageError = t('common.errors.onlyLettersNumbers');
        }
        setError('value', {
          message: messageError,
        });
        return;
      }
      onSubmitProp(result);
    },
    [onSubmitProp, setError, t, recordToEdit, isContinuity],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="text-center">
              <CardTitle children={t(texts.title)} />
            </div>
          </Col>
        </Row>
        <Row className="mt-0 mt-md-4">
          <Col xs="12" md="6">
            <Select
              name="period"
              label={t('packages.period')}
              options={periods.map<SelectOptionType>((period) => ({
                value: period.id,
                label: period.name,
                startDate: period.startDate,
                endDate: period.endDate,
              }))}
              rules={{
                required: msgValidations.required,
              }}
              isClearable={false}
              value={selectValue!}
              onChange={handleChange}
              disabled={editing}
            />
          </Col>
          <Col xs="12" md="6">
            <TextInput
              name="value"
              label={t('common.forms.tutionValue')}
              control={control}
              formatter={formatInput}
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                },
                pattern: rulesPatternNumeric,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <DateInput
              name="startDate"
              label={t(`common.forms.startDate`)}
              rules={{ required: msgValidations.required }}
              value={startDateValue}
              disabled
            />
          </Col>
          <Col xs="12" md="6">
            <DateInput
              name="endDate"
              label={t(`common.forms.endDate`)}
              value={endDateValue}
              disabled
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <Button
              type="button"
              text={t('common.actions.cancel')}
              outlined
              onClick={onCancel}
              fullwidth
            />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
            <Button
              type="submit"
              text={t(texts.button)}
              loading={isSubmitting}
              fullwidth
              className="mb-3 mb-md-0"
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};
