import { useCallback, useEffect, useState } from 'react';

import { Period } from '../../../../api/requests/MantainerPortal';
import { getPeriod } from '../../../../api/requests/tariffAndTuitions';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { getTcPeriodsValue } from '../../../../api/requests/tuitionContinuityTariff';

export interface RequestsData {
  periods: Period[];
}

export interface PeriodsLoaderProps {
  children: (data: RequestsData) => JSX.Element;
  isTuitionContinuity: boolean;
}

export const PeriodsLoader = (props: PeriodsLoaderProps) => {
  const { children, isTuitionContinuity } = props;
  const [periods, setPeriods] = useState<Period[]>([]);

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const loadData = useCallback(async () => {
    const { data, error } = isTuitionContinuity
      ? await getTcPeriodsValue()
      : await getPeriod();
    if (error) {
      setErrorLoading(error.code);
    }

    if (data) {
      setErrorLoading(undefined);
      setPeriods(data.data);
    }

    setLoading(false);
  }, [setErrorLoading, setLoading, isTuitionContinuity]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return <DisplayError insideCard textBody={errorLoading} />;
  }

  return children({ periods });
};
