import { SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';
import { getResponsiblesRequest } from '../../api/requests/parameters';

export const useSearchResponsible = () => {
  return useCallback(
    async (searchText: string): Promise<SelectOptionType[]> => {
      const { data } = await getResponsiblesRequest(searchText);
      if (data) {
        return data.data.map((responsible) => ({
          value: responsible.id,
          label: responsible.fullName ?? '',
        }));
      }
      return [];
    },
    [],
  );
};
