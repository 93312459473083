import {
  Button,
  PaginationType,
  SelectOptionType,
  Table,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { useColumnsCoursesList } from './parts/useColumnsCoursesList';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLoadingState } from '../../hooks/useLoadingState';
import DisplayError from '../../components/info/DisplayError';
import { CourseMaintainerListType } from '../../types/courseMaintainerTypes';
import { requestCoursesMaintainerList } from '../../api/requests/coursesMaintainer';
import { useHistory } from 'react-router-dom';
import SearchFilters, { SearchFiltersType } from './parts/SearchFilters';
import Loading from '../../components/info/Loading';
import { getSchoolListRequest } from '../../api/requests/sectionsMaintainer';

const ITEMS_PER_PAGE = 10;

const CoursesMaintainer = () => {
  const prefix = 'courses.list';
  const { t } = useTranslation();
  const history = useHistory();
  const [dataList, setDataList] = useState<CourseMaintainerListType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastFilters, setLastFilters] =
    useState<{ faculty: SelectOptionType; searchText: string }>();
  const [schools, setSchools] = useState<SelectOptionType[]>([]);
  const columns = useColumnsCoursesList();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [searching, setSearching] = useState<boolean>(true);

  const getSchools = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSchoolListRequest();
    if (data) {
      setSchools(data.map((item) => ({ value: item.id, label: item.name })));
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setSchools, setLoading]);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  const getData = useCallback(
    async (page: number) => {
      setSearching(true);

      const requestFilters = {
        schoolId: lastFilters?.faculty?.value,
        search_text: lastFilters?.searchText,
      };

      const { data, error } = await requestCoursesMaintainerList({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        ...requestFilters,
      });

      if (data?.data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setSearching(false);
    },
    [setSearching, setErrorLoading, lastFilters],
  );

  useEffect(() => {
    getData(currentPage);
  }, [currentPage, getData]);

  const isSearch = useMemo(() => {
    return lastFilters?.faculty || lastFilters?.searchText !== '';
  }, [lastFilters]);

  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => {
            setErrorLoading(undefined);
            getSchools();
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12}>
          <SearchFilters
            schools={schools}
            onSearch={(filters: SearchFiltersType) => {
              setLastFilters({
                faculty: filters.schools,
                searchText: filters.nameOrCode || '',
              });
              setCurrentPage(1);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="pb-3">
          <Button
            icon="plus"
            size="md"
            text={t(`${prefix}.btnNewCourse`)}
            onClick={() => {
              history.push('/courses-maintainer/create');
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table<CourseMaintainerListType>
            columns={columns}
            data={dataList}
            pagination={pagination}
            isLoadingResults={searching}
            noResultsText={
              <TableEmptyContent
                title={t(
                  `${prefix}.${
                    isSearch ? 'noResultsSearchTitle' : 'noResultsTitle'
                  }`,
                )}
                subtitle={t(
                  `${prefix}.${
                    isSearch ? 'noResultsSearchBody' : 'noResultsBody'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CoursesMaintainer;
