import { Box } from '@octano/global-ui';
import { TextEllipsis } from '../../../components/text';

interface TextBoxProps {
  text: string;
}

export default function TextBox(props: TextBoxProps) {
  const content = props.text;

  return (
    <div className="d-flex">
      <Box
        className="justify-content-start"
        style={{
          border: '1px solid #CCCCCC',
          color: 'var(---1e1e1e)',
        }}
        fullwidth={true}
        body={
          <TextEllipsis
            style={{
              justifyContent: "start"
            }}
            textWidth={'100%'}
            tooltip={content}
          >
            {content}
          </TextEllipsis>}
      />
    </div>
  );
}
