import { Table as TableCore } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../components/contexts/FetchContext';
import DisplayError from '../../../components/info/DisplayError';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import {
  CourseValidationInternalStudent,
  CourseValidationInternalStudents,
  CourseValidationInternalStudentsQuery,
} from '../types';
import { useColumns } from './useColumns';

export default function Table() {
  const prefix = 'courseValidationInternal';
  const { t } = useTranslation();

  const texts = useMemo(
    () => ({
      empty: t<
        string,
        Record<'noResults' | 'noSearch', Record<'title' | 'body', string>>
      >(`${prefix}.empty`, { returnObjects: true }),
    }),
    [t],
  );

  const { data, error, loading, query, updateQuery } = useFetch<
    CourseValidationInternalStudentsQuery,
    CourseValidationInternalStudents
  >();

  const pagination = useMemo(
    () => ({
      totalItems: data?.total ?? 0,
      itemsPerPage: 10,
      totalPages: data?.total_pages ?? 0,
      currentPage: query.page,
      onChangePage: (page: number) => updateQuery({ ...query, page }),
    }),
    [data, query, updateQuery],
  );

  const textsEmpty = useMemo(() => {
    if (query.search) {
      return texts.empty.noSearch;
    }
    return texts.empty.noResults;
  }, [texts, query]);

  const columns = useColumns();

  const retry = useCallback(() => {
    updateQuery({ ...query });
  }, [updateQuery, query]);

  if (error) {
    console.log({ error });
    return (
      <DisplayError
        insideCard
        textBody={error.code}
        retryAction={retry}
        loadingAction={loading}
      />
    );
  }

  return (
    <TableCore<CourseValidationInternalStudent>
      columns={columns}
      data={data?.data ?? []}
      pagination={pagination}
      isLoadingResults={loading}
      noResultsText={
        <TableEmptyContent
          title={textsEmpty.title}
          subtitle={textsEmpty.body}
        />
      }
    />
  );
}
