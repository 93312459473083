import { Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { STUDENT_STATUS } from '../../types/studentStatusTypes';

export interface StudentDegreeStatusBadgeProps {
  status: STUDENT_STATUS;
  name: string | number;
  showToolTips?: boolean;
}
export default function StudentDegreeStatusBadge({
  status,
  name,
  showToolTips = true,
}: StudentDegreeStatusBadgeProps) {
  const { t } = useTranslation();
  const data = useMemo<{ color: string; text: string; tooltip: string }>(() => {
    const colors: Record<STUDENT_STATUS, string> = {
      [STUDENT_STATUS.REGULAR]: '#CCCCCC',
      [STUDENT_STATUS.INSTITUTIONAL_RETRACTED]: '#CCCCCC',
      [STUDENT_STATUS.WITHDRAWN_STUDY_PLAN]: '#CCCCCC',
      [STUDENT_STATUS.SUSPENDED]: '#ff0000c7',
      [STUDENT_STATUS.CANCELED]: '#ff0000c7',
      [STUDENT_STATUS.RESIGNED]: '#FFE6A1',
      [STUDENT_STATUS.EXPELLED]: '#FFE6A1',
      [STUDENT_STATUS.POSTPONED]: '#FFE6A1',
      [STUDENT_STATUS.ABANDON]: '#FFE6A1',
      [STUDENT_STATUS.REINCORPORATED]: '#5a9be1',
      [STUDENT_STATUS.GRADUATE]: '#E0EEFD',
      [STUDENT_STATUS.TITLED]: '#CEF0DA',
    };
    return {
      color: colors[status],
      text: t(`studyPlanEnrollmentStatus.${status}`),
      tooltip: t(`studyPlanEnrollmentStatus.tooltips.${status}`),
    };
  }, [status, t]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center student-degree-status-badge">
        <Badge
          className="status-badge fs-16 fw-400 py-2 px-4 text-dark"
          style={{ backgroundColor: data.color, width: '110px' }}
          pill
        >
          {data.text}
        </Badge>
        {showToolTips && (
          <div className="ml-3" id={`inputTooltip_${name}`}>
            <Icon name="information_input" size="1rem" />
            <UncontrolledTooltip
              placement="bottom"
              target={`inputTooltip_${name}`}
              fade={false}
            >
              {data.tooltip}
            </UncontrolledTooltip>
          </div>
        )}
      </div>
    </>
  );
}
