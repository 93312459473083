import React from 'react';
import AcademicOffersFormLoader from './parts/AcademicOffersFormLoader';
import AcademicOffersCreateForm from './parts/AcademicOffersCreateForm';

export default function AcademicOffersCreate() {
  return (
    <AcademicOffersFormLoader>
      {({ data, refresh }) => (
        <AcademicOffersCreateForm data={data} refresh={refresh} />
      )}
    </AcademicOffersFormLoader>
  );
}
