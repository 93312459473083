import { OutlinedSelectOptionType, Select, TextInput } from '@octano/global-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { getAllAcademicOffersRequest } from '../../../api/requests/tariffAndTuitions';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { AcademicOffersTypeDuplicate as AcademicOffers } from '../../../types/AcademicOffersType';
import TariffTable from './pats/TariffTable';

function Tariff() {
  const { t } = useTranslation();
  const { showTabs } = useLayoutState();
  const [academicOffers, setAcademicOffers] = useState<AcademicOffers[]>([]);
  const [referralOffer, setReferralOffer] = useState<AcademicOffers | null>(
    null,
  );
  const [currentAcademicOffers, setCurrentAcademicOffers] =
    useState<AcademicOffers>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const selectOptions = academicOffers.map<OutlinedSelectOptionType>(
    (offer) => {
      return {
        label: offer.period.name,
        value: offer.id,
      };
    },
  );

  const handleChange = (option: OutlinedSelectOptionType) => {
    const current = academicOffers.find(({ id }) => option.value === id);
    setCurrentAcademicOffers(current);
    setReferralOffer(current?.duplicatedAcademicOffer || null);
  };

  const getAllAcademicOffer = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getAllAcademicOffersRequest();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading(undefined);
      setAcademicOffers(data.data);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading]);

  useEffect(() => {
    getAllAcademicOffer();
  }, [getAllAcademicOffer]);

  useEffect(() => {
    showTabs();
  }, [showTabs]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => getAllAcademicOffer()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <div className="g-table-container pt-4">
      <p className="fs-20 text-uppercase text-primary fw-700 ">
        {t('tariffAndTuition.tariff.title')}
      </p>
      <p> {t('tariffAndTuition.tariff.subTitle')}</p>
      <Row>
        <Col md={6}>
          <Select
            name="selection-period"
            label={t('tariffAndTuition.tariff.selectLabel')}
            isClearable={false}
            options={selectOptions}
            onChange={handleChange}
          />
        </Col>
        {currentAcademicOffers && (
          <Col md={6}>
            <TextInput
              name="selection-period"
              label={t('tariffAndTuition.tariff.inputLabel')}
              value={referralOffer?.period.name || 'Sin referencia'}
              disabled
            />
          </Col>
        )}
      </Row>
      <br className="mt-4" />
      {currentAcademicOffers && (
        <TariffTable academicOfferId={currentAcademicOffers.id} />
      )}
    </div>
  );
}
export default Tariff;
