import dayjs from 'dayjs';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  TuitionContinuityProcessManagementDetail,
  TuitionContinuityProcessManagementErrors,
  getTuitionContinuityProcessManagementDetail,
} from '../../../api/requests/tuitionContinuityProcessManagement';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';

export interface TuitionContinuityProcessManagementDetailLoaderProps<T> {
  studyPlanVersionOfferId: number | string;
  periodId: number | string;

  children: (props: T) => ReactElement;
}

export function TuitionContinuityProcessManagementDetailLoader({
  studyPlanVersionOfferId,
  periodId,
  children,
}: TuitionContinuityProcessManagementDetailLoaderProps<TuitionContinuityProcessManagementDetail>) {
  const prefix = 'tuitionContinuityProcessManagement';
  const { t } = useTranslation();

  const { loading, setLoading } = useLoadingState();

  const [data, setData] = useState<TuitionContinuityProcessManagementDetail>();
  const [error, setError] =
    useState<TuitionContinuityProcessManagementErrors>();

  /* Función que se llama cuando se monta el componente. Trae la info utilizada en la vista */
  const load = useCallback(async () => {
    setLoading(true);

    const response = await getTuitionContinuityProcessManagementDetail(
      studyPlanVersionOfferId,
      periodId,
    );

    if (response.data) {
      setData(response.data);
      setError(undefined);
    }
    if (response.error) {
      setError(response.error);
    }
    setLoading(false);
  }, [setLoading, studyPlanVersionOfferId, periodId]);

  useEffect(() => {
    load();
  }, [load]);

  if (error) {
    const { title, body } = getErrorTexts(error, t, prefix);
    return (
      <DisplayError
        insideCard
        title={title}
        textBody={body}
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!data) {
    return (
      <DisplayError
        insideCard
        textBody="Data no cargada"
        retryAction={() => load()}
        loadingAction={loading}
      />
    );
  }

  return children(data);
}

function getErrorTexts(
  error: TuitionContinuityProcessManagementErrors,
  t: TFunction<'translation'>,
  prefix: string,
) {
  if (error.code === 'MISSING_EVENT') {
    return {
      title: t(`${prefix}.error.no-event.title`),
      body: t(`${prefix}.error.no-event.body`),
    };
  }
  if (error.code === 'INCOMING_EVENT' || error.code === 'PERIOD_EXCEEDED') {
    return {
      title: t(`${prefix}.error.event-locking.title`),
      body: t(`${prefix}.error.event-locking.body`, {
        start: dayjs(error.range[0]).format('DD-MM-YYYY'),
        end: dayjs(error.range[1]).format('DD-MM-YYYY'),
      }),
    };
  }
  return {
    body: error.code,
  };
}
