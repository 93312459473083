import { useMemo } from 'react';

import { Card, Container } from 'reactstrap';
import TabControl from '../../components/tabs/TabControl';
import { PathsLayouts } from '../../config/routes';
import GraduateList from './GraduateList';
import TitledList from './TitledList';

export default function StudentsDegree() {
  const tabsPrefix = 'studentsDegree';

  const tabs = useMemo(
    () => [
      { name: 'graduate', content: <GraduateList /> },
      { name: 'titled', content: <TitledList /> },
    ],
    [],
  );

  return (
    <Container fluid>
      <Card>
        <TabControl
          tabsPrefix={`${tabsPrefix}.tabs`}
          tabs={tabs}
          path={PathsLayouts.studentsDegree}
        />
      </Card>
    </Container>
  );
}
