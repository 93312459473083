import { Redirect, useParams } from 'react-router-dom';
import BaseLoader from './parts/BaseLoader';
import { PathsLayouts } from '../../config/routes';
import StudentsPetitionsReceivedDetail from './Received/Received';
import StudentsPetitionsResolvedDetail from './Resolved/Resolved';
import { PetitionStatus } from '../../types/studentPetitions';
import { isNumber } from '../../utils/math';
import { useMemo } from 'react';

export default function StudentsPetitionsDetail() {
  const { petitionId: petitionIdStr } = useParams<{ petitionId: string }>();
  const petitionId = useMemo(() => {
    if (isNumber(petitionIdStr)) {
      return Number(petitionIdStr);
    }
    return null;
  }, [petitionIdStr]);

  if (!petitionId) {
    return <Redirect to={PathsLayouts.studentPetitions} />;
  }

  return (
    <BaseLoader petitionId={petitionId}>
      {(data) => (
        data && data.status !== PetitionStatus.Resolved ?
          <StudentsPetitionsReceivedDetail /> : <StudentsPetitionsResolvedDetail />
      )}
    </BaseLoader>
  )
}