import { useFetch } from '../../../components/contexts/FetchContext';
import { StudentListParams } from '../parts/TuitionContinuityDocumentReviewDetailLoader';
import {
  TcDocumentReviewStudentData,
  TcDocumentReviewStudentTabResponse,
  TuitionContinuityDocumentStatusEnum,
} from '../../../types/tuitionContinuityDocumentReview';
import { usePagination } from '../../../hooks/usePagination';
import { useCallback, useEffect, useMemo } from 'react';
import { Button, ColumnTable, Table, TablePagination } from '@octano/global-ui';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import DisplayError from '../../../components/info/DisplayError';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';
import SearchRut from '../../../components/inputs/SearchRutValue';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';

export interface TuitionContinuityDocumentReviewTabListProps {
  currentStatus?: TuitionContinuityDocumentStatusEnum;
  status: TuitionContinuityDocumentStatusEnum;
}

export default function TuitionContinuityDocumentReviewTabList({
  currentStatus,
  status,
}: TuitionContinuityDocumentReviewTabListProps) {
  const { data, loading, error, updateQuery, query } = useFetch<
    StudentListParams,
    { data: TcDocumentReviewStudentTabResponse },
    { code: string }
  >();
  const {
    currentPage,
    itemsPerPage,
    onChangePage,
    total,
    setItems,
    items,
    isPaginated,
    totalPages,
    handlerSearch,
  } = usePagination<TcDocumentReviewStudentData, StudentListParams>({
    page: 0,
    itemsPerPage: 10,
    search: updateQuery,
    query,
    items: data?.data.students,
  });
  useEffect(() => {
    if (data) {
      setItems(data.data.students);
    }
  }, [data, setItems]);

  useEffect(() => {
    if (query.status !== currentStatus) {
      handlerSearch({ status: status });
    }
  }, [currentStatus, handlerSearch, query.status, status]);

  const { t } = useTranslation();
  const text = useMemo(() => {
    const prefix = 'TuitionContinuityDocumentReview.tabs';
    return {
      columns: {
        run: t(`${prefix}.columns.run`),
        fullName: t(`${prefix}.columns.fullName`),
        cellPhone: t(`${prefix}.columns.cellPhone`),
        email: t(`${prefix}.columns.email`),
        action: t(`${prefix}.columns.action`),
      },
      empty: {
        title: t(`${prefix}.listEmpty.title`),
        body: t(`${prefix}.listEmpty.body`),
      },
      search: {
        label: t(`${prefix}.search.label`),
        placeholder: t(`${prefix}.search.placeholder`),
      },
      viewDocuments: t(`${prefix}.viewDocuments`),
    };
  }, [t]);

  const history = useHistory();
  const goDocument = useCallback(
    (tcProcessId: number) => {
      history.push(
        `${PathsLayouts.tuitionContinuityDocumentReview}/documents/${tcProcessId}`,
      );
    },
    [history],
  );

  const columns = useMemo<ColumnTable<TcDocumentReviewStudentData>[]>(
    () => [
      {
        columnName: 'run',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        headerText: text.columns.run,
        cellFormat: ({ value, row }) => {
          if (value == null) {
            return row.passportNumber ?? '';
          }
          return format(value);
        },
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'fullName',
        headerText: text.columns.fullName,
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'cellPhone',
        headerText: text.columns.cellPhone,
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'email',
        headerText: text.columns.email,
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'action',
        headerText: text.columns.action,
        cellFormat: ({ row }) => {
          return (
            <Button
              text={text.viewDocuments}
              size="sm"
              fullwidth
              color="primary"
              onClick={() => goDocument(row.tcProcessId)}
            />
          );
        },
      },
    ],
    [text, goDocument],
  );
  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error.code}
        retryAction={() => {
          updateQuery();
        }}
      />
    );
  }
  return (
    <Card className="mt-4">
      <SearchRut
        value={query.rutOrPassport}
        onChange={(search) => handlerSearch({ rutOrPassport: search, status })}
        searching={loading}
        label={text.search.label}
        placeholder={text.search.placeholder}
      />
      <Table
        columns={columns}
        data={items}
        noResultsText={
          <TableEmptyContent
            title={text.empty.title}
            subtitle={text.empty.body}
          />
        }
        isLoadingResults={loading}
      />
      {isPaginated && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage,
            onChangePage,
            totalItems: total,
            totalPages,
          }}
        />
      )}
    </Card>
  );
}
