import { addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { ContinuityRow } from '../types/ContinuityRow';
import { SearchParams } from '../types/Search';
import ContinuityTable from './parts/ContinuityTable';

import { getRegularStudentsListContinuity } from '../services/request';
import { SearchControls } from './parts/SearchControls';

const dictPrefix = 'changeStudyPlan.continutityList';
const ITEMS_PER_PAGE = 10;

export default function ContinuityList() {
  const { t } = useTranslation();
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [tableData, setTableData] = useState<ContinuityRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: SearchParams;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getRegularStudentsListContinuity({
        items: ITEMS_PER_PAGE,
        page: page,
        search: searchParams?.search,
        studyPlanId: searchParams?.studyPlanId,
        campusId: searchParams?.campusId,
        schoolId: searchParams?.schoolId,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalData(data?.total || 0);
        page === 0 && setCurrentPage(page);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  return (
    <Card className="mx-3 p-4">
      <p className="fs-16 mt-3 text-light mb-2">{t(`${dictPrefix}.title`)}</p>
      <p className="fs-16 mt-3 text-light mb-3">
        {t(`${dictPrefix}.searchControls.help`)}
      </p>

      <div className="mb-5">
        <SearchControls
          onSearch={handleSearch}
          onClear={handleClear}
          setFilters={setFilters}
        />
      </div>

      <ContinuityTable
        isLoadingResults={isTableLoading}
        data={tableData}
        totalPages={totalPages}
        totalPetitions={totalData}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
        hasFilters={filters?.hasFilters}
      />
    </Card>
  );
}
