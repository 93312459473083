import { ColumnTable, Table, TablePagination } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TuitionContinuityProcessDetailStudentResponseDto,
  TuitionContinuityProcessDetailStudentsResponseDto,
  TuitionContinuityProcessManagementErrors,
} from '../../../api/requests/tuitionContinuityProcessManagement';
import { useFetch } from '../../../components/contexts/FetchContext';
import DisplayError from '../../../components/info/DisplayError';
import { SearchIdNotFound } from '../../../components/info/SearchIdNotFound';
import SearchRut from '../../../components/inputs/SearchRutValue';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
export interface TuitionContinuityProcessManagementDetailTabProps {
  columns: ColumnTable<TuitionContinuityProcessDetailStudentResponseDto>[];
}

export default function TuitionContinuityProcessManagementDetailTab({
  columns,
}: TuitionContinuityProcessManagementDetailTabProps) {
  const { loading, data, error, query, updateQuery } = useFetch<
    { search?: string; page: number },
    TuitionContinuityProcessDetailStudentsResponseDto,
    TuitionContinuityProcessManagementErrors
  >();

  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagementDetail';
  const texts = useMemo(
    () => ({
      searchId: {
        label: t(`common.searchId.label`),
        placeholder: t(`common.searchId.placeholder`),
      },
      emptyList: t(`${prefix}.emptyList`),
    }),
    [t],
  );

  const TableEmpty = useMemo(() => {
    if (query.search) {
      return <SearchIdNotFound />;
    } else {
      return <TableEmptyContent title={texts.emptyList} subtitle={''} />;
    }
  }, [query, texts]);

  if (error) {
    return (
      <DisplayError
        insideCard
        textBody={error.code}
        retryAction={() => {
          updateQuery();
        }}
      />
    );
  }

  const totalItems = data?.total ?? 0;
  const totalPages = data?.total_pages ?? 0;
  const currentPage = query.page;
  const showPagination = data ? data.total_pages > 1 : false;

  return (
    <div className="mt-3 text-info">
      <SearchRut
        value={query.search}
        onChange={(search) => updateQuery({ page: 1, search })}
        searching={loading}
        label={texts.searchId.label}
        placeholder={texts.searchId.placeholder}
      />

      <Table
        columns={columns}
        data={data?.data ?? []}
        noResultsText={TableEmpty}
        isLoadingResults={loading}
      />

      {showPagination && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage: 10,
            onChangePage: (page) => {
              updateQuery({ page, search: query.search });
            },
            totalItems,
            totalPages,
          }}
        />
      )}
    </div>
  );
}
