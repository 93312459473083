import { TablePagination } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  getDocumentationReview,
  StudyPlansOfferDocumentationReview,
} from '../../../api/requests/studyPlansOffer';
import { Card as CustomCard } from '../../../components/article/Card';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoadingState } from '../../../hooks/useLoadingState';

interface IDocumentsGridCardsContentProps {
  periodId: number;
  campusId?: number;
  schoolId?: number;
}

const ITEMS_PER_PAGE_DEFAULT = 12;

export default function DocumentsGridCardsContent({
  periodId,
  campusId,
  schoolId,
}: IDocumentsGridCardsContentProps) {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [documentationReview, setDocumentationReview] = useState<
    StudyPlansOfferDocumentationReview[]
  >([]);

  const fetchData = useCallback(
    async (currentPage = 1) => {
      setLoading(true);
      let { data, error } = await getDocumentationReview({
        periodId,
        campusId,
        schoolId,
        items_per_page: ITEMS_PER_PAGE_DEFAULT,
        page: currentPage,
      });
      if (error) {
        setErrorLoading(error.code);
      } else if (data) {
        setDocumentationReview(data.data);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      }
      setLoading(false);
    },
    [setLoading, periodId, campusId, schoolId, setErrorLoading],
  );

  useEffect(() => {
    fetchData();
    setCurrentPage(1);
  }, [setLoading, fetchData]);

  useEffect(() => {
    if (currentPage !== 1) {
      fetchData(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, currentPage]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }

  return (
    <>
      {documentationReview.length === 0 && !loading && (
        <TableEmptyContent
          title={t(
            'academicOffers.form.AdmissionsPlanesStudentCardsTableEmptyTitle',
          )}
          subtitle={t(
            'academicOffers.form.AdmissionsPlanesStudentCardsTableEmptySubtitle',
          )}
        />
      )}

      {loading && <Loading insideCard />}

      {!loading && (
        <Row className="pt-4">
          {documentationReview.map((documentation, key) => {
            const {
              Campus_name,
              Schedule_name,
              StudyPlan_name,
              enrolled,
              notReviewed,
              waiting,
              approved,
              approvedPercentage,
              StudyPlanVersionOffer_id,
            } = documentation;

            return (
              <Col md={3} key={key} className="mb-4">
                <CustomCard
                  title={StudyPlan_name}
                  subtitle={`${Campus_name} - ${Schedule_name}`}
                  body={[
                    { label: t('documents.enrolled'), value: enrolled },
                    { label: t('documents.notReviewed'), value: notReviewed },
                    { label: t('documents.waiting'), value: waiting },
                    { label: t('documents.approved'), value: approved },
                  ]}
                  markedText={{
                    label: t('documents.approved'),
                    value: `${
                      approvedPercentage < 100
                        ? approvedPercentage.toFixed(1)
                        : Math.round(approvedPercentage)
                    }%`,
                  }}
                  to={`${PathsLayouts.documents}/${StudyPlanVersionOffer_id}/documentation-not-reviewed`}
                />
              </Col>
            );
          })}
        </Row>
      )}

      {totalPages > 1 && (
        <TablePagination
          pagination={{
            currentPage: currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              setCurrentPage(page);
            },
            totalItems: totalItems,
            totalPages: totalPages,
          }}
        />
      )}
    </>
  );
}
