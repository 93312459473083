import { Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import { PetitionStatus } from '../../../types/studentPetitions';

export interface InputToolTipProps {
  prefix: string
  status: PetitionStatus;
  name: string | number;
  text?: string;
}

export default function InputToolTip({ prefix, status, name, text }: InputToolTipProps) {
  const { t } = useTranslation();

  const data = useMemo<{ text: string; tooltip: string }>(() => {
    return {
      text: t(`${prefix}.status.${status}`),
      tooltip: t(`${prefix}.tooltips.${status}`),
    };
  }, [status, prefix, t]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-start">
        <div className="d-flex" style={{ gap: '3px' }}>
          <div className="mr-2" id={`inputTooltip_${status}`}>
            <Icon name="information_input" size="1rem" />
            <UncontrolledTooltip
              placement="bottom"
              target={`inputTooltip_${status}`}
              fade={false}
            >
              {data.tooltip}
            </UncontrolledTooltip>
          </div>
          <p className="d-flex" style={{ gap: '3px' }}>
            <b>{name}:</b>
            <span>{text || data.text}</span>
          </p>
        </div>
      </div>
    </>
  );
}
