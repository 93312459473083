import { DetailBox } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import {
  getStudyPlanCampusSchedule,
  StudyPlansOfferCampusSchedule,
} from '../../api/requests/studyPlansOffer';
import GoBackButton from '../../components/buttons/GoBackButton';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { PathsLayouts } from '../../config/routes';
import { useLoadingState } from '../../hooks/useLoadingState';
import useQuery from '../../hooks/useQuery';
import { StudyDocuments } from './parts/StudyDocuments';
import { TablePostulantsProcessByStatus } from './parts/TablePostulantsProcessByStatus';

export function DocumentsList() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string; status: string }>();
  const history = useHistory();
  const query = useQuery();
  const detailId = useMemo<string | null>(() => {
    return query.get('id');
  }, [query]);
  const [studyPlansOfferCampusSchedule, setStudyPlansOfferCampusSchedule] =
    useState<StudyPlansOfferCampusSchedule>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const tags = useMemo(() => {
    return [
      {
        title: t('admission.studyPlan'),
        body:
          (studyPlansOfferCampusSchedule &&
            studyPlansOfferCampusSchedule.StudyPlan_name) ||
          '',
        size: { xs: 12, md: 6 },
      },
      {
        title: t('admission.campus'),
        body:
          (studyPlansOfferCampusSchedule &&
            studyPlansOfferCampusSchedule.Campus_name) ||
          '',
        size: { xs: 12, md: 3 },
      },
      {
        title: t('admission.workingDay'),
        body:
          (studyPlansOfferCampusSchedule &&
            studyPlansOfferCampusSchedule.Schedule_name) ||
          '',
        size: { xs: 12, md: 3 },
      },
    ];
  }, [t, studyPlansOfferCampusSchedule]);

  const fetchGetStudyPlanCampusSchedule = useCallback(async () => {
    setLoading(true);
    let { data, error } = await getStudyPlanCampusSchedule(Number(id));
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading('');
      setStudyPlansOfferCampusSchedule(data);
    }
    setLoading(false);
  }, [id, setLoading, setErrorLoading, setStudyPlansOfferCampusSchedule]);

  useEffect(() => {
    fetchGetStudyPlanCampusSchedule();
  }, [fetchGetStudyPlanCampusSchedule]);

  function back() {
    if (detailId) {
      history.push({
        search: history.location.search.replace(
          new RegExp(`([&]|[?])id=${detailId}`),
          '',
        ),
      });
    } else {
      history.push(PathsLayouts.documents);
    }
  }
  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => fetchGetStudyPlanCampusSchedule()}
          loadingAction={loading}
        />
      </div>
    );
  }
  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }
  return (
    <Card className="px-4 px-lg-5 py-4 mx-3">
      <header className="mb-5">
        <Row>
          <Col className="mb-4">
            <GoBackButton onClick={back} text={t('admission.back')} />
          </Col>
          <Col md={5}>
            <DetailBox columns={tags} />
          </Col>
        </Row>
      </header>
      {detailId ? (
        <StudyDocuments detailId={Number(detailId)} />
      ) : (
        <TablePostulantsProcessByStatus />
      )}
    </Card>
  );
}
