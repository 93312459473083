import { ColumnTable, Table, TextOutlinedInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useValidations } from '../../../hooks/useValidations';

type ActivityAttendanceFieldsTableProps = {
  assistanceRequirements: object;
  setAssistanceRequirements: (item: object) => void;
};

const ActivityAttendanceFieldsTable = ({
  assistanceRequirements,
  setAssistanceRequirements,
}: ActivityAttendanceFieldsTableProps) => {
  const { t } = useTranslation();
  const prefix = 'sectionsMaintainer.update.attendanceTable';
  const { rulesPatternNumeric } = useValidations();

  const columns: ColumnTable<any>[] = [
    {
      columnName: 'activityType',
      headerText: t(`${prefix}.activityType`),
      cellFormat: ({ row }) => <>{row.name}</>,
    },
    {
      columnName: 'percentage',
      headerText: t(`${prefix}.percentage`),
      cellFormat: ({ row }) => (
        <Col md={{ size: 6, offset: 3 }}>
          <TextOutlinedInput
            name={`percentage-${row.id}`}
            value={
              row.minPercentage.toString() === '0'
                ? '- %'
                : row.minPercentage + '%'
            }
            rules={{
              pattern: rulesPatternNumeric,
            }}
            onChange={(event) =>
              setAssistanceRequirements({
                ...assistanceRequirements,
                [row.id]: {
                  id: row.id,
                  name: row.name,
                  minPercentage: validatePercentage(event.target.value),
                  activityTypeId: row.activityTypeId,
                },
              })
            }
          />
        </Col>
      ),
    },
  ];

  const validatePercentage = (value: string) => {
    const MAX_ACCEPTED = 100;
    const numbersOnlyValue = value.replace(/\D/g, '');

    if (isNaN(+numbersOnlyValue)) return value.slice(0, -1);
    if (+numbersOnlyValue > MAX_ACCEPTED) return `${MAX_ACCEPTED}`;

    return numbersOnlyValue;
  };

  return (
    <div className="activity-table text-center">
      <Table
        borderless={false}
        striped={false}
        height={700}
        columns={columns}
        data={Object.values(assistanceRequirements).sort(
          (a, b) => a.activityTypeId - b.activityTypeId,
        )}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </div>
  );
};

export default ActivityAttendanceFieldsTable;
