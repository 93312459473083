import { CellFormatOptions, ColumnTable, Table } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Student } from '../../../api/requests/MantainerPortal';
import { format as formatRut } from 'rut.js';
import TableEmptyContent from '../../../components/text/TableEmptyContent';

export interface TablePeriodEndReviewProps {
  data: Student[];
  section: Section;
  passingGrade: number;
}

export default function TablePeriodEndReview(props: TablePeriodEndReviewProps) {
  const { t } = useTranslation();
  const { data, passingGrade, section } = props;

  const studentColumn = useMemo(() => {
    return {
      columnName: 'student',
      headerText: t('periodEndReview.table.student'),
      tdClassName: 'text-center  column-sticky bg-white',
      thClassName: 'text-center  column-sticky bg-secondary',
      width: '280px',
      cellFormat: ({ row }: CellFormatOptions<Student>) => {
        return (
          <span>
            {row.fullName}
            <br />
            {row.run ? formatRut(row.run) : row.passportNumber}
          </span>
        );
      },
    };
  }, [t]);

  const finalGradeColumn = useMemo(() => {
    return {
      columnName: 'finalGrade',
      headerText: t('periodEndReview.table.gradeFinal'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }: CellFormatOptions<Student>) => {
        let textColor;
        if (row.grades.finalGrade === 'NR' || row.grades.finalGrade === 'P') {
          textColor = row.grades.finalGrade !== 'P' && 'text-danger';
        } else {
          textColor =
            Number(row.grades.finalGrade) >= passingGrade
              ? 'text-primary'
              : 'text-danger';
        }

        return <span className={textColor || ''}>{row.grades.finalGrade}</span>;
      },
    };
  }, [t, passingGrade]);

  const assistanceRequirementsColumn = useMemo(() => {
    return section.assistanceRequirements.map(
      ({ activityType, minPercentage, activityTypeId }) => {
        return {
          columnName: activityType.name,
          headerText: `${t('periodEndReview.table.assistances')} ${
            activityType.name
          } ${minPercentage}%`,
          tdClassName: 'text-center',
          thClassName: 'text-center',
          cellFormat: ({ row }: CellFormatOptions<Student>) => {
            return (
              <span
                className={
                  Number(row.assistances[activityTypeId].percentage) >=
                  minPercentage
                    ? 'text-primary'
                    : 'text-danger'
                }
              >
                {Number(row.assistances[activityTypeId].percentage).toFixed(1)}%
              </span>
            );
          },
        };
      },
    );
  }, [section, t]);

  const stateColumn = useMemo(() => {
    return {
      columnName: 'state',
      headerText: t('periodEndReview.table.state'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }: CellFormatOptions<Student>) => {
        const textColor =
          row.generalPassed === 'PASSED'
            ? 'text-success'
            : row.generalPassed && 'text-danger';
        return (
          <span className={textColor || ''}>
            {t(
              `periodEndReview.table.sectionStudentSummaryStateEnum.${
                row.generalPassed || 'PENDING'
              }`,
            )}
          </span>
        );
      },
    };
  }, [t]);

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      studentColumn,
      finalGradeColumn,
      ...assistanceRequirementsColumn,
      stateColumn,
    ];
  }, [
    finalGradeColumn,
    assistanceRequirementsColumn,
    stateColumn,
    studentColumn,
  ]);

  const noResultsText = useMemo(() => {
    return (
      <TableEmptyContent
        title={t('periodEndReview.table.notResult.empty')}
        subtitle=""
      />
    );
  }, [t]);

  return (
    <Table
      height={500}
      columns={columns}
      data={data}
      noResultsText={noResultsText}
      borderless={false}
      striped={false}
    />
  );
}
