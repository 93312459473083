import { Button, ColumnTable, Icon } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';

function Tooltip(names: string[]) {
  const tooltipRef = useRef(null);
  return (
    <div
      className="d-flex justify-content-center pt-3"
      style={{ position: 'relative' }}
    >
      <p> {names[0]} </p>
      {names.length > 1 ? (
        <>
          <span className="ml-1" ref={tooltipRef}>
            <Icon name="information" />
          </span>
          <div>
            <UncontrolledTooltip
              target={tooltipRef}
              placement="auto-start"
              color="light"
              style={{ width: 'max-content', paddingLeft: 0, paddingRight: 30 }}
            >
              <ul>
                {names.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </UncontrolledTooltip>
          </div>
        </>
      ) : null}
    </div>
  );
}

export const useColumnsStudentFileList = () => {
  const prefix = 'studentFile.columns';
  const { isAuthorizedTo } = useUserState();
  const history = useHistory();
  const { t } = useTranslation();

  const updateStudent = useCallback(
    (postulantId: number) => {
      history.push(`/student-file/update/${postulantId}`);
    },
    [history],
  );

  const columns: ColumnTable<any>[] = useMemo(() => {
    return [
      {
        columnName: 'id',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.id`),
        cellFormat: ({ row }) => row.id,
      },
      {
        columnName: 'name',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.name`),
        cellFormat: ({ row }) => row.name,
      },
      {
        columnName: 'studyPlans',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.studyPlan`),
        cellFormat: ({ row }) => Tooltip(row.studyPlans),
      },
      {
        columnName: 'schools',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.school`),
        cellFormat: ({ row }) => Tooltip(row.schools),
      },
      {
        columnName: 'campuses',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.campus`),
        cellFormat: ({ row }) => Tooltip(row.campuses),
      },
      {
        columnName: 'email',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.email`),
        cellFormat: ({ row }) => row.email,
      },
      {
        columnName: 'createdAt',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`${prefix}.createdAt`),
        cellFormat: ({ row }) => dayjs(row.createdAt).format('DD/MM/YYYY'),
      },
      {
        columnName: 'actions',
        headerText: t(`${prefix}.action`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: ({ row }) => (
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              style={{ maxWidth: 120 }}
              text={t('studentFile.actions.edit')}
              size="sm"
              onClick={() => updateStudent(row?.postulantId)}
              disabled={!isAuthorizedTo([PermissionName.CHANGE_STUDENT_STATUS])}
            />
          </div>
        ),
      },
    ];
  }, [isAuthorizedTo, t, updateStudent]);

  return columns;
};
