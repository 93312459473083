import { AccountType } from './account';
import { PaginationRequestType } from './paginationRequestType';

export enum STUDENT_STATUS {
  REGULAR = 'regular',
  INSTITUTIONAL_RETRACTED = 'retractado_institucional',
  WITHDRAWN_STUDY_PLAN = 'retractado_del_plan_de_estudio',
  SUSPENDED = 'suspendido',
  CANCELED = 'anulado',
  RESIGNED = 'renunciado',
  EXPELLED = 'expulsado',
  POSTPONED = 'postergado',
  ABANDON = 'abandono',
  REINCORPORATED = 'reincorporado',
  GRADUATE = 'egresado',
  TITLED = 'titulado',
}

export type StudentStatusListResponse =
  PaginationRequestType<StudentStatusListType>;

export type StudentStatusListType = {
  studyPlanEnrollmentId: number;
  studentId: number;
  account: AccountType;
  studyPlan: {
    id: number;
    name: string;
    schedule: string;
    campus: string;
    semesterAdmission: string;
    yearAdmission: number;
  };
  status: string;
  reincorporated?: {
    date: string;
    status: string;
  };
};

export type StudentStatusRequest = {
  data: {
    id: number;
    startDate: string;
    endDate: string;
    statusNow: string;
    fileRequiredId: number;
    accountId: number;
    studyPlanEnrollmentId: number;
  };
  file: {
    id: number;
    name: string;
    extension: string;
    type: string;
    uploadedById: number;
    url: string;
  };
};
export interface StudenStatusOptionsResponse {
  status: string[];
  campuses: { id: number; name: string }[];
}
