import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { PathsLayouts } from '../../config/routes';
import DownloadDocumentButton from '../../components/buttons/DownloadDocumentButton';
import { SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT } from '../../config/constants';
import PeriodView from '../../sectionAttributes/PeriodView';
import GoBackButton from '../../components/buttons/GoBackButton';

const sectionAttributesStructureFileName =
  SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT;
const sectionAttributesStructureFileURL = `/tenant/${SECTIONS_ATTRIBUTES_UPLOAD_STRUCTURE_DOCUMENT}`;

const SectionAttributesView = (): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'sectionAttributes';
  const { periodId } = useParams<{ periodId?: string }>();
  const history = useHistory();

  function goBack() {
    history.push(PathsLayouts.sectionAttributes);
  }

  if (!periodId || isNaN(parseInt(periodId))) {
    return <Redirect to={PathsLayouts.sectionAttributes} />;
  }

  return (
    <div className="g-table-container mt-2 pb-4 pt-4 mx-3">
      <Row className="mb-5">
        <Col lg={7} xl={7}>
          <div className="mb-4">
            <GoBackButton onClick={goBack} />
          </div>
          <div className="fs-16">{t(`${prefix}.titleDesc`)}</div>
        </Col>
        <Col lg={5} xl={5}>
          <DownloadDocumentButton
            buttonText={t(`${prefix}.downloadExcelLayout`)}
            userFileName={sectionAttributesStructureFileName}
            fileURL={sectionAttributesStructureFileURL}
            outlined
          />
        </Col>
      </Row>
      <PeriodView periodId={parseInt(periodId)} />
    </div>
  );
};

export default SectionAttributesView;
