import { useMemo } from 'react';
import Tuition from './tuition/Tuition';
import Tariff from './tariff/Tariff';
import Discount from './discount/Discount';
import { PathsLayouts } from '../../config/routes';
import TabControl from '../../components/tabs/TabControl';

export default function TariffAndTuition() {
  const tabsPrefix = 'tariffAndTuition.tabs';
  const tabs = useMemo(
    () => [
      { name: 'tuitions', content: <Tuition /> },
      { name: 'tariff', content: <Tariff /> },
      { name: 'discounts', content: <Discount /> },
    ],
    [],
  );

  return (
    <>
      <TabControl
        tabsPrefix={tabsPrefix}
        tabs={tabs}
        path={PathsLayouts.tariffAndTuiton}
      />
    </>
  );
}
