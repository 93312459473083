import { Button, Select, TextInput, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Form, Row } from 'reactstrap';
import { saveAcademicOffersRequest } from '../../../../api/requests/academicOffers';
import GoBackButton from '../../../../components/buttons/GoBackButton';
import { SectionTitle } from '../../../../components/text';
import { useValidations } from '../../../../hooks/useValidations';
import { AcademicOffersTypeDuplicate } from '../../../../types/AcademicOffersType';
import AcademicOffersListDuplicate from '../AcademicOffersListDuplicate';
import { AcademicOffersValues } from '../interfaces/academic-offer.interface';
import AcademicOffersModal from '../modals/AcademicOffersModal';
import { GeneralEventType, VacantOfferFormDataResponse } from '../types';

const getModalTranslationsKeys = (key: string | null) => {
  if (key === 'offer' || key === 'period') {
    return {
      period: {
        title: 'academicOffers.form.periodModalTitle',
        subtitle: 'academicOffers.form.periodModalSubtitle',
      },
      offer: {
        title: 'academicOffers.form.offerModalTitle',
        subtitle: 'academicOffers.form.offerModalSubtitle',
      },
    }[key];
  }

  return { title: '', subtitle: '' };
};

const prefix = 'academicOffers.form';
const prefixDuplicate = 'academicOffers.duplicate';

interface AcademicOffersCreateProps {
  data: VacantOfferFormDataResponse;
  refresh: () => void;
}

export default function AcademicOffersCreateForm(
  props: AcademicOffersCreateProps,
) {
  const { data } = props;
  const { periods } = data;
  const history = useHistory();
  const { t } = useTranslation();

  const [modalToShow, setModalToShow] = useState<string | null>(null);

  // obtener el row de la tabla
  const [selectedRow, setSelectedRow] =
    useState<AcademicOffersTypeDuplicate | null>(null);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const methods = useForm<AcademicOffersValues>({
    mode: 'all',
    defaultValues: {
      period: null,
      places: null,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
  } = methods;

  const [places, period] = watch(['places', 'period']);

  const { msgValidations, validateTextNotEmpty } = useValidations();

  const onSubmit = useCallback(async () => {
    setModalToShow(!!selectedRow ? 'period' : 'offer');
    return null;
  }, [selectedRow]);

  const validateNoSuaEvent = (value: any) => {
    if (value?.eventId !== GeneralEventType.TUITION_INITIAL_WITHOUT_SUA) {
      return t(`${prefix}.noSuaEventNotFound`);
    }
  };

  // enviar formulario depende de la confirmacion
  const sendForm = useCallback(async () => {
    if (confirmed) {
      const response = await saveAcademicOffersRequest({
        periodId: period ? Number(period.value) : null,
        duplicatedAcademicOfferId: selectedRow ? selectedRow.id : null,
        quota: Number(places),
        status: 'Borrador',
      });

      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });

        setConfirmed(false);
        setModalToShow(null);
        history.push(`/academic-offers/record/${response.data.id}`);
      } else {
        setConfirmed(false);
        setModalToShow(null);
        return response.error.code;
      }
      return null;
    }
  }, [confirmed, places, period, selectedRow, t, history]);

  useEffect(() => {
    sendForm();
  }, [confirmed, sendForm]);

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={() => history.push('/academic-offers')} />
        </div>
        <Row>
          <Col xs={12}>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12}>
                    <SectionTitle text={t(`${prefix}.step1Title`)} />
                    <div className="fs-18 mb-5">
                      {t(`${prefix}.step1Subtitle`)}
                    </div>
                    <Row>
                      <Col xs={12} md={6} className="pb-3">
                        <Select
                          name="period"
                          label={t(`${prefix}.selectPeriod`)}
                          options={periods.map((e) => ({
                            label: e.name,
                            value: e.id,
                            eventId:
                              e.academicCalendars?.[0]?.eventCalendar
                                ?.generalType?.id,
                          }))}
                          control={control}
                          rules={{
                            validate: {
                              required: validateTextNotEmpty,
                              validateNoSuaEvent: validateNoSuaEvent,
                            },
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} className="pb-3">
                        <TextInput
                          name="places"
                          label={t(`${prefix}.newTotalPlaces`)}
                          control={control}
                          formatter={(value) => {
                            const newValue = value.replace(/\D/g, '');

                            if (Number(newValue) < 1) {
                              return '';
                            }
                            return newValue;
                          }}
                          rules={{
                            required: msgValidations.required,
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col xs={12}>
                    <SectionTitle text={t(`${prefixDuplicate}.title`)} />
                    <div className="fs-18 mb-5">
                      {t(`${prefixDuplicate}.subTitle`)}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <AcademicOffersListDuplicate
                      periodId={period?.value}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  </Col>
                </Row>
                <Row className="pt-5">
                  <Col
                    xs={{ order: 2, size: 12 }}
                    md={{ order: 1, size: 4 }}
                    className="ml-auto"
                  >
                    <div>
                      <Button
                        type="button"
                        text={t(`common.actions.cancel`)}
                        outlined
                        onClick={() => history.push('/academic-offers')}
                        fullwidth
                      />
                    </div>
                  </Col>
                  <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
                    <div className="pb-3 pb-md-0">
                      <Button
                        type="submit"
                        text={t('common.actions.create')}
                        disabled={!period?.value}
                        loading={isSubmitting}
                        fullwidth
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
            <AcademicOffersModal
              show={!!modalToShow}
              onCloseModal={() => setModalToShow(null)}
              onConfirm={() => setConfirmed(true)}
              title={t(getModalTranslationsKeys(modalToShow).title)}
              subtitle={t(getModalTranslationsKeys(modalToShow).subtitle)}
            />
          </Col>
        </Row>
      </Card>
    </Container>
  );
}
