import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadTcDocumentReviewDocumentsOthers } from '../../../../api/requests/tuitionContinuityDocumentReviewDocumentsOthers';
import UploadModal from '../../../../components/modals/UploadModal';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';
import { showToast } from '../../../../utils/toast';

export interface TuitionContinuityDocumentReviewDocumentsOthersModalUploadProps {
  tcProcessId: string;
  open: boolean;
  onConfirm: (data: StudentDocumentReviewInfDocumentOther) => void;
  onClose: () => void;
}

export default function TuitionContinuityDocumentReviewDocumentsOthersModalUpload({
  tcProcessId,
  open,
  onConfirm,
  onClose,
}: TuitionContinuityDocumentReviewDocumentsOthersModalUploadProps) {
  const { t } = useTranslation();
  const prefix =
    'tuitionContinuityDocumentReviewStudent.documentOthers.modalUpload';
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      upload: t(`${prefix}.upload`),
      label: {
        file: t(`${prefix}.label.file`),
        description: t(`${prefix}.label.description`),
      },
      error: {
        fileRequired: t(`${prefix}.error.fileRequired`),
        descriptionRequired: t(`${prefix}.error.descriptionRequired`),
      },
    }),
    [t],
  );

  const submitFunction = useCallback(
    async (values) => {
      let { error, data } = await uploadTcDocumentReviewDocumentsOthers(
        tcProcessId,
        values,
      );
      if (error) {
        showToast(true, t);
      } else if (data) {
        onConfirm(data);
        onClose();
      }
    },
    [tcProcessId, t, onConfirm, onClose],
  );

  return (
    <UploadModal
      isOpen={open}
      onClose={onClose}
      submitFunction={submitFunction}
      title={texts.title}
      subtitle={texts.body}
    >
      <UploadModal.Input
        name="file"
        label={texts.label.file}
        accept="image/png, image/jpeg, application/pdf, application/xls, application/xlsx, application/docx"
        btnText={texts.upload}
        requiredMsgError={texts.error.fileRequired}
      />
      <UploadModal.TextArea
        name="description"
        label={texts.label.description}
        height={100}
        requiredMsgError={texts.error.descriptionRequired}
      />
    </UploadModal>
  );
}
