import { DetailBox } from '@octano/global-ui';
import { useDetailBoxColumns } from './useDetailBoxColumns';

export interface SectionBoxProps {
  section: {
    name: string;
    course?: { shortening: string; name: string; school?: { name: string } };
  };
}

export default function SectionBox(props: SectionBoxProps) {
  const detailColumns = useDetailBoxColumns(props.section);
  return <DetailBox columns={detailColumns} />;
}
