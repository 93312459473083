import { Campus } from '../../types/campus';
import {
  AsignPackageToSectionBody,
  CourseListResponseType,
  CreateSectionBody,
  PeriodListResponseType,
  SchoolType,
  SectionDetail,
  SectionMaintainerListResponse,
  StudyPlanListResponseType,
  TeacherListResponseType,
} from '../../types/sectionMaintainerTypes';
import { SECTION_MAINTAINER } from '../endpoints';
import request from '../request';

/**
 * Obtiene listado de las secciones
 */
export const requestSectionsMaintainerList = (data: {
  items_per_page: number;
  page: number;
  periodId?: string | number;
  schoolId?: string | number;
  studyPlanId?: string | number;
  campusId?: string | number;
  courseNameCode?: string | null;
  profesorId?: string | number;
}) => {
  return request<SectionMaintainerListResponse>(`${SECTION_MAINTAINER.LIST}`, {
    params: data,
  });
};

/**
 * Obtiene el listado de todos los periodos para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getPeriodListRequest = () => {
  return request<PeriodListResponseType>(SECTION_MAINTAINER.PERIOD_LIST);
};

/**
 * Obtiene el listado de todas las facultades para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getSchoolListRequest = () => {
  return request<SchoolType[]>(SECTION_MAINTAINER.SCHOOL_LIST);
};

/**
 * Obtiene el listado de todos los planes de estudio para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getStudyPlanListRequest = () => {
  return request<StudyPlanListResponseType>(SECTION_MAINTAINER.STUDY_PLAN_LIST);
};

/**
 * Obtiene el listado de todos los campus para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getCampusListRequest = () => {
  return request<Campus[]>(SECTION_MAINTAINER.CAMPUS_LIST);
};

/**
 * Obtiene el listado de todos los profesores para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getTeacherListRequest = () => {
  return request<TeacherListResponseType>(SECTION_MAINTAINER.TEACHER_LIST);
};

/**
 * Obtiene el listado de todos los cursos (filtrado opcional) para llenar el selector del filtro en el mantenedor de secciones
 * @returns
 */
export const getCoursesListRequest = (school?: number) => {
  const faculty = school ? `?schoolId=${school}` : '';

  return request<CourseListResponseType>(
    SECTION_MAINTAINER.COURSES_LIST + faculty,
  );
};

/**
 * Crea una sección nueva
 * @returns
 */
export const createSectionRequest = (data: CreateSectionBody) => {
  return request<any>(SECTION_MAINTAINER.CREATE_SECTION, {
    method: 'POST',
    data,
  });
};

/**
 * Asigna Paquetes a una sección
 * @returns
 */
export const asignPackageToSectionRequest = (
  sectionId: string | number,
  data: AsignPackageToSectionBody,
) => {
  return request<any>(SECTION_MAINTAINER.ASIGN_PACKAGE_TO_SECTION(sectionId), {
    method: 'PUT',
    data,
  });
};

/**
 * Obtiene el detalle de una sección
 * @param sectionId
 * @returns
 */
export const requestSectionInfo = (sectionId: number) => {
  return request<{ data: SectionDetail }>(
    SECTION_MAINTAINER.GET_SECTION(sectionId),
  );
};
