import { useMemo, useState } from 'react';
import clsx from 'clsx';

export interface CourseProps {
  name: string;
  code?: string;
  credits?: number | string;
  texts: {
    credits: string;
  };
  color?: string;
  onClick?: () => void;
}

export default function Course({
  name,
  code,
  credits,
  texts,
  color,
  onClick,
}: CourseProps) {
  const [hovered, setHovered] = useState(false);
  const { onMouseEnter, onMouseLeave } = useMemo(() => {
    if (!onClick) {
      return {
        onMouseEnter: undefined,
        onMouseLeave: undefined,
      };
    }
    return {
      onMouseEnter() {
        setHovered(true);
      },
      onMouseLeave() {
        setHovered(false);
      },
    };
  }, [onClick]);

  return (
    <div
      className={clsx('border-gray-100', { 'cursor-pointer': hovered })}
      style={{
        width: '100px',
        maxWidth: '120px',
        minHeight: '100px',
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '5px 10px',
        borderRadius: '4px',
        backgroundColor: color,
        ...(hovered
          ? {
              boxShadow: '0px 6px 20px #0056A33B',
              //filter: 'drop-shadow(0px 6px 20px #0056A33B)',
            }
          : undefined),
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="course-name text-color-dark"
        title={name}
        style={{
          height: '45px',
          overflow: 'hidden',
          display: '-webkit-box',
          boxOrient: 'vertical',
          lineClamp: 3,
          fontSize: '12px',
          lineHeight: '15px',
          marginBottom: '10px',
        }}
      >
        {name}
      </div>

      {code && <div className="fs-10 course-detail pb-0">{code}</div>}
      <div className="fs-10 course-detail">
        {texts.credits}: {credits}
      </div>
    </div>
  );
}
