import { addToast, Button, Icon } from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { getTcDocumentReviewDocumentsOthersUrl } from '../../../../api/requests/tuitionContinuityDocumentReviewDocumentsOthers';
import { ModalConfirmation } from '../../../../components/modals/Confirmation';
import { StudentDocumentReviewInfDocumentOther } from '../../../../types/tuitionContinuityDocumentReviewDocuments';
import TuitionContinuityDocumentReviewDocumentsOthersModalUpload from './TuitionContinuityDocumentReviewDocumentsOthersModalUpload';
import { TuitionContinuityDocumentReviewDocumentsOtherTable } from './TuitionContinuityDocumentReviewDocumentsOthersTable';
import { useOnDelete } from './useOnDelete';
import { useOnUpload } from './useOnUpload';

export function TuitionContinuityDocumentReviewDocumentsOthers({
  data: dataProp,
  tcProcessId,
}: {
  tcProcessId: string;
  data: StudentDocumentReviewInfDocumentOther[];
}) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityDocumentReviewStudent.documentOthers';
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      tooltip: t(`${prefix}.tooltip`),
      add: t(`${prefix}.add`),
      downloadError: t(`${prefix}.downloadError`),
      deleteError: t(`${prefix}.deleteError`),
      confirm: t<string, Record<'title' | 'subtitle', string>>(
        `${prefix}.deleteConfirm`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const [data, setData] = useState(dataProp);

  const onDownloadError = useCallback(() => {
    addToast({
      icon: 'error',
      color: 'danger',
      text: texts.downloadError,
    });
  }, [texts]);

  const onDownload = useCallback(
    async (documentId: number | string) => {
      let { data, error } = await getTcDocumentReviewDocumentsOthersUrl(
        documentId,
      );
      if (error) {
        onDownloadError();
      } else if (data) {
        let link = document.createElement('a');
        link.download = data.name;
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [onDownloadError],
  );

  const onDeleteConfirm = useCallback(
    (row: StudentDocumentReviewInfDocumentOther) => {
      setData((d) => {
        const index = d.findIndex((document) => document === row);
        const newData = [...d];
        newData.splice(index, 1);
        return newData;
      });
    },
    [],
  );

  const onDeleteError = useCallback(
    (row: StudentDocumentReviewInfDocumentOther) => {
      setData((d) => {
        const index = d.findIndex((document) => document.id === row.id);
        const newData = [...d];
        newData.splice(index, 1, row);
        return newData;
      });
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.deleteError,
      });
    },
    [texts],
  );

  const { data: modalConfirm, onDelete } = useOnDelete({
    onConfirm: onDeleteConfirm,
    onError: onDeleteError,
  });

  const onUploadConfirm = useCallback(
    async (row: StudentDocumentReviewInfDocumentOther) => {
      setData((d) => {
        return [...d, row];
      });
    },
    [],
  );
  const { data: modalAddDocument, onUpload } = useOnUpload({
    onConfirm: onUploadConfirm,
  });

  const ref = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = useCallback(() => {
    setTooltipOpen((t) => !t);
  }, []);

  return (
    <>
      <div
        className="border-primary px-5 py-4"
        style={{
          borderRadius: 8,
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <div className="d-flex align-items-center mb-4" style={{ gap: '6px' }}>
          <h6 className="d-inline-block text-primary fs-20 fw-600 y mb-0">
            {texts.title}
          </h6>
          <span ref={ref}>
            <Icon name="information" color="primary" size="20px" />
          </span>
        </div>

        <Tooltip
          placement="top"
          target={ref}
          toggle={toggle}
          isOpen={tooltipOpen}
          fade={false}
        >
          {texts.tooltip}
        </Tooltip>
        <div className={clsx({ 'mb-4': Boolean(data.length) })}>
          <Button
            color="primary"
            type="button"
            icon="plus"
            text={texts.add}
            onClick={onUpload}
          />
        </div>
        {Boolean(data.length) && (
          <TuitionContinuityDocumentReviewDocumentsOtherTable
            data={data}
            onDelete={onDelete}
            onDownload={onDownload}
          />
        )}
      </div>
      {modalConfirm.open && (
        <ModalConfirmation
          icon="information"
          open={modalConfirm.open}
          onClose={modalConfirm.onClose}
          onConfirm={modalConfirm.onConfirm}
          title={texts.confirm.title}
          subtitle={texts.confirm.subtitle}
        />
      )}
      {modalAddDocument.open && (
        <TuitionContinuityDocumentReviewDocumentsOthersModalUpload
          open={modalAddDocument.open}
          tcProcessId={tcProcessId}
          onConfirm={modalAddDocument.onConfirm}
          onClose={modalAddDocument.onClose}
        />
      )}
    </>
  );
}
