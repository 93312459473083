import { PaginationType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import DisplayError from '../../../../components/info/DisplayError';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { StudentStatusListType } from '../../../../types/studentStatusTypes';
import { getPostulants } from '../api';
import { StudenFileProps, StudenFileSearchFiltersType } from '../type';
import SearchFilters from './../parts/SearchFilters';
import { useColumnsStudentFileList } from '../hooks/useColumnsStudentFileList';

const ITEMS_PER_PAGE = 10;

const StudentFile = (props: StudenFileProps) => {
  const { campusOptions, schoolOptions, studyPlanOptions } = props;
  const prefix = 'studentStatus';
  const { t } = useTranslation();
  const [dataList, setDataList] = useState<StudentStatusListType[]>([]);
  const [pagination, setPagination] = useState<PaginationType>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] =
    useState<StudenFileSearchFiltersType>();

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState(false);

  const columns = useColumnsStudentFileList();

  const getData = useCallback(
    async (page: number) => {
      setLoading(true);
      setCurrentPage(page);

      const { data, error } = await getPostulants({
        items_per_page: ITEMS_PER_PAGE,
        page: page - 1,
        campusId: searchFilters?.campusId?.value
          ? searchFilters.campusId.value.toString()
          : null,
        schoolId: searchFilters?.schoolId?.value
          ? searchFilters.schoolId.value.toString()
          : null,
        studyPlanId: searchFilters?.studyPlanId?.value
          ? searchFilters.studyPlanId.value.toString()
          : null,
        nameOrId: searchFilters?.nameOrId,
      });

      if (data) {
        setDataList(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: ITEMS_PER_PAGE,
          totalPages: data.total_pages,
          currentPage: page,
          onChangePage: (pageNumber: number) => getData(pageNumber),
        });
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [setLoading, setErrorLoading, searchFilters],
  );

  useEffect(() => {
    if (searchFilters) {
      getData(1);
    }
  }, [searchFilters, getData]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading ?? ''}
          retryAction={() => {
            getData(currentPage);
            setErrorLoading(undefined);
          }}
          loadingAction={loading}
        />
      </div>
    );
  }
  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12}>
          <h1 className="fs-22 text-primary fw-700">{t(`${prefix}.title`)}</h1>
          <SearchFilters
            onSearch={(filters: StudenFileSearchFiltersType) =>
              setSearchFilters(filters)
            }
            campusOptions={campusOptions}
            schoolOptions={schoolOptions}
            studyPlanOptions={studyPlanOptions}
          />
        </Col>
        <Col xs={12}>
          <Table<any>
            columns={columns}
            data={dataList}
            pagination={pagination}
            isLoadingResults={loading}
            noResultsText={
              <TableEmptyContent
                title={t(
                  `${prefix}.${
                    searchFilters ? 'noResultsTitle' : 'noSearchTitle'
                  }`,
                )}
                subtitle={t(
                  `${prefix}.${
                    searchFilters ? 'noResultsBody' : 'noSearchBody'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default StudentFile;
