import { CellFormatOptions, ColumnTable, Icon, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { ProgressCurrentPeriod } from '../types/progressCurrentPeriod';

export const ITEMS_PER_PAGE_DEFAULT = 10;
const NO_HAVE_DATA = '-';

interface ProgressByCurrentPeriodProps {
  progressCurrentPeriod: ProgressCurrentPeriod[];
  studyPlanEnrollmentId: number;
  texts: {
    table: {
      columns: {
        section: string;
        courseCode: string;
        generalPassedAverage: string;
        averageGradeToDate: string;
        progressPorcentInGrades: string;
        averageAttendanceToDate: string;
        attendanceCatedral: string;
        attendanceLaboratory: string;
        attendanceAssistantship: string;
      };
      tooltips: {
        attendanceCatedral: string;
        attendanceLaboratory: string;
        attendanceAssistantship: string;
      };
      notResult: {
        title: string;
        subtitle: string;
      };
    };
  };
}

export default function ProgressByCurrentPeriod({
  progressCurrentPeriod: progressAttendance,
  studyPlanEnrollmentId,
  texts,
}: ProgressByCurrentPeriodProps) {
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    setTableData(progressAttendance);
    setTotalItems(1);
    setTotalPages(
      Math.ceil(progressAttendance.length / ITEMS_PER_PAGE_DEFAULT),
    );
    setCurrentPage(1);
  }, [progressAttendance]);

  const tableDataSlice = useMemo(() => {
    return tableData.slice(
      ITEMS_PER_PAGE_DEFAULT * (currentPage - 1),
      ITEMS_PER_PAGE_DEFAULT * currentPage,
    );
  }, [tableData, currentPage]);

  const toolTip = useCallback((name: string, text: string) => {
    return (
      <div className="ml-3" id={`inputTooltip_${name}`}>
        <Icon name="information_input" size="1rem" />
        <UncontrolledTooltip
          placement="bottom"
          target={`inputTooltip_${name}`}
          fade={false}
        >
          {text}
        </UncontrolledTooltip>
      </div>
    );
  }, []);

  const customHeader = useCallback(
    (
      name:
        | 'attendanceCatedral'
        | 'attendanceLaboratory'
        | 'attendanceAssistantship',
    ) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {texts.table.columns[name]}
          {toolTip(name, texts.table.tooltips[name])}
        </div>
      );
    },
    [texts.table.columns, texts.table.tooltips, toolTip],
  );

  const defaultColumns = useMemo<ColumnTable[]>(
    () => [
      {
        columnName: 'sectionName',
        headerText: texts.table.columns.section,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) =>
          options.row?.sectionName,
      },
      {
        columnName: 'courseCode',
        headerText: texts.table.columns.courseCode,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          if (!options.row?.courseCode) {
            return NO_HAVE_DATA;
          }

          return <>{options.row.courseCode}</>;
        },
      },
      {
        columnName: 'averageGradeToDate',
        width: '10%',
        headerText: texts.table.columns.averageGradeToDate,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        tdProps: ({ row }: { row: ProgressCurrentPeriod }) => {
          if (!row.gradeByStudent) {
            return {};
          }
          if (typeof row.gradeByStudent.finalGrade === 'number') {
            const color =
              row.gradeByStudent.finalGrade >= row.gradeByStudent.passingGrade
                ? 'blue'
                : 'red';
            return { style: { color } };
          }
          return {};
        },
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { gradeByStudent } = options.row;
          if (!gradeByStudent || gradeByStudent.finalGrade === null) {
            return NO_HAVE_DATA;
          }
          return gradeByStudent.finalGrade;
        },
      },
      {
        columnName: 'progressPorcentInGrades',
        width: '10%',
        headerText: texts.table.columns.progressPorcentInGrades,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { gradeByStudent } = options.row;
          if (!gradeByStudent) {
            return NO_HAVE_DATA;
          }

          return <>{gradeByStudent.generalGradePercentage ?? 0}%</>;
        },
      },
      {
        columnName: 'averageAttendanceToDate',
        width: '10%',
        headerText: texts.table.columns.averageAttendanceToDate,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { attendanceByStudent } = options.row;
          if (!attendanceByStudent) {
            return NO_HAVE_DATA;
          }
          if (attendanceByStudent.attendanceGeneral === null) {
            return NO_HAVE_DATA;
          }
          return <>{attendanceByStudent.attendanceGeneral}%</>;
        },
      },
      {
        columnName: 'attendanceCatedral',
        headerText: customHeader('attendanceCatedral'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        tdProps: ({ row }: { row: ProgressCurrentPeriod }) => {
          const { attendanceByStudent } = row;
          if (!attendanceByStudent) {
            return {};
          }

          const { attendanceCatedral } = attendanceByStudent;
          if (
            attendanceCatedral.percentage === null &&
            attendanceCatedral.minPercentage === null
          ) {
            return {};
          }

          if (attendanceCatedral) {
            const color = attendanceCatedral.passed ? 'blue' : 'red';
            return { style: { color } };
          }

          return {};
        },
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { attendanceByStudent } = options.row;
          if (!attendanceByStudent) {
            return NO_HAVE_DATA;
          }
          const { attendanceCatedral } = attendanceByStudent;
          if (attendanceCatedral.percentage === null) {
            if (attendanceCatedral.minPercentage === null) {
              return 'N/A';
            } else {
              return (
                <>
                  {NO_HAVE_DATA} ({attendanceCatedral.minPercentage}% )
                </>
              );
            }
          }
          return (
            <>
              {attendanceCatedral.percentage}% (
              {attendanceCatedral.minPercentage}% )
            </>
          );
        },
      },
      {
        columnName: 'attendanceLaboratory',
        headerText: customHeader('attendanceLaboratory'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        tdProps: ({ row }: { row: ProgressCurrentPeriod }) => {
          const { attendanceByStudent } = row;
          if (!attendanceByStudent) {
            return {};
          }

          const { attendanceLaboratory } = attendanceByStudent;
          if (
            attendanceLaboratory.percentage === null &&
            attendanceLaboratory.minPercentage === null
          ) {
            return {};
          }

          if (attendanceLaboratory) {
            const color = attendanceLaboratory.passed ? 'blue' : 'red';
            return { style: { color } };
          }
          return {};
        },
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { attendanceByStudent } = options.row;
          if (!attendanceByStudent) {
            return NO_HAVE_DATA;
          }
          const { attendanceLaboratory } = attendanceByStudent;
          if (attendanceLaboratory.percentage === null) {
            if (attendanceLaboratory.minPercentage === null) {
              return 'N/A';
            } else {
              return (
                <>
                  {NO_HAVE_DATA} ({attendanceLaboratory.minPercentage}% )
                </>
              );
            }
          }
          return (
            <>
              {attendanceLaboratory.percentage}% (
              {attendanceLaboratory.minPercentage}% )
            </>
          );
        },
      },
      {
        columnName: 'attendanceAssistantship',
        headerText: customHeader('attendanceAssistantship'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        tdProps: ({ row }: { row: ProgressCurrentPeriod }) => {
          const { attendanceByStudent } = row;
          if (!attendanceByStudent) {
            return {};
          }

          const { attendanceAssistantship } = attendanceByStudent;
          if (
            attendanceAssistantship.percentage === null &&
            attendanceAssistantship.minPercentage === null
          ) {
            return {};
          }

          if (attendanceAssistantship) {
            const color = attendanceAssistantship.passed ? 'blue' : 'red';
            return { style: { color } };
          }
          return {};
        },
        cellFormat: (options: CellFormatOptions<ProgressCurrentPeriod>) => {
          const { attendanceByStudent } = options.row;
          if (!attendanceByStudent) {
            return NO_HAVE_DATA;
          }
          const { attendanceAssistantship } = attendanceByStudent;
          if (attendanceAssistantship.percentage === null) {
            if (attendanceAssistantship.minPercentage === null) {
              return 'N/A';
            } else {
              return (
                <>
                  {NO_HAVE_DATA} ({attendanceAssistantship.minPercentage}% )
                </>
              );
            }
          }
          return (
            <>
              {attendanceAssistantship.percentage}% (
              {attendanceAssistantship.minPercentage}% )
            </>
          );
        },
      },
    ],
    [
      customHeader,
      texts.table.columns.averageAttendanceToDate,
      texts.table.columns.averageGradeToDate,
      texts.table.columns.courseCode,
      texts.table.columns.progressPorcentInGrades,
      texts.table.columns.section,
    ],
  );

  const noResultsText = useMemo(() => {
    return (
      <TableEmptyContent
        title={texts.table.notResult.title}
        subtitle={texts.table.notResult.subtitle}
      />
    );
  }, [texts]);

  const columns = useMemo<ColumnTable[]>(() => {
    const array = defaultColumns;
    return array;
  }, [defaultColumns]);

  return (
    <Table
      columns={columns}
      data={tableDataSlice}
      pagination={{
        currentPage,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalItems,
        totalPages,
        onChangePage: (page) => setCurrentPage(page),
      }}
      noResultsText={noResultsText}
    />
  );
}
