import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SECTIONS_AND_PACAGES_UPLOAD_STRUCTURE_DOCUMENT } from '../../config/constants';
import PeriodsList from '../../packagesMaintainer/PeriodsList';
import DownloadDocumentButton from '../../components/buttons/DownloadDocumentButton';

const sectionAttributesStructureFileName =
  SECTIONS_AND_PACAGES_UPLOAD_STRUCTURE_DOCUMENT;
const sectionAttributesStructureFileURL = `/tenant/${SECTIONS_AND_PACAGES_UPLOAD_STRUCTURE_DOCUMENT}`;

const SectionsAndPackagesList = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="g-table-container mt-2 pb-4 pt-4 mx-3">
      <Row className="mb-5">
        <Col lg={7} xl={7}>
          <div className="fs-16">{t('packagesMaintainer.titleDesc')}</div>
        </Col>
        <Col lg={5} xl={5}>
          <DownloadDocumentButton
            buttonText={t(`packagesMaintainer.downloadExcelLayout`)}
            userFileName={sectionAttributesStructureFileName}
            fileURL={sectionAttributesStructureFileURL}
            outlined
          />
        </Col>
      </Row>
      <PeriodsList />
    </div>
  );
};

export default SectionsAndPackagesList;
