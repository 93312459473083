import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format as formatRUT } from 'rut.js';
import { ColumnTable, Collapse } from '@octano/global-ui';
import { StudentSummaryTable } from '.';
import { MappedSearchResults } from '../helpers';

type RowData = MappedSearchResults[number]['courseEnrollments'][number];
interface Params {
  onRowEdit?: (courseEnrollmentId: RowData) => void;
}
const prefix = `studentGradesClosure.search.studentsTable`;

export function useStudentsTableColumns({ onRowEdit }: Params) {
  const { t } = useTranslation();

  const COLUMNS: ColumnTable<MappedSearchResults[number]>[] = useMemo(
    () => [
      {
        headerText: t(`${prefix}.nameAndId`),
        columnName: '',
        thClassName: 'text-center',
        cellFormat: (options) => {
          const { student, courseEnrollments } = options.row;
          const id = student.run
            ? formatRUT(student.run)
            : student.passportNumber;

          return (
            <Collapse title={`${student.fullName} - ${id}`}>
              <StudentSummaryTable
                summary={courseEnrollments}
                onRecordEdit={onRowEdit}
              />
            </Collapse>
          );
        },
      },
    ],
    [onRowEdit, t],
  );

  return COLUMNS;
}
