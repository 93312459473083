import { OutlinedSelectOptionType } from '@octano/global-ui';
import { PaginationRequestType } from './paginationRequestType';

export interface TcDocumentReviewResponse {
  enrolled: number;
  pending: number;
  passed: number;
  withoutReview: number;
  studyPlanVersionName: string;
  studyPlanVersionOfferId: number;
  campusName: string;
  campusId: number;
  schoolName: string;
  schoolId: number;
  scheduleName: string;
}

export interface TcDocumentReviewPeriodSelect {
  current: OutlinedSelectOptionType | undefined;
  periods: OutlinedSelectOptionType[];
}

export interface TcDocumentReviewBoxDetailInf {
  studyPlanVersionName: string;

  campusName: string;

  scheduleName: string;
}

export enum TuitionContinuityDocumentStatusEnum {
  NoReview = 'sin_revision',
  Standby = 'en_espera',
  Approved = 'aprobado',
}

export interface TcDocumentReviewStudentTabResponse {
  campusName: string;
  scheduleName: string;
  students: PaginationRequestType<TcDocumentReviewStudentData>;
  studyPlanVersionName: string;
  studyPlanVersionOfferId: number;
}

export interface TcDocumentReviewStudentData {
  cellPhone: string;
  documentStatus: string;
  email: string;
  fullName: string;
  passportNumber: string | null;
  run: string | null;
  studentId: string | null;
  tcProcessId: number;
}
