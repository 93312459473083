import { Col, Row } from 'reactstrap';
import { useCallback, useEffect, useState } from 'react';
import { PackagesViewsSection } from '../../../types/packageTypes';
import { useLoadingState } from '../../../hooks/useLoadingState';
import {
  getPackageSections,
  getPackageTimeCalendar,
} from '../../../api/requests/packages';
import { TimeModuleType } from '../../../types/timeModuleTypes';
import PackagesViewSchedule from '../../../packages/parts/PackagesViewSchedule';
import SectionDetailTable from './SectionDetailTable';

type SectionScheduleProps = {
  packageId: number | undefined;
};

const SectionSchedule = ({ packageId }: SectionScheduleProps) => {
  const [sections, setSections] = useState<PackagesViewsSection[] | null>(null);
  const [timeModules, setTimeModules] = useState<TimeModuleType[]>([]);
  const { setLoading, setErrorLoading } = useLoadingState();

  const getPackageInfo = useCallback(
    async (pkgId: number) => {
      setLoading(true);
      const { data, error } = await getPackageSections(pkgId);

      if (data && data?.data) {
        setSections(data.data);
      }
      if (error) {
        setErrorLoading(error.code);
      }
      setLoading(false);
    },
    [setErrorLoading, setLoading],
  );

  const getTimeModules = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPackageTimeCalendar();

    if (data) {
      setTimeModules(data.data);
    }
    if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [setErrorLoading, setLoading]);

  useEffect(() => {
    if (packageId) {
      getPackageInfo(packageId);
    } else {
      setSections(null);
    }

    if (timeModules.length === 0) getTimeModules();
  }, [getPackageInfo, getTimeModules, timeModules, packageId]);

  return (
    <>
      <Row className="pb-5">
        <Col xs={12} md={5} style={{ zIndex: 0 }}>
          <SectionDetailTable sections={sections} />
        </Col>
        <Col xs={12} md={7}>
          <PackagesViewSchedule timeModules={timeModules} sections={sections} />
        </Col>
      </Row>
    </>
  );
};

export default SectionSchedule;
