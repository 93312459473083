import { useHistory } from 'react-router-dom';
import { Col, Nav, NavLink, Row } from 'reactstrap';

interface NavTabsProps {
  tabs: Array<{
    pathname: string;
    title: string;
  }>;
  pathname: string;
  className?: string;
  style?: object;
}

export function NavTabs(props: NavTabsProps) {
  const { tabs, pathname, className = '', style = {} } = props;
  const history = useHistory();
  function toggleTab(to: string) {
    history.push(to);
  }
  return (
    <Row className={className} style={style}>
      <Col>
        <div
          className="g-tabs-container"
          style={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
          <Nav
            className="g-tabs-admission-process"
            tabs
            style={{ marginBottom: '4px' }}
          >
            {tabs.map((tab) => (
              <NavLink
                key={tab.pathname}
                className={pathname.search(tab.pathname) !== -1 ? 'active' : ''}
                onClick={() => toggleTab(tab.pathname)}
              >
                {tab.title}
              </NavLink>
            ))}
          </Nav>
        </div>
      </Col>
    </Row>
  );
}
