import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadTcDocumentReviewDocuments } from '../../../api/requests/tuitionContinuityDocumentReviewDocuments';
import UploadModal from '../../../components/modals/UploadModal';
import { StudentDocumentReviewInfDocument } from '../../../types/tuitionContinuityDocumentReviewDocuments';
import { showToast } from '../../../utils/toast';

export interface TuitionContinuityDocumentReviewDocumentsModalUploadProps {
  tcProcessId: string;
  open: boolean;
  onConfirm: (data: StudentDocumentReviewInfDocument) => void;
  onClose: () => void;
  document: StudentDocumentReviewInfDocument;
}

export default function TuitionContinuityDocumentReviewDocumentsModalUpload({
  tcProcessId,
  open,
  onConfirm,
  onClose,
  document,
}: TuitionContinuityDocumentReviewDocumentsModalUploadProps) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityDocumentReviewStudent.uploadDocument';
  const texts = useMemo(
    () => ({
      title: t<string, Record<string, string>>(`${prefix}.title`, {
        returnObjects: true,
      }),
      body: t(`${prefix}.body`),
      upload: t(`${prefix}.upload`),
      label: {
        file: t(`${prefix}.label.file`),
      },
      error: {
        fileRequired: t(`${prefix}.error.fileRequired`),
      },
    }),
    [t],
  );

  const submitFunction = useCallback(
    async (values) => {
      let { error, data } = await uploadTcDocumentReviewDocuments(tcProcessId, {
        ...values,
        type: document.type,
      });
      if (error) {
        showToast(true, t);
      } else if (data) {
        onConfirm(data);
        onClose();
      }
    },
    [tcProcessId, document, t, onConfirm, onClose],
  );

  return (
    <UploadModal
      isOpen={open}
      onClose={onClose}
      submitFunction={submitFunction}
      title={texts.title[document.type]}
      subtitle={texts.body}
    >
      <UploadModal.Input
        name="file"
        label={texts.label.file}
        accept="image/png, image/jpeg, application/pdf, application/xls, application/xlsx, application/docx"
        btnText={texts.upload}
        requiredMsgError={texts.error.fileRequired}
      />
    </UploadModal>
  );
}
