import { Button, Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { CONTACT_EMAIL } from '../../../config/constants';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
}

export default function InvalidScopeModal({
  isOpen,
  onConfirm = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size={50} />
        <h1 className="text-dark fs-20 mb-0 mt-4 text-center">
          {t(`auth.invalidScopeTitle`)}
        </h1>
        <p className="fs-16 mt-4 mb-0 text-center">
          {t(`auth.invalidScopeExplanation`)}
        </p>
        <p className="fs-16 mb-3 text-center lh-30">
          {`${t('auth.invalidScopeContact')} ${CONTACT_EMAIL}`}
        </p>
        <Button
          text={t('common.actions.understood')}
          className="mt-4 w-75 text-uppercase"
          style={{ maxWidth: 250 }}
          onClick={() => onConfirm()}
        />
      </div>
    </Modal>
  );
}
