import { ColumnTable, Table } from '@octano/global-ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionsScheduleDays } from '../../types/sectionSchedules';
import dayToNumber from '../../utils/dayToNumber';
import PackagesViewSectionsDetailsHeader from './PackagesViewSectionsDetailsHeader';

function formatTime(time: string) {
  if (!time) {
    return '';
  }
  return time.slice(0, 5);
}

export interface PackageViewSection {
  sectionName: string;
  courseName: string;
  teachersNames: string[];
  sectionSchedules: {
    day: SectionsScheduleDays;
    startTime: string;
    endTime: string;
    classroomName: string;
  }[];
}

export interface PackagesViewSectionsDetailsProps {
  course: PackageViewSection;
  onBack: () => void;
}

export default function PackagesViewSectionsDetails({
  course,
  onBack,
}: PackagesViewSectionsDetailsProps) {
  const { t } = useTranslation();
  const prefix = 'packages.view.sections';

  const coursesColumns: ColumnTable<PackageViewSection>[] = [
    {
      columnName: 'sectionName',
      headerText: (
        <PackagesViewSectionsDetailsHeader
          text={t(`common.actions.back`)}
          goBackAction={onBack}
        />
      ),
      cellFormat: ({ row }) => {
        const sectionSchedules = [...row.sectionSchedules].sort((a, b) => {
          const dayComparison = dayToNumber(a.day) - dayToNumber(b.day);
          if (dayComparison) {
            return dayComparison;
          }
          return a.startTime.localeCompare(b.startTime);
        });
        return (
          <div className="py-4 px-5">
            <Row>
              <SectionDetailColumn title={t(`${prefix}.section`)}>
                <div>{row.sectionName}</div>
              </SectionDetailColumn>
              <SectionDetailColumn title={t(`${prefix}.professor`)}>
                {[...row.teachersNames].sort().map((teacher) => (
                  <div>{teacher}</div>
                ))}
              </SectionDetailColumn>
              <SectionDetailColumn title={t(`${prefix}.schedule`)}>
                {sectionSchedules.map((s, index) => (
                  <div key={index}>
                    <span className="text-uppercase">{s.day}</span>{' '}
                    {formatTime(s.startTime)}-{formatTime(s.endTime)}
                  </div>
                ))}
              </SectionDetailColumn>
              <SectionDetailColumn title={t(`${prefix}.classroom`)}>
                {sectionSchedules.map((s, index) => (
                  <div key={index}>{s.classroomName}</div>
                ))}
              </SectionDetailColumn>
            </Row>
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-shadow">
      <Table
        borderless
        striped={false}
        height={700}
        columns={coursesColumns}
        data={[course]}
        subHeader={<div className="px-2 text-primary">{course.courseName}</div>}
      />
    </div>
  );
}

export interface SectionDetailColumnProps {
  title: string;
  children: ReactNode;
}

function SectionDetailColumn({ title, children }: SectionDetailColumnProps) {
  return (
    <Col className="fs-16" xs={12} md={6} lg={3}>
      <div className="fw-700">{title}</div>
      {children}
    </Col>
  );
}
