import { DetailBox } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModifyEnrollmentFormDataResponse } from '../types';
interface DetailProps {
  data: ModifyEnrollmentFormDataResponse['detail'];
  completed: boolean;
}

export default function Detail(props: DetailProps) {
  const { t } = useTranslation();
  const prefix = 'changeStudyPlan.continuityDetail.box';
  const texts = useMemo(
    () => ({
      studentName: t(`${prefix}.studentName`),
      studentRun: t(`${prefix}.studentRun`),
      studyPlanVersionName: t(`${prefix}.studyPlanVersionName`),
      campusName: t(`${prefix}.campusName`),
      scheduleName: t(`${prefix}.scheduleName`),
      status: t(`${prefix}.status`),
    }),
    [t],
  );
  const { data, completed } = props;
  return (
    <div className="d-md-flex justify-content-between">
      <div className="pb-3 pb-md-0 pr-md-3">
        <DetailBox
          columns={[
            {
              title: texts.studentName,
              body: data.studentName,
            },
            {
              title: texts.studentRun,
              body: data.studentRun,
            },
            {
              title: texts.studyPlanVersionName,
              body: data.studyPlanVersionName,
            },
            {
              title: texts.campusName,
              body: data.campusName,
            },
            {
              title: texts.scheduleName,
              body: data.scheduleName,
            },
          ]}
        />
      </div>
      <DetailBox
        columns={[
          {
            title: texts.status,
            body: completed
              ? 'Cambiado el plan de estudio'
              : 'En proceso de cambio de plan de estudio',
          },
        ]}
      />
    </div>
  );
}
