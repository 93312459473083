import { useForm } from 'react-hook-form';
import {
  Button,
  TextOutlinedInput,
  OutlinedSelect,
  useMobile,
} from '@octano/global-ui';
import { useValidations } from '../../../hooks/useValidations';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type SearchType = 'identificator' | 'name';
type SearchTypeOption = { label: string; value: SearchType };

const SEARCH_TYPE_OPTIONS: SearchTypeOption[] = [
  { label: 'ID', value: 'identificator' },
  { label: 'Nombre', value: 'name' },
];

type SearchFormValues = {
  search: string;
  searchBy: SearchTypeOption | null;
};

export type SearchParams = { search: string; searchBy: SearchType };

interface Props {
  initialParams?: SearchParams;
  onSearch?: (params: SearchParams) => void;
}

export function SearchBar({ initialParams, onSearch = () => null }: Props) {
  const { t } = useTranslation();
  const prefix = 'studentGradesClosure.search';
  const isMobile = useMobile();

  const defaultValues = useMemo(() => {
    if (!initialParams) return { search: '', searchBy: null };

    const matchedSearchByOption = SEARCH_TYPE_OPTIONS.find(
      (opt) => opt.value === initialParams.searchBy,
    );

    return {
      searchBy: matchedSearchByOption,
      search: initialParams.search,
    };
  }, [initialParams]);

  const { handleSubmit, control } = useForm<SearchFormValues>({
    defaultValues,
  });
  const { validateTextNotEmpty, msgValidations } = useValidations();

  const handleSearch = (values: SearchFormValues) => {
    const { search, searchBy } = values;
    onSearch({ search, searchBy: searchBy?.value! });
  };

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '0.4fr 1fr auto',
        gridGap: isMobile ? 8 : 14,
        alignItems: 'center',
      }}
    >
      <div style={{ minHeight: isMobile ? 'unset' : 144 }}>
        <OutlinedSelect
          control={control}
          label={t(`${prefix}.searchType`)}
          name="searchBy"
          options={SEARCH_TYPE_OPTIONS}
          rules={{ required: msgValidations.required }}
        />
      </div>
      <div style={{ minHeight: isMobile ? 'unset' : 144 }}>
        <TextOutlinedInput
          control={control}
          name="search"
          label={t(`${prefix}.searchText.label`)}
          placeholder={t(`${prefix}.searchText.placeholder`)}
          rules={{
            validate: {
              validateTextNotEmpty,
            },
          }}
        />
      </div>
      <div style={{ minHeight: isMobile ? 'unset' : 144 }}>
        <Button
          text={t(`${prefix}.searchBtnText`)}
          size="md"
          className={isMobile ? 'mb-4' : 'mt-4'}
          fullwidth={isMobile}
          type="submit"
        />
      </div>
    </form>
  );
}
