import { Icon, IconColorType, IconNameType } from '@octano/global-ui';
import { useMemo } from 'react';
import { SingleValueProps } from 'react-select';
import { PetitionStatus } from '../../../../types/studentPetitions';

export default function IconOption(props: SingleValueProps<any>) {
  const data = useMemo<{ color: IconColorType; icon: IconNameType }>(() => {
    const status: PetitionStatus = props?.data?.value;
    const statusColor: Record<PetitionStatus, string> = {
      [PetitionStatus.Received]: 'info',
      [PetitionStatus.InProcess]: 'info',
      [PetitionStatus.Waiting]: 'warning',
      [PetitionStatus.Resolved]: 'success',
    };
    const statusIcon: Record<PetitionStatus, string> = {
      [PetitionStatus.Received]: 'warning',
      [PetitionStatus.InProcess]: 'success',
      [PetitionStatus.Waiting]: 'warning',
      [PetitionStatus.Resolved]: 'success',
    };
    return {
      color: statusColor[status] as IconColorType,
      icon: statusIcon[status] as IconNameType,
    };
  }, [props]);

  return (
    <>
      <div>
        <Icon
          className={'icon-select'}
          color={data.color}
          name={data.icon}
          size="15px"
        />
        <span>{props.data.label}</span>
      </div>
    </>
  );
}
