import { useTranslation } from 'react-i18next';
import { addToast, Button } from '@octano/global-ui';
import { useCallback } from 'react';
import { downloadLoadedInfoDocument } from '../../api/requests/sectionsAndPackages';
import { downloadFromBlob } from '../../utils/blob';

interface DownloadLoadedInformationDocumentProps {
  beforeDownload?: () => void;
  afterDownload?: () => void;
  disabled?: boolean;
  periodId: number;
}

const DownloadLoadedInformationDocument = ({
  beforeDownload,
  afterDownload,
  disabled,
  periodId,
}: DownloadLoadedInformationDocumentProps): JSX.Element => {
  const { t } = useTranslation();
  const prefix = 'packagesMaintainer.downloads';

  const handleDocumentDownload = useCallback(async () => {
    if (beforeDownload) beforeDownload();
    const res = await downloadLoadedInfoDocument(periodId);

    if (res.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorDownloadingFile`),
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
      downloadFromBlob(blob, 'planilla-de-secciones-cargadas.xlsx');
    }
    if (afterDownload) afterDownload();
  }, [beforeDownload, afterDownload, periodId, t]);

  return (
    <div
      className={`loaded-doc-container text-center ${
        disabled ? 'disabled' : ''
      }`}
    >
      <p className="fs-18 fw-700 text-uppercase title">
        {t(`${prefix}.loadedDocTitle`)}
      </p>
      <p className="fs-14">{t(`${prefix}.loadedDocDesc`)}</p>
      <Button
        outlined
        size="md"
        icon="download"
        color="primary"
        text={t(`${prefix}.baseDocButton`)}
        onClick={handleDocumentDownload}
        disabled={disabled}
      />
    </div>
  );
};

export default DownloadLoadedInformationDocument;
