import { Schedule, ScheduleType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../components/text';
import { useScheduledays } from '../../../hooks/useScheduleDays';
import CourseTypeLegends from '../../../packages/parts/CourseTypeLegends';
import { SectionSchedule } from '../../../types/sectionMaintainerTypes';
import { TimeModuleType } from '../../../types/timeModuleTypes';
import { SectionScheduleGetResponse } from '../types';

const SectionShowSchedule = ({
  section,
  sectionSchedules,
  modules,
  legends,
}: {
  section: SectionScheduleGetResponse['section'];
  sectionSchedules: SectionSchedule[];
  modules: TimeModuleType[];
  legends: { id: number; name: string }[];
}) => {
  const prefix = 'sectionsMaintainer.update';
  const { t } = useTranslation();
  const days = useScheduledays();

  const schedules: ScheduleType[] = useMemo(() => {
    return sectionSchedules.map((schedule, i) => {
      return {
        sectionId: section.id,
        scheduleId: i,
        courseCode: section.course.name,
        sectionName: section.name,
        day: schedule.day,
        activityId: schedule.activity.id,
        moduleStartId: schedule.startModule.id,
        moduleEndId: schedule.endModule?.id ?? 0,
        tooltip: {
          courseName: section.course.name,
          sectionTitle: t('common.scheduleTooltip.sectionTitle'),
          sectionName: section.name,
          teachers: [schedule.responsibles[0]?.account?.fullName ?? ''],
          teachersTitle: t('common.scheduleTooltip.teacherTitle'),
          classroom: schedule.classroom?.name ?? '',
        },
      };
    });
  }, [section, sectionSchedules, t]);

  return (
    <Row>
      <Col xs={6}>
        <SectionTitle text={t(`${prefix}.showSchedule`)} />
      </Col>
      <Col xs={12} md={11} lg={10} className="mx-auto">
        <Schedule
          mobileNavigation
          days={days}
          schedules={schedules}
          modules={modules}
        />
      </Col>
      <Col xs={12} md={11} lg={9} className="pt-5 mx-auto">
        <CourseTypeLegends legends={legends} />
      </Col>
    </Row>
  );
};

export default SectionShowSchedule;
