import {
  StudenStatusOptionsResponse,
  StudentStatusListResponse,
  StudentStatusRequest,
} from '../../types/studentStatusTypes';
import { STUDENT_STATUS } from '../endpoints';
import request from '../request';

/**
 * Recupera el listado de estado del alumno
 * mediante los filtros de estado y nombre/id
 * @param data
 * @returns
 */
export const requestStudentStatusList = (data: {
  items_per_page: number;
  page: number;
  status?: string | null;
  campusId?: string | null;
  search_text?: string | null;
}) => {
  return request<StudentStatusListResponse>(`${STUDENT_STATUS.LIST}`, {
    params: data,
  });
};

/**
 * Recupera todos los estados del alumno para ser utilizados
 * como filtro del listado
 * @returns
 */
export const requestStudentStatusOptions = () => {
  return request<StudenStatusOptionsResponse>(
    `${STUDENT_STATUS.STATUS_OPTIONS}`,
  );
};

/**
 * Subir documentación para validar cambio de estado de alumno
 */
export const uploadDocumentationChangeStatus = ({
  file,
  studyPlanEnrollmentId,
  newStatus,
  startDate,
  endDate,
}: {
  file: File;
  studyPlanEnrollmentId: number;
  newStatus: string;
  startDate?: string;
  endDate?: string;
}) => {
  const formData = new FormData();
  formData.append('doc', file);
  formData.append('newStatus', newStatus);
  if (startDate) {
    formData.append('startDate', startDate);
  }
  if (endDate) {
    formData.append('endDate', endDate);
  }
  return request(STUDENT_STATUS.GET_CHANGE_STATUS(studyPlanEnrollmentId), {
    method: 'put',
    data: formData,
  });
};

/**
 * Obtiene el estado de un cambio de estado de un alumno
 */
export const requestStudentStatus = ({
  studyPlanEnrollmentId,
}: {
  studyPlanEnrollmentId: number;
}) => {
  return request<{
    data: StudentStatusRequest;
  }>(STUDENT_STATUS.GET_STUDENT_STATUS(studyPlanEnrollmentId));
};

/**
 * Se actualiza la documentación para validar cambio de estado de alumno
 */
export const updateDocumentationChangeStatus = ({
  file,
  studyPlanEnrollmentId,
  startDate,
  endDate,
}: {
  file?: File;
  studyPlanEnrollmentId: number;
  startDate?: string;
  endDate?: string;
}) => {
  const formData = new FormData();
  if (file) {
    formData.append('doc', file);
  }
  if (startDate) {
    formData.append('startDate', startDate);
  }
  if (endDate) {
    formData.append('endDate', endDate);
  }
  return request(STUDENT_STATUS.GET_UPDATE_STATUS(studyPlanEnrollmentId), {
    method: 'put',
    data: formData,
  });
};

/**
 * Obtiene reporte del listado de los alumnos
 * con su estado asociado
 */
export const getReportStatusStudent = (data: {
  status?: string | null;
  search_text?: string | null;
  campusId?: string | null;
}) => {
  return request(STUDENT_STATUS.REPORT_STATUS_STUDENT, {
    responseType: 'blob',
    params: data,
  });
};
