import {
  CheckInput,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../components/text';
import { useValidations } from '../../../hooks/useValidations';
import { CourseType } from '../../../types/courseMaintainerTypes';
import { CourseFormFields, CourseFormMode } from './CourseForm';

type CourseFormPart1Props = {
  mode: CourseFormMode;
  schools: SelectOptionType[];
  courseTypes: CourseType[];
};

const CourseFormPart1 = ({
  mode,
  schools,
  courseTypes,
}: CourseFormPart1Props) => {
  const prefix = 'courses.update';
  const { t } = useTranslation();
  const { control, getValues, setValue, watch, register } =
    useFormContext<CourseFormFields>();
  const { msgValidations, rulesPatternNumeric } = useValidations();
  const selectedCourseTypes = watch('courseTypes');

  useEffect(() => {
    register('courseTypes');
  }, [register]);

  const onCheckCourseType = (courseTypeId: number) => {
    const newCourseTypes = getValues('courseTypes') ?? [];
    const indexNewCourseType = newCourseTypes.findIndex(
      (course) => course === courseTypeId,
    );
    if (indexNewCourseType >= 0) {
      newCourseTypes.splice(indexNewCourseType, 1);
    } else {
      newCourseTypes.push(courseTypeId);
    }
    setValue('courseTypes', newCourseTypes);
  };

  return (
    <>
      <Row>
        <Col xs={12} className="pt-4">
          <SectionTitle text={t(`${prefix}.dataCourse`)} />
        </Col>
        <Col xs={6} lg={4}>
          <TextInput
            name="shortening"
            label={t(`${prefix}.shortening`)}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={mode !== CourseFormMode.CREATE}
          />
        </Col>
        <Col xs={6} lg={4}>
          <TextInput
            name="code"
            label={t(`${prefix}.code`)}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={mode !== CourseFormMode.CREATE}
          />
        </Col>
        <Col xs={12} lg={4}>
          <TextInput
            name="name"
            label={t(`${prefix}.name`)}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={mode !== CourseFormMode.CREATE}
          />
        </Col>
        <Col xs={12} lg={4}>
          <TextInput
            name="credits"
            label={t(`${prefix}.credits`)}
            control={control}
            rules={{
              required: msgValidations.required,
              pattern: rulesPatternNumeric,
            }}
            disabled={mode !== CourseFormMode.CREATE}
          />
        </Col>
        <Col xs={12} lg={8}>
          <Select
            name="school"
            label={t(`${prefix}.school`)}
            options={schools}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={mode !== CourseFormMode.CREATE}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="pt-4">
          <SectionTitle text={t(`${prefix}.courseTypes`)} />
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300">{t(`${prefix}.courseTypeDescription`)}</p>
        </Col>
        <Col xs={12}>
          <Row className="px-md-3 py-2">
            {courseTypes.map((type) => {
              return (
                <Col xs={6} md={4} lg={3} xl={2} key={`course_${type.id}`}>
                  <CheckInput
                    name={type.name}
                    label={type.name}
                    onChange={() => {
                      onCheckCourseType(type.id);
                    }}
                    value={selectedCourseTypes?.some(
                      (courseTypeId) => courseTypeId === type.id,
                    )}
                    disabled={mode === CourseFormMode.WATCH}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CourseFormPart1;
