import { Button, ColumnTable, Table } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import TableEmptyContent from '../../../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../../../config/routes';
import { getVerifySoftchangeToContinuity } from '../../services/request';
import { ContinuityRow } from '../../types/ContinuityRow';

const dictPrefix = 'changeStudyPlan.continutityList.table';

interface Props {
  data: ContinuityRow[];
  isLoadingResults: boolean;
  totalPetitions: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function ContinuityTable({
  data,
  isLoadingResults,
  totalPages,
  totalPetitions,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      studentRun: t(`${dictPrefix}.columns.studentRun`),
      studentNames: t(`${dictPrefix}.columns.studentNames`),
      studentLastNames: t(`${dictPrefix}.columns.studentLastNames`),
      studyPlanVersionName: t(`${dictPrefix}.columns.studyPlanVersionName`),
      studentPhone: t(`${dictPrefix}.columns.studentPhone`),
      studentEmail: t(`${dictPrefix}.columns.studentEmail`),
      periodName: t(`${dictPrefix}.columns.periodName`),
      action: t(`${dictPrefix}.columns.action`),
      makeChange: t(`${dictPrefix}.columns.makeChange`),
      noResultsTitle: t(`${dictPrefix}.noResultsTitle`),
      noResultsBody: t(`${dictPrefix}.noResultsBody`),
      modalTitle: t(`${dictPrefix}.partialSuccessModal.title`),
      modalBody: t(`${dictPrefix}.partialSuccessModal.body`),
      modalAction: t(`common.actions.understood`),
    }),
    [t],
  );
  const history = useHistory();

  const [openedModal, setOpenedModal] = useState<boolean>(false);

  const handleSoftChangeContinuity = useCallback(
    async (accoundId, studyPlanEnrollmentId) => {
      const { data, error } = await getVerifySoftchangeToContinuity(
        accoundId,
        studyPlanEnrollmentId,
      );
      if (error) {
        setOpenedModal(true);
      } else {
        history.push(
          `${PathsLayouts.enrollmentSoftChange}/continuity/${studyPlanEnrollmentId}`,
        );
      }
    },
    [history],
  );

  const columns: ColumnTable<ContinuityRow>[] = [
    {
      headerText: texts.studentRun,
      columnName: 'studentRun',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return <p>{row?.studentRun && format(row.studentRun)}</p>;
      },
    },
    {
      headerText: texts.studentNames,
      columnName: 'studentNames',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: texts.studentLastNames,
      columnName: 'studentLastNames',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: texts.studyPlanVersionName,
      columnName: 'studyPlanVersionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
      cellFormat: (options: CellFormatOptions) => options.value,
    },
    {
      headerText: texts.studentPhone,
      columnName: 'studentPhone',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.studentEmail,
      columnName: 'studentEmail',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '50px',
    },
    {
      headerText: texts.periodName,
      columnName: 'periodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: texts.action,
      columnName: 'studyPlanEnrollmentId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: (options) => {
        return (
          <Button
            text={texts.makeChange}
            size="sm"
            onClick={() => {
              handleSoftChangeContinuity(
                options.row.accountId,
                options.row.studyPlanEnrollmentId,
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: totalPetitions,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <TableEmptyContent
            title={texts.noResultsTitle}
            subtitle={texts.noResultsBody}
          />
        }
      />
      <ConfirmationModal
        iconName="warning"
        isOpen={openedModal}
        toggle={() => setOpenedModal(false)}
        title={texts.modalTitle}
        body={texts.modalBody}
        primaryBtn={{
          text: texts.modalAction,
          action: () => {
            setOpenedModal(false);
          },
        }}
      />
    </>
  );
}
