import { TUITION_CONTINUITY_TARIFF } from '../endpoints';
import request from '../request';
import { TuitionListResponse } from '../../types/periodTuition';
import { Period } from './MantainerPortal';
import {
  PeriodAndSchoolResponse,
  TariffListResponse,
  TcTariffSubmitData,
} from '../../types/tuitionContinuityTrariff';

/**
 * Obtiene el listado de matrículas con paginación
 */
export const getTuitionContinuityValuesRequest = (
  items: number = 10,
  page: number = 0,
) => {
  const url = `${TUITION_CONTINUITY_TARIFF.TUITION_LIST}`;
  return request<TuitionListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

export const getTcPeriodsValue = () =>
  request<{ data: Period[] }>(TUITION_CONTINUITY_TARIFF.PERIODS, {
    method: 'get',
  });

export const saveTuitionContinuityValue = (data: {
  periodId: number;
  value: number;
  id?: number;
}) => {
  const url = `${TUITION_CONTINUITY_TARIFF.SAVE_TUITION_VALUE}${
    data.id ? '/' + data.id : ''
  }`;
  return request(url, { method: data.id ? 'put' : 'post', data });
};

export const getPeriodAndSchool = () =>
  request<{ data: PeriodAndSchoolResponse }>(
    TUITION_CONTINUITY_TARIFF.GET_PERIOD_AND_OFFER,
    { method: 'get' },
  );

export const getTcTariffList = (
  periodId: number | string,
  schoolId: number | string,
) =>
  request<{ data: TariffListResponse[] }>(
    TUITION_CONTINUITY_TARIFF.GET_TARIFF_LIST,
    {
      method: 'get',
      params: { periodId, schoolId },
    },
  );

export const saveTcTariffList = (data: TcTariffSubmitData[]) =>
  request(TUITION_CONTINUITY_TARIFF.SAVE_TARIFFS, {
    method: 'post',
    data: { tariffList: data },
  });
